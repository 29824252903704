// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaymentFormTyped
 */
export interface PaymentFormTyped {
  /**
   *
   * @type {string}
   * @memberof PaymentFormTyped
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof PaymentFormTyped
   */
  method: string;
  /**
   *
   * @type {string}
   * @memberof PaymentFormTyped
   */
  action: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PaymentFormTyped
   */
  fields: { [key: string]: any };
}

/**
 * Check if a given object implements the PaymentFormTyped interface.
 */
export function instanceOfPaymentFormTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "method" in value;
  isInstance = isInstance && "action" in value;
  isInstance = isInstance && "fields" in value;

  return isInstance;
}

export function PaymentFormTypedFromJSON(json: any): PaymentFormTyped {
  return PaymentFormTypedFromJSONTyped(json, false);
}

export function PaymentFormTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentFormTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json["type"],
    method: json["method"],
    action: json["action"],
    fields: json["fields"],
  };
}

export function PaymentFormTypedToJSON(value?: PaymentFormTyped | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    method: value.method,
    action: value.action,
    fields: value.fields,
  };
}
