// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChatUnbanRequest
 */
export interface ChatUnbanRequest {
  /**
   *
   * @type {number}
   * @memberof ChatUnbanRequest
   */
  userId: number;
}

/**
 * Check if a given object implements the ChatUnbanRequest interface.
 */
export function instanceOfChatUnbanRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;

  return isInstance;
}

export function ChatUnbanRequestFromJSON(json: any): ChatUnbanRequest {
  return ChatUnbanRequestFromJSONTyped(json, false);
}

export function ChatUnbanRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatUnbanRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
  };
}

export function ChatUnbanRequestToJSON(value?: ChatUnbanRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
  };
}
