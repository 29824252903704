// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CurrencyEnum } from "./CurrencyEnum";
import {
  CurrencyEnumFromJSON,
  CurrencyEnumFromJSONTyped,
  CurrencyEnumToJSON,
} from "./CurrencyEnum";

/**
 *
 * @export
 * @interface InvoiceRequest
 */
export interface InvoiceRequest {
  /**
   *
   * @type {number}
   * @memberof InvoiceRequest
   */
  amount?: number;
  /**
   *
   * @type {CurrencyEnum}
   * @memberof InvoiceRequest
   */
  currency?: CurrencyEnum;
  /**
   *
   * @type {string}
   * @memberof InvoiceRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvoiceRequest
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceRequest
   */
  cryptoValue?: string | null;
}

/**
 * Check if a given object implements the InvoiceRequest interface.
 */
export function instanceOfInvoiceRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function InvoiceRequestFromJSON(json: any): InvoiceRequest {
  return InvoiceRequestFromJSONTyped(json, false);
}

export function InvoiceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InvoiceRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amount: !exists(json, "amount") ? undefined : json["amount"],
    currency: !exists(json, "currency")
      ? undefined
      : CurrencyEnumFromJSON(json["currency"]),
    email: !exists(json, "email") ? undefined : json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    cryptoValue: !exists(json, "crypto_value")
      ? undefined
      : json["crypto_value"],
  };
}

export function InvoiceRequestToJSON(value?: InvoiceRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount: value.amount,
    currency: CurrencyEnumToJSON(value.currency),
    email: value.email,
    phone: value.phone,
    crypto_value: value.cryptoValue,
  };
}
