// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { UserShort } from "./UserShort";
import {
  UserShortFromJSON,
  UserShortFromJSONTyped,
  UserShortToJSON,
} from "./UserShort";

/**
 *
 * @export
 * @interface ReferralAccount
 */
export interface ReferralAccount {
  /**
   *
   * @type {UserShort}
   * @memberof ReferralAccount
   */
  referrer: UserShort;
  /**
   *
   * @type {Date}
   * @memberof ReferralAccount
   */
  readonly created: Date;
}

/**
 * Check if a given object implements the ReferralAccount interface.
 */
export function instanceOfReferralAccount(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "referrer" in value;
  isInstance = isInstance && "created" in value;

  return isInstance;
}

export function ReferralAccountFromJSON(json: any): ReferralAccount {
  return ReferralAccountFromJSONTyped(json, false);
}

export function ReferralAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReferralAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    referrer: UserShortFromJSON(json["referrer"]),
    created: new Date(json["created"]),
  };
}

export function ReferralAccountToJSON(value?: ReferralAccount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    referrer: UserShortToJSON(value.referrer),
  };
}
