// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  ExternalScenarioTyped,
  instanceOfExternalScenarioTyped,
  ExternalScenarioTypedFromJSON,
  ExternalScenarioTypedFromJSONTyped,
  ExternalScenarioTypedToJSON,
} from "./ExternalScenarioTyped";
import {
  InvoiceQRScenarioTyped,
  instanceOfInvoiceQRScenarioTyped,
  InvoiceQRScenarioTypedFromJSON,
  InvoiceQRScenarioTypedFromJSONTyped,
  InvoiceQRScenarioTypedToJSON,
} from "./InvoiceQRScenarioTyped";
import {
  P2PScenarioTyped,
  instanceOfP2PScenarioTyped,
  P2PScenarioTypedFromJSON,
  P2PScenarioTypedFromJSONTyped,
  P2PScenarioTypedToJSON,
} from "./P2PScenarioTyped";
import {
  PaymentFormTyped,
  instanceOfPaymentFormTyped,
  PaymentFormTypedFromJSON,
  PaymentFormTypedFromJSONTyped,
  PaymentFormTypedToJSON,
} from "./PaymentFormTyped";
import {
  PaymentRedirectTyped,
  instanceOfPaymentRedirectTyped,
  PaymentRedirectTypedFromJSON,
  PaymentRedirectTypedFromJSONTyped,
  PaymentRedirectTypedToJSON,
} from "./PaymentRedirectTyped";
import {
  QRScenarioTyped,
  instanceOfQRScenarioTyped,
  QRScenarioTypedFromJSON,
  QRScenarioTypedFromJSONTyped,
  QRScenarioTypedToJSON,
} from "./QRScenarioTyped";
import {
  WaitOrderStateTyped,
  instanceOfWaitOrderStateTyped,
  WaitOrderStateTypedFromJSON,
  WaitOrderStateTypedFromJSONTyped,
  WaitOrderStateTypedToJSON,
} from "./WaitOrderStateTyped";

/**
 * @type Scenario
 *
 * @export
 */
export type Scenario =
  | ({ type: "external" } & ExternalScenarioTyped)
  | ({ type: "form" } & PaymentFormTyped)
  | ({ type: "invoice_qr" } & InvoiceQRScenarioTyped)
  | ({ type: "p2p" } & P2PScenarioTyped)
  | ({ type: "p2p_redirect" } & PaymentRedirectTyped)
  | ({ type: "qr" } & QRScenarioTyped)
  | ({ type: "redirect" } & PaymentRedirectTyped)
  | ({ type: "wait_order_state" } & WaitOrderStateTyped);

export function ScenarioFromJSON(json: any): Scenario {
  return ScenarioFromJSONTyped(json, false);
}

export function ScenarioFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Scenario {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json["type"]) {
    case "external":
      return {
        ...ExternalScenarioTypedFromJSONTyped(json, true),
        type: "external",
      };
    case "form":
      return { ...PaymentFormTypedFromJSONTyped(json, true), type: "form" };
    case "invoice_qr":
      return {
        ...InvoiceQRScenarioTypedFromJSONTyped(json, true),
        type: "invoice_qr",
      };
    case "p2p":
      return { ...P2PScenarioTypedFromJSONTyped(json, true), type: "p2p" };
    case "p2p_redirect":
      return {
        ...PaymentRedirectTypedFromJSONTyped(json, true),
        type: "p2p_redirect",
      };
    case "qr":
      return { ...QRScenarioTypedFromJSONTyped(json, true), type: "qr" };
    case "redirect":
      return {
        ...PaymentRedirectTypedFromJSONTyped(json, true),
        type: "redirect",
      };
    case "wait_order_state":
      return {
        ...WaitOrderStateTypedFromJSONTyped(json, true),
        type: "wait_order_state",
      };
    default:
      throw new Error(
        `No variant of Scenario exists with 'type=${json["type"]}'`,
      );
  }
}

export function ScenarioToJSON(value?: Scenario | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value["type"]) {
    case "external":
      return ExternalScenarioTypedToJSON(value);
    case "form":
      return PaymentFormTypedToJSON(value);
    case "invoice_qr":
      return InvoiceQRScenarioTypedToJSON(value);
    case "p2p":
      return P2PScenarioTypedToJSON(value);
    case "p2p_redirect":
      return PaymentRedirectTypedToJSON(value);
    case "qr":
      return QRScenarioTypedToJSON(value);
    case "redirect":
      return PaymentRedirectTypedToJSON(value);
    case "wait_order_state":
      return WaitOrderStateTypedToJSON(value);
    default:
      throw new Error(
        `No variant of Scenario exists with 'type=${value["type"]}'`,
      );
  }
}
