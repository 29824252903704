// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AvatarBorder } from "./AvatarBorder";
import {
  AvatarBorderFromJSON,
  AvatarBorderFromJSONTyped,
  AvatarBorderToJSON,
} from "./AvatarBorder";
import type { NicknameDecorationsEnum } from "./NicknameDecorationsEnum";
import {
  NicknameDecorationsEnumFromJSON,
  NicknameDecorationsEnumFromJSONTyped,
  NicknameDecorationsEnumToJSON,
} from "./NicknameDecorationsEnum";

/**
 *
 * @export
 * @interface MessageSender
 */
export interface MessageSender {
  /**
   *
   * @type {number}
   * @memberof MessageSender
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof MessageSender
   */
  readonly nickname: string;
  /**
   *
   * @type {string}
   * @memberof MessageSender
   */
  readonly avatar: string;
  /**
   *
   * @type {AvatarBorder}
   * @memberof MessageSender
   */
  readonly avatarBorder: AvatarBorder;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly banInChat: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly isStaff: boolean;
  /**
   *
   * @type {NicknameDecorationsEnum}
   * @memberof MessageSender
   */
  readonly selectedNicknameDecoration: NicknameDecorationsEnum;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly canBeBanned: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly isContentCreator: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly isModerator: boolean;
  /**
   *
   * @type {Date}
   * @memberof MessageSender
   */
  endBanDate: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly isNicknameChangeRequested: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MessageSender
   */
  readonly isForeverBanned: boolean;
}

/**
 * Check if a given object implements the MessageSender interface.
 */
export function instanceOfMessageSender(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "avatar" in value;
  isInstance = isInstance && "avatarBorder" in value;
  isInstance = isInstance && "banInChat" in value;
  isInstance = isInstance && "isStaff" in value;
  isInstance = isInstance && "selectedNicknameDecoration" in value;
  isInstance = isInstance && "canBeBanned" in value;
  isInstance = isInstance && "isContentCreator" in value;
  isInstance = isInstance && "isModerator" in value;
  isInstance = isInstance && "endBanDate" in value;
  isInstance = isInstance && "isNicknameChangeRequested" in value;
  isInstance = isInstance && "isForeverBanned" in value;

  return isInstance;
}

export function MessageSenderFromJSON(json: any): MessageSender {
  return MessageSenderFromJSONTyped(json, false);
}

export function MessageSenderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MessageSender {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    nickname: json["nickname"],
    avatar: json["avatar"],
    avatarBorder: AvatarBorderFromJSON(json["avatar_border"]),
    banInChat: json["ban_in_chat"],
    isStaff: json["is_staff"],
    selectedNicknameDecoration: NicknameDecorationsEnumFromJSON(
      json["selected_nickname_decoration"],
    ),
    canBeBanned: json["can_be_banned"],
    isContentCreator: json["is_content_creator"],
    isModerator: json["is_moderator"],
    endBanDate:
      json["end_ban_date"] === null ? null : new Date(json["end_ban_date"]),
    isNicknameChangeRequested: json["is_nickname_change_requested"],
    isForeverBanned: json["is_forever_banned"],
  };
}

export function MessageSenderToJSON(value?: MessageSender | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    end_ban_date:
      value.endBanDate === null ? null : value.endBanDate.toISOString(),
  };
}
