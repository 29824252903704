// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RewardBaseTyped
 */
export interface RewardBaseTyped {
  /**
   *
   * @type {string}
   * @memberof RewardBaseTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RewardBaseTyped
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof RewardBaseTyped
   */
  icon: string | null;
  /**
   *
   * @type {string}
   * @memberof RewardBaseTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the RewardBaseTyped interface.
 */
export function instanceOfRewardBaseTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "icon" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function RewardBaseTypedFromJSON(json: any): RewardBaseTyped {
  return RewardBaseTypedFromJSONTyped(json, false);
}

export function RewardBaseTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RewardBaseTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    description: json["description"],
    icon: json["icon"],
    resourcetype: json["resourcetype"],
  };
}

export function RewardBaseTypedToJSON(value?: RewardBaseTyped | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    icon: value.icon,
    resourcetype: value.resourcetype,
  };
}
