// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CalcChance,
  CasesBattleRoom,
  CasesBattleRoomRequest,
  CoinRoom,
  CoinRoomRequest,
  ContractRequest,
  ContractSettings,
  CrashSettings,
  FooterValuesCounter,
  Inventory,
  LadderGame,
  LadderGameSettings,
  LadderGameStartRequest,
  MinerGame,
  MinerGameSettings,
  MinerGameStartRequest,
  NextFloorRequest,
  NextFloorResponse,
  NextStepRequest,
  NextStepResponse,
  PaginatedCaseListsByCollectionsList,
  PaginatedGameResultOutputList,
  PaginatedSubjectList,
  PlayUpgradeRequest,
  StartGameInputRequest,
  StartGameOutput,
  TakeProfitResponse,
  TowerSettings,
  TurnInputRequest,
  UpgradeGame,
  UpgradeGameTop,
  UpgradeRecommendedSubject,
  WheelGameSettings,
} from "../models/index";
import {
  CalcChanceFromJSON,
  CalcChanceToJSON,
  CasesBattleRoomFromJSON,
  CasesBattleRoomToJSON,
  CasesBattleRoomRequestFromJSON,
  CasesBattleRoomRequestToJSON,
  CoinRoomFromJSON,
  CoinRoomToJSON,
  CoinRoomRequestFromJSON,
  CoinRoomRequestToJSON,
  ContractRequestFromJSON,
  ContractRequestToJSON,
  ContractSettingsFromJSON,
  ContractSettingsToJSON,
  CrashSettingsFromJSON,
  CrashSettingsToJSON,
  FooterValuesCounterFromJSON,
  FooterValuesCounterToJSON,
  InventoryFromJSON,
  InventoryToJSON,
  LadderGameFromJSON,
  LadderGameToJSON,
  LadderGameSettingsFromJSON,
  LadderGameSettingsToJSON,
  LadderGameStartRequestFromJSON,
  LadderGameStartRequestToJSON,
  MinerGameFromJSON,
  MinerGameToJSON,
  MinerGameSettingsFromJSON,
  MinerGameSettingsToJSON,
  MinerGameStartRequestFromJSON,
  MinerGameStartRequestToJSON,
  NextFloorRequestFromJSON,
  NextFloorRequestToJSON,
  NextFloorResponseFromJSON,
  NextFloorResponseToJSON,
  NextStepRequestFromJSON,
  NextStepRequestToJSON,
  NextStepResponseFromJSON,
  NextStepResponseToJSON,
  PaginatedCaseListsByCollectionsListFromJSON,
  PaginatedCaseListsByCollectionsListToJSON,
  PaginatedGameResultOutputListFromJSON,
  PaginatedGameResultOutputListToJSON,
  PaginatedSubjectListFromJSON,
  PaginatedSubjectListToJSON,
  PlayUpgradeRequestFromJSON,
  PlayUpgradeRequestToJSON,
  StartGameInputRequestFromJSON,
  StartGameInputRequestToJSON,
  StartGameOutputFromJSON,
  StartGameOutputToJSON,
  TakeProfitResponseFromJSON,
  TakeProfitResponseToJSON,
  TowerSettingsFromJSON,
  TowerSettingsToJSON,
  TurnInputRequestFromJSON,
  TurnInputRequestToJSON,
  UpgradeGameFromJSON,
  UpgradeGameToJSON,
  UpgradeGameTopFromJSON,
  UpgradeGameTopToJSON,
  UpgradeRecommendedSubjectFromJSON,
  UpgradeRecommendedSubjectToJSON,
  WheelGameSettingsFromJSON,
  WheelGameSettingsToJSON,
} from "../models/index";

export interface GamesApiCasesBattlesCasesListRequest {
  page?: number;
}

export interface GamesApiCasesBattlesCreateRequest {
  casesBattleRoomRequest: CasesBattleRoomRequest;
}

export interface GamesApiCoinCreateRequest {
  coinRoomRequest: CoinRoomRequest;
}

export interface GamesApiContractCreateCreateRequest {
  contractRequest: ContractRequest;
}

export interface GamesApiLadderActiveNextFloorCreateRequest {
  nextFloorRequest: NextFloorRequest;
}

export interface GamesApiLadderStartRequest {
  ladderGameStartRequest: LadderGameStartRequest;
}

export interface GamesApiMinerActiveNextStepCreateRequest {
  nextStepRequest: NextStepRequest;
}

export interface GamesApiMinerStartRequest {
  minerGameStartRequest: MinerGameStartRequest;
}

export interface GamesApiTowerBestListRequest {
  page?: number;
}

export interface GamesApiTowerLastListRequest {
  cursor?: string;
  pageSize?: number;
}

export interface GamesApiTowerMyListRequest {
  cursor?: string;
  pageSize?: number;
}

export interface GamesApiTowerStartRequest {
  startGameInputRequest: StartGameInputRequest;
}

export interface GamesApiTowerTakeProfitCreateRequest {
  id: string;
}

export interface GamesApiTowerTurnCreateRequest {
  id: string;
  turnInputRequest: TurnInputRequest;
}

export interface GamesApiUpgradeCalcChanceRetrieveRequest {
  expectedItem: number;
  bullcoins?: number;
  origInventory?: number;
}

export interface GamesApiUpgradeGetItemsForUpgradeListRequest {
  bullcoins?: number;
  limit?: number;
  offset?: number;
  ordering?: string;
  origInventory?: number;
  price?: number;
  search?: string;
}

export interface GamesApiUpgradePlayCreateRequest {
  playUpgradeRequest: PlayUpgradeRequest;
}

/**
 *
 */
export class GamesApi extends runtime.BaseAPI {
  /**
   */
  async casesBattlesCasesListRaw(
    requestParameters: GamesApiCasesBattlesCasesListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedCaseListsByCollectionsList>> {
    const queryParameters: any = {};

    if (requestParameters["page"] != null) {
      queryParameters["page"] = requestParameters["page"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/cases-battles/cases/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedCaseListsByCollectionsListFromJSON(jsonValue),
    );
  }

  /**
   */
  async casesBattlesCasesList(
    requestParameters: GamesApiCasesBattlesCasesListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedCaseListsByCollectionsList> {
    const response = await this.casesBattlesCasesListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async casesBattlesCreateRaw(
    requestParameters: GamesApiCasesBattlesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CasesBattleRoom>> {
    if (requestParameters["casesBattleRoomRequest"] == null) {
      throw new runtime.RequiredError(
        "casesBattleRoomRequest",
        'Required parameter "casesBattleRoomRequest" was null or undefined when calling casesBattlesCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/cases-battles/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CasesBattleRoomRequestToJSON(
          requestParameters["casesBattleRoomRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CasesBattleRoomFromJSON(jsonValue),
    );
  }

  /**
   */
  async casesBattlesCreate(
    requestParameters: GamesApiCasesBattlesCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CasesBattleRoom> {
    const response = await this.casesBattlesCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async casesBattlesListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<CasesBattleRoom>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/cases-battles/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CasesBattleRoomFromJSON),
    );
  }

  /**
   */
  async casesBattlesList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<CasesBattleRoom>> {
    const response = await this.casesBattlesListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async coinCreateRaw(
    requestParameters: GamesApiCoinCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CoinRoom>> {
    if (requestParameters["coinRoomRequest"] == null) {
      throw new runtime.RequiredError(
        "coinRoomRequest",
        'Required parameter "coinRoomRequest" was null or undefined when calling coinCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/coin/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CoinRoomRequestToJSON(requestParameters["coinRoomRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CoinRoomFromJSON(jsonValue),
    );
  }

  /**
   */
  async coinCreate(
    requestParameters: GamesApiCoinCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CoinRoom> {
    const response = await this.coinCreateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async coinListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<CoinRoom>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/coin/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CoinRoomFromJSON),
    );
  }

  /**
   */
  async coinList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<CoinRoom>> {
    const response = await this.coinListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async contractCreateCreateRaw(
    requestParameters: GamesApiContractCreateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Inventory>> {
    if (requestParameters["contractRequest"] == null) {
      throw new runtime.RequiredError(
        "contractRequest",
        'Required parameter "contractRequest" was null or undefined when calling contractCreateCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/contract/create/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ContractRequestToJSON(requestParameters["contractRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InventoryFromJSON(jsonValue),
    );
  }

  /**
   */
  async contractCreateCreate(
    requestParameters: GamesApiContractCreateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Inventory> {
    const response = await this.contractCreateCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async contractSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ContractSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/contract/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ContractSettingsFromJSON(jsonValue),
    );
  }

  /**
   */
  async contractSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ContractSettings> {
    const response = await this.contractSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async crashSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CrashSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/crash/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CrashSettingsFromJSON(jsonValue),
    );
  }

  /**
   */
  async crashSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CrashSettings> {
    const response = await this.crashSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Перейти на следующий этаж
   */
  async ladderActiveNextFloorCreateRaw(
    requestParameters: GamesApiLadderActiveNextFloorCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<NextFloorResponse>> {
    if (requestParameters["nextFloorRequest"] == null) {
      throw new runtime.RequiredError(
        "nextFloorRequest",
        'Required parameter "nextFloorRequest" was null or undefined when calling ladderActiveNextFloorCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/ladder/active/next_floor/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: NextFloorRequestToJSON(requestParameters["nextFloorRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NextFloorResponseFromJSON(jsonValue),
    );
  }

  /**
   * Перейти на следующий этаж
   */
  async ladderActiveNextFloorCreate(
    requestParameters: GamesApiLadderActiveNextFloorCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<NextFloorResponse> {
    const response = await this.ladderActiveNextFloorCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ladderActiveRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LadderGame>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/ladder/active/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LadderGameFromJSON(jsonValue),
    );
  }

  /**
   */
  async ladderActiveRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LadderGame> {
    const response = await this.ladderActiveRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async ladderActiveTakeProfitCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/ladder/active/take_profit/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async ladderActiveTakeProfitCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ladderActiveTakeProfitCreateRaw(initOverrides);
  }

  /**
   * Настройки игры Лесенка
   */
  async ladderSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LadderGameSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/ladder/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LadderGameSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Настройки игры Лесенка
   */
  async ladderSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LadderGameSettings> {
    const response = await this.ladderSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async ladderStartRaw(
    requestParameters: GamesApiLadderStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LadderGame>> {
    if (requestParameters["ladderGameStartRequest"] == null) {
      throw new runtime.RequiredError(
        "ladderGameStartRequest",
        'Required parameter "ladderGameStartRequest" was null or undefined when calling ladderStart().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/ladder/start/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LadderGameStartRequestToJSON(
          requestParameters["ladderGameStartRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LadderGameFromJSON(jsonValue),
    );
  }

  /**
   */
  async ladderStart(
    requestParameters: GamesApiLadderStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LadderGame> {
    const response = await this.ladderStartRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Выбрать следующую ячейку
   */
  async minerActiveNextStepCreateRaw(
    requestParameters: GamesApiMinerActiveNextStepCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<NextStepResponse>> {
    if (requestParameters["nextStepRequest"] == null) {
      throw new runtime.RequiredError(
        "nextStepRequest",
        'Required parameter "nextStepRequest" was null or undefined when calling minerActiveNextStepCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/miner/active/next_step/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: NextStepRequestToJSON(requestParameters["nextStepRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NextStepResponseFromJSON(jsonValue),
    );
  }

  /**
   * Выбрать следующую ячейку
   */
  async minerActiveNextStepCreate(
    requestParameters: GamesApiMinerActiveNextStepCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<NextStepResponse> {
    const response = await this.minerActiveNextStepCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async minerActiveRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MinerGame>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/miner/active/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MinerGameFromJSON(jsonValue),
    );
  }

  /**
   */
  async minerActiveRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MinerGame> {
    const response = await this.minerActiveRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async minerActiveTakeProfitCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TakeProfitResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/miner/active/take_profit/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TakeProfitResponseFromJSON(jsonValue),
    );
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async minerActiveTakeProfitCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TakeProfitResponse> {
    const response = await this.minerActiveTakeProfitCreateRaw(initOverrides);
    return await response.value();
  }

  /**
   * Настройки игры Сапёр
   */
  async minerSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MinerGameSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/miner/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MinerGameSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Настройки игры Сапёр
   */
  async minerSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MinerGameSettings> {
    const response = await this.minerSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async minerStartRaw(
    requestParameters: GamesApiMinerStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MinerGame>> {
    if (requestParameters["minerGameStartRequest"] == null) {
      throw new runtime.RequiredError(
        "minerGameStartRequest",
        'Required parameter "minerGameStartRequest" was null or undefined when calling minerStart().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/miner/start/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MinerGameStartRequestToJSON(
          requestParameters["minerGameStartRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MinerGameFromJSON(jsonValue),
    );
  }

  /**
   */
  async minerStart(
    requestParameters: GamesApiMinerStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MinerGame> {
    const response = await this.minerStartRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async overviewFooterValuesRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FooterValuesCounter>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/overview/footer-values/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FooterValuesCounterFromJSON(jsonValue),
    );
  }

  /**
   */
  async overviewFooterValuesRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FooterValuesCounter> {
    const response = await this.overviewFooterValuesRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Активная игра текущего пользователя
   */
  async towerActiveRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StartGameOutput>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/active/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StartGameOutputFromJSON(jsonValue),
    );
  }

  /**
   * Активная игра текущего пользователя
   */
  async towerActiveRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StartGameOutput> {
    const response = await this.towerActiveRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Список лучших игр в tower
   */
  async towerBestListRaw(
    requestParameters: GamesApiTowerBestListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedGameResultOutputList>> {
    const queryParameters: any = {};

    if (requestParameters["page"] != null) {
      queryParameters["page"] = requestParameters["page"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/best/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedGameResultOutputListFromJSON(jsonValue),
    );
  }

  /**
   * Список лучших игр в tower
   */
  async towerBestList(
    requestParameters: GamesApiTowerBestListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedGameResultOutputList> {
    const response = await this.towerBestListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Список последних игр в tower
   */
  async towerLastListRaw(
    requestParameters: GamesApiTowerLastListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedGameResultOutputList>> {
    const queryParameters: any = {};

    if (requestParameters["cursor"] != null) {
      queryParameters["cursor"] = requestParameters["cursor"];
    }

    if (requestParameters["pageSize"] != null) {
      queryParameters["page_size"] = requestParameters["pageSize"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/last/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedGameResultOutputListFromJSON(jsonValue),
    );
  }

  /**
   * Список последних игр в tower
   */
  async towerLastList(
    requestParameters: GamesApiTowerLastListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedGameResultOutputList> {
    const response = await this.towerLastListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Список моих игр в tower
   */
  async towerMyListRaw(
    requestParameters: GamesApiTowerMyListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedGameResultOutputList>> {
    const queryParameters: any = {};

    if (requestParameters["cursor"] != null) {
      queryParameters["cursor"] = requestParameters["cursor"];
    }

    if (requestParameters["pageSize"] != null) {
      queryParameters["page_size"] = requestParameters["pageSize"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/my/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedGameResultOutputListFromJSON(jsonValue),
    );
  }

  /**
   * Список моих игр в tower
   */
  async towerMyList(
    requestParameters: GamesApiTowerMyListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedGameResultOutputList> {
    const response = await this.towerMyListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async towerSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TowerSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TowerSettingsFromJSON(jsonValue),
    );
  }

  /**
   */
  async towerSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TowerSettings> {
    const response = await this.towerSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async towerStartRaw(
    requestParameters: GamesApiTowerStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StartGameOutput>> {
    if (requestParameters["startGameInputRequest"] == null) {
      throw new runtime.RequiredError(
        "startGameInputRequest",
        'Required parameter "startGameInputRequest" was null or undefined when calling towerStart().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/start/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StartGameInputRequestToJSON(
          requestParameters["startGameInputRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StartGameOutputFromJSON(jsonValue),
    );
  }

  /**
   */
  async towerStart(
    requestParameters: GamesApiTowerStartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StartGameOutput> {
    const response = await this.towerStartRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async towerTakeProfitCreateRaw(
    requestParameters: GamesApiTowerTakeProfitCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StartGameOutput>> {
    if (requestParameters["id"] == null) {
      throw new runtime.RequiredError(
        "id",
        'Required parameter "id" was null or undefined when calling towerTakeProfitCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/{id}/take-profit/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters["id"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StartGameOutputFromJSON(jsonValue),
    );
  }

  /**
   * Забрать выигрыш. Игра при этом становится выигранной
   */
  async towerTakeProfitCreate(
    requestParameters: GamesApiTowerTakeProfitCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StartGameOutput> {
    const response = await this.towerTakeProfitCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Сделать ход в игре, выбрав предполагаемый выигрышный вариант
   */
  async towerTurnCreateRaw(
    requestParameters: GamesApiTowerTurnCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StartGameOutput>> {
    if (requestParameters["id"] == null) {
      throw new runtime.RequiredError(
        "id",
        'Required parameter "id" was null or undefined when calling towerTurnCreate().',
      );
    }

    if (requestParameters["turnInputRequest"] == null) {
      throw new runtime.RequiredError(
        "turnInputRequest",
        'Required parameter "turnInputRequest" was null or undefined when calling towerTurnCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/tower/{id}/turn/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters["id"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TurnInputRequestToJSON(requestParameters["turnInputRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StartGameOutputFromJSON(jsonValue),
    );
  }

  /**
   * Сделать ход в игре, выбрав предполагаемый выигрышный вариант
   */
  async towerTurnCreate(
    requestParameters: GamesApiTowerTurnCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StartGameOutput> {
    const response = await this.towerTurnCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Возвращает шанс на победу для выбранных предметов
   */
  async upgradeCalcChanceRetrieveRaw(
    requestParameters: GamesApiUpgradeCalcChanceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CalcChance>> {
    if (requestParameters["expectedItem"] == null) {
      throw new runtime.RequiredError(
        "expectedItem",
        'Required parameter "expectedItem" was null or undefined when calling upgradeCalcChanceRetrieve().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters["bullcoins"] != null) {
      queryParameters["bullcoins"] = requestParameters["bullcoins"];
    }

    if (requestParameters["expectedItem"] != null) {
      queryParameters["expected_item"] = requestParameters["expectedItem"];
    }

    if (requestParameters["origInventory"] != null) {
      queryParameters["orig_inventory"] = requestParameters["origInventory"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/upgrade/calc-chance/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalcChanceFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает шанс на победу для выбранных предметов
   */
  async upgradeCalcChanceRetrieve(
    requestParameters: GamesApiUpgradeCalcChanceRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CalcChance> {
    const response = await this.upgradeCalcChanceRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async upgradeGetItemsForUpgradeListRaw(
    requestParameters: GamesApiUpgradeGetItemsForUpgradeListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedSubjectList>> {
    const queryParameters: any = {};

    if (requestParameters["bullcoins"] != null) {
      queryParameters["bullcoins"] = requestParameters["bullcoins"];
    }

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    if (requestParameters["offset"] != null) {
      queryParameters["offset"] = requestParameters["offset"];
    }

    if (requestParameters["ordering"] != null) {
      queryParameters["ordering"] = requestParameters["ordering"];
    }

    if (requestParameters["origInventory"] != null) {
      queryParameters["orig_inventory"] = requestParameters["origInventory"];
    }

    if (requestParameters["price"] != null) {
      queryParameters["price"] = requestParameters["price"];
    }

    if (requestParameters["search"] != null) {
      queryParameters["search"] = requestParameters["search"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/upgrade/get-items-for-upgrade/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedSubjectListFromJSON(jsonValue),
    );
  }

  /**
   */
  async upgradeGetItemsForUpgradeList(
    requestParameters: GamesApiUpgradeGetItemsForUpgradeListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedSubjectList> {
    const response = await this.upgradeGetItemsForUpgradeListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Запускает игру upgrade
   */
  async upgradePlayCreateRaw(
    requestParameters: GamesApiUpgradePlayCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UpgradeGame>> {
    if (requestParameters["playUpgradeRequest"] == null) {
      throw new runtime.RequiredError(
        "playUpgradeRequest",
        'Required parameter "playUpgradeRequest" was null or undefined when calling upgradePlayCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/upgrade/play/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PlayUpgradeRequestToJSON(requestParameters["playUpgradeRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpgradeGameFromJSON(jsonValue),
    );
  }

  /**
   * Запускает игру upgrade
   */
  async upgradePlayCreate(
    requestParameters: GamesApiUpgradePlayCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UpgradeGame> {
    const response = await this.upgradePlayCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async upgradeRecommendedListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<UpgradeRecommendedSubject>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/upgrade/recommended/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(UpgradeRecommendedSubjectFromJSON),
    );
  }

  /**
   */
  async upgradeRecommendedList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<UpgradeRecommendedSubject>> {
    const response = await this.upgradeRecommendedListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async upgradeTopListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<UpgradeGameTop>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/upgrade/top/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(UpgradeGameTopFromJSON),
    );
  }

  /**
   */
  async upgradeTopList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<UpgradeGameTop>> {
    const response = await this.upgradeTopListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async wheelSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<WheelGameSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/games/wheel/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WheelGameSettingsFromJSON(jsonValue),
    );
  }

  /**
   */
  async wheelSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WheelGameSettings> {
    const response = await this.wheelSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }
}
