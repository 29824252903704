// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CalendarEvent,
  CalendarProfile,
  RewardInfo,
  TakeRewardRequest,
  TakeRewardTickets,
} from "../models/index";
import {
  CalendarEventFromJSON,
  CalendarEventToJSON,
  CalendarProfileFromJSON,
  CalendarProfileToJSON,
  RewardInfoFromJSON,
  RewardInfoToJSON,
  TakeRewardRequestFromJSON,
  TakeRewardRequestToJSON,
  TakeRewardTicketsFromJSON,
  TakeRewardTicketsToJSON,
} from "../models/index";

export interface CalendarApiTakeRewardCreateRequest {
  takeRewardRequest: TakeRewardRequest;
}

/**
 *
 */
export class CalendarApi extends runtime.BaseAPI {
  /**
   */
  async battlePassProgressRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CalendarEvent>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/calendar/battle-pass-progress/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalendarEventFromJSON(jsonValue),
    );
  }

  /**
   */
  async battlePassProgressRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CalendarEvent> {
    const response = await this.battlePassProgressRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async profileRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CalendarProfile>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/calendar/profile/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalendarProfileFromJSON(jsonValue),
    );
  }

  /**
   */
  async profileRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CalendarProfile> {
    const response = await this.profileRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async takeRewardCreateRaw(
    requestParameters: CalendarApiTakeRewardCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RewardInfo>> {
    if (requestParameters["takeRewardRequest"] == null) {
      throw new runtime.RequiredError(
        "takeRewardRequest",
        'Required parameter "takeRewardRequest" was null or undefined when calling takeRewardCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/calendar/take-reward/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TakeRewardRequestToJSON(requestParameters["takeRewardRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async takeRewardCreate(
    requestParameters: CalendarApiTakeRewardCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RewardInfo> {
    const response = await this.takeRewardCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * @deprecated
   */
  async takeTicketsRewardListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<TakeRewardTickets>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/calendar/take-tickets-reward/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TakeRewardTicketsFromJSON),
    );
  }

  /**
   * @deprecated
   */
  async takeTicketsRewardList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<TakeRewardTickets>> {
    const response = await this.takeTicketsRewardListRaw(initOverrides);
    return await response.value();
  }
}
