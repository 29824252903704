// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CasesBattleRoomCaseShort } from "./CasesBattleRoomCaseShort";
import {
  CasesBattleRoomCaseShortFromJSON,
  CasesBattleRoomCaseShortFromJSONTyped,
  CasesBattleRoomCaseShortToJSON,
} from "./CasesBattleRoomCaseShort";
import type { UserShort } from "./UserShort";
import {
  UserShortFromJSON,
  UserShortFromJSONTyped,
  UserShortToJSON,
} from "./UserShort";

/**
 *
 * @export
 * @interface CasesBattleRoom
 */
export interface CasesBattleRoom {
  /**
   *
   * @type {number}
   * @memberof CasesBattleRoom
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof CasesBattleRoom
   */
  readonly visibleId: number;
  /**
   *
   * @type {UserShort}
   * @memberof CasesBattleRoom
   */
  readonly host: UserShort;
  /**
   *
   * @type {CasesBattleRoomCaseShort}
   * @memberof CasesBattleRoom
   */
  readonly _case: CasesBattleRoomCaseShort;
  /**
   *
   * @type {number}
   * @memberof CasesBattleRoom
   */
  readonly numParticipants: number;
}

/**
 * Check if a given object implements the CasesBattleRoom interface.
 */
export function instanceOfCasesBattleRoom(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "visibleId" in value;
  isInstance = isInstance && "host" in value;
  isInstance = isInstance && "_case" in value;
  isInstance = isInstance && "numParticipants" in value;

  return isInstance;
}

export function CasesBattleRoomFromJSON(json: any): CasesBattleRoom {
  return CasesBattleRoomFromJSONTyped(json, false);
}

export function CasesBattleRoomFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CasesBattleRoom {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    visibleId: json["visible_id"],
    host: UserShortFromJSON(json["host"]),
    _case: CasesBattleRoomCaseShortFromJSON(json["case"]),
    numParticipants: json["num_participants"],
  };
}

export function CasesBattleRoomToJSON(value?: CasesBattleRoom | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
