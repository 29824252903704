// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Inventory } from "./Inventory";
import {
  InventoryFromJSON,
  InventoryFromJSONTyped,
  InventoryToJSON,
} from "./Inventory";

/**
 *
 * @export
 * @interface PaginatedInventoryList
 */
export interface PaginatedInventoryList {
  /**
   *
   * @type {number}
   * @memberof PaginatedInventoryList
   */
  count: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedInventoryList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedInventoryList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<Inventory>}
   * @memberof PaginatedInventoryList
   */
  results: Array<Inventory>;
}

/**
 * Check if a given object implements the PaginatedInventoryList interface.
 */
export function instanceOfPaginatedInventoryList(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "count" in value;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function PaginatedInventoryListFromJSON(
  json: any,
): PaginatedInventoryList {
  return PaginatedInventoryListFromJSONTyped(json, false);
}

export function PaginatedInventoryListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedInventoryList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    count: json["count"],
    next: !exists(json, "next") ? undefined : json["next"],
    previous: !exists(json, "previous") ? undefined : json["previous"],
    results: (json["results"] as Array<any>).map(InventoryFromJSON),
  };
}

export function PaginatedInventoryListToJSON(
  value?: PaginatedInventoryList | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    count: value.count,
    next: value.next,
    previous: value.previous,
    results: (value.results as Array<any>).map(InventoryToJSON),
  };
}
