// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PUBGWithdrawal } from "./PUBGWithdrawal";
import {
  PUBGWithdrawalFromJSON,
  PUBGWithdrawalFromJSONTyped,
  PUBGWithdrawalToJSON,
} from "./PUBGWithdrawal";

/**
 *
 * @export
 * @interface PaginatedPUBGWithdrawalList
 */
export interface PaginatedPUBGWithdrawalList {
  /**
   *
   * @type {string}
   * @memberof PaginatedPUBGWithdrawalList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedPUBGWithdrawalList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<PUBGWithdrawal>}
   * @memberof PaginatedPUBGWithdrawalList
   */
  results: Array<PUBGWithdrawal>;
}

/**
 * Check if a given object implements the PaginatedPUBGWithdrawalList interface.
 */
export function instanceOfPaginatedPUBGWithdrawalList(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function PaginatedPUBGWithdrawalListFromJSON(
  json: any,
): PaginatedPUBGWithdrawalList {
  return PaginatedPUBGWithdrawalListFromJSONTyped(json, false);
}

export function PaginatedPUBGWithdrawalListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedPUBGWithdrawalList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    next: !exists(json, "next") ? undefined : json["next"],
    previous: !exists(json, "previous") ? undefined : json["previous"],
    results: (json["results"] as Array<any>).map(PUBGWithdrawalFromJSON),
  };
}

export function PaginatedPUBGWithdrawalListToJSON(
  value?: PaginatedPUBGWithdrawalList | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    next: value.next,
    previous: value.previous,
    results: (value.results as Array<any>).map(PUBGWithdrawalToJSON),
  };
}
