// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CasesBattleRoomRequest
 */
export interface CasesBattleRoomRequest {
  /**
   *
   * @type {number}
   * @memberof CasesBattleRoomRequest
   */
  caseId: number;
}

/**
 * Check if a given object implements the CasesBattleRoomRequest interface.
 */
export function instanceOfCasesBattleRoomRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "caseId" in value;

  return isInstance;
}

export function CasesBattleRoomRequestFromJSON(
  json: any,
): CasesBattleRoomRequest {
  return CasesBattleRoomRequestFromJSONTyped(json, false);
}

export function CasesBattleRoomRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CasesBattleRoomRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    caseId: json["case_id"],
  };
}

export function CasesBattleRoomRequestToJSON(
  value?: CasesBattleRoomRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    case_id: value.caseId,
  };
}
