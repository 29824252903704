// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { UserReward } from "../models/index";
import { UserRewardFromJSON, UserRewardToJSON } from "../models/index";

export interface RewardsApiClaimRequest {
  id: number;
}

/**
 *
 */
export class RewardsApi extends runtime.BaseAPI {
  /**
   */
  async claimRaw(
    requestParameters: RewardsApiClaimRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserReward>> {
    if (requestParameters["id"] == null) {
      throw new runtime.RequiredError(
        "id",
        'Required parameter "id" was null or undefined when calling claim().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/rewards/{id}/claim/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters["id"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserRewardFromJSON(jsonValue),
    );
  }

  /**
   */
  async claim(
    requestParameters: RewardsApiClaimRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserReward> {
    const response = await this.claimRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async userPendingListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<UserReward>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/rewards/user/pending/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(UserRewardFromJSON),
    );
  }

  /**
   */
  async userPendingList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<UserReward>> {
    const response = await this.userPendingListRaw(initOverrides);
    return await response.value();
  }
}
