// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ChatMessageRoomEnum } from "./ChatMessageRoomEnum";
import {
  ChatMessageRoomEnumFromJSON,
  ChatMessageRoomEnumFromJSONTyped,
  ChatMessageRoomEnumToJSON,
} from "./ChatMessageRoomEnum";
import type { PlatformEnum } from "./PlatformEnum";
import {
  PlatformEnumFromJSON,
  PlatformEnumFromJSONTyped,
  PlatformEnumToJSON,
} from "./PlatformEnum";

/**
 *
 * @export
 * @interface ChatMessageRequest
 */
export interface ChatMessageRequest {
  /**
   *
   * @type {ChatMessageRoomEnum}
   * @memberof ChatMessageRequest
   */
  room?: ChatMessageRoomEnum;
  /**
   *
   * @type {string}
   * @memberof ChatMessageRequest
   */
  messageText: string;
  /**
   *
   * @type {Date}
   * @memberof ChatMessageRequest
   */
  createdAt?: Date;
  /**
   *
   * @type {string}
   * @memberof ChatMessageRequest
   */
  roomId?: string | null;
  /**
   *
   * @type {PlatformEnum}
   * @memberof ChatMessageRequest
   */
  game?: PlatformEnum;
  /**
   *
   * @type {any}
   * @memberof ChatMessageRequest
   */
  reactions?: any | null;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessageRequest
   */
  isReported?: boolean;
}

/**
 * Check if a given object implements the ChatMessageRequest interface.
 */
export function instanceOfChatMessageRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "messageText" in value;

  return isInstance;
}

export function ChatMessageRequestFromJSON(json: any): ChatMessageRequest {
  return ChatMessageRequestFromJSONTyped(json, false);
}

export function ChatMessageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatMessageRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    room: !exists(json, "room")
      ? undefined
      : ChatMessageRoomEnumFromJSON(json["room"]),
    messageText: json["message_text"],
    createdAt: !exists(json, "created_at")
      ? undefined
      : new Date(json["created_at"]),
    roomId: !exists(json, "room_id") ? undefined : json["room_id"],
    game: !exists(json, "game")
      ? undefined
      : PlatformEnumFromJSON(json["game"]),
    reactions: !exists(json, "reactions") ? undefined : json["reactions"],
    isReported: !exists(json, "is_reported") ? undefined : json["is_reported"],
  };
}

export function ChatMessageRequestToJSON(
  value?: ChatMessageRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    room: ChatMessageRoomEnumToJSON(value.room),
    message_text: value.messageText,
    created_at:
      value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    room_id: value.roomId,
    game: PlatformEnumToJSON(value.game),
    reactions: value.reactions,
    is_reported: value.isReported,
  };
}
