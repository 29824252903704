// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ChatMessageFull } from "./ChatMessageFull";
import {
  ChatMessageFullFromJSON,
  ChatMessageFullFromJSONTyped,
  ChatMessageFullToJSON,
} from "./ChatMessageFull";

/**
 *
 * @export
 * @interface PaginatedChatMessageFullList
 */
export interface PaginatedChatMessageFullList {
  /**
   *
   * @type {string}
   * @memberof PaginatedChatMessageFullList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedChatMessageFullList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<ChatMessageFull>}
   * @memberof PaginatedChatMessageFullList
   */
  results: Array<ChatMessageFull>;
}

/**
 * Check if a given object implements the PaginatedChatMessageFullList interface.
 */
export function instanceOfPaginatedChatMessageFullList(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function PaginatedChatMessageFullListFromJSON(
  json: any,
): PaginatedChatMessageFullList {
  return PaginatedChatMessageFullListFromJSONTyped(json, false);
}

export function PaginatedChatMessageFullListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedChatMessageFullList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    next: !exists(json, "next") ? undefined : json["next"],
    previous: !exists(json, "previous") ? undefined : json["previous"],
    results: (json["results"] as Array<any>).map(ChatMessageFullFromJSON),
  };
}

export function PaginatedChatMessageFullListToJSON(
  value?: PaginatedChatMessageFullList | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    next: value.next,
    previous: value.previous,
    results: (value.results as Array<any>).map(ChatMessageFullToJSON),
  };
}
