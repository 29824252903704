// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `standoff` - Standoff
 * * `genshin` - Genshin
 * * `pubg` - Pubg
 * * `roblox` - Roblox
 * * `cs2` - Cs2
 * @export
 */
export const PlatformEnum = {
  Standoff: "standoff",
  Genshin: "genshin",
  Pubg: "pubg",
  Roblox: "roblox",
  Cs2: "cs2",
} as const;
export type PlatformEnum = (typeof PlatformEnum)[keyof typeof PlatformEnum];

export function PlatformEnumFromJSON(json: any): PlatformEnum {
  return PlatformEnumFromJSONTyped(json, false);
}

export function PlatformEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlatformEnum {
  return json as PlatformEnum;
}

export function PlatformEnumToJSON(value?: PlatformEnum | null): any {
  return value as any;
}
