// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AvatarBorder } from "./AvatarBorder";
import {
  AvatarBorderFromJSON,
  AvatarBorderFromJSONTyped,
  AvatarBorderToJSON,
} from "./AvatarBorder";
import type { InvoiceStatus } from "./InvoiceStatus";
import {
  InvoiceStatusFromJSON,
  InvoiceStatusFromJSONTyped,
  InvoiceStatusToJSON,
} from "./InvoiceStatus";
import type { NicknameDecorationsEnum } from "./NicknameDecorationsEnum";
import {
  NicknameDecorationsEnumFromJSON,
  NicknameDecorationsEnumFromJSONTyped,
  NicknameDecorationsEnumToJSON,
} from "./NicknameDecorationsEnum";
import type { Withdrawal } from "./Withdrawal";
import {
  WithdrawalFromJSON,
  WithdrawalFromJSONTyped,
  WithdrawalToJSON,
} from "./Withdrawal";

/**
 *
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
  /**
   *
   * @type {number}
   * @memberof UserInfo
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  readonly nickname: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  readonly avatar: string;
  /**
   *
   * @type {AvatarBorder}
   * @memberof UserInfo
   */
  readonly avatarBorder: AvatarBorder;
  /**
   *
   * @type {boolean}
   * @memberof UserInfo
   */
  readonly banInChat: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserInfo
   */
  readonly isStaff: boolean;
  /**
   *
   * @type {NicknameDecorationsEnum}
   * @memberof UserInfo
   */
  readonly selectedNicknameDecoration: NicknameDecorationsEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserInfo
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserInfo
   */
  readonly isVerified: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserInfo
   */
  readonly isForeverBanned: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserInfo
   */
  dateJoined: Date;
  /**
   *
   * @type {InvoiceStatus}
   * @memberof UserInfo
   */
  readonly lastInvoice: InvoiceStatus;
  /**
   *
   * @type {Withdrawal}
   * @memberof UserInfo
   */
  readonly lastWithdrawal: Withdrawal;
  /**
   *
   * @type {number}
   * @memberof UserInfo
   */
  readonly depositAmount: number;
  /**
   *
   * @type {number}
   * @memberof UserInfo
   */
  cooldown: number | null;
  /**
   * Дата до которой забанен юзер
   * @type {Date}
   * @memberof UserInfo
   */
  readonly timeEndBan: Date | null;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  readonly banReason: string;
}

/**
 * Check if a given object implements the UserInfo interface.
 */
export function instanceOfUserInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "avatar" in value;
  isInstance = isInstance && "avatarBorder" in value;
  isInstance = isInstance && "banInChat" in value;
  isInstance = isInstance && "isStaff" in value;
  isInstance = isInstance && "selectedNicknameDecoration" in value;
  isInstance = isInstance && "isActive" in value;
  isInstance = isInstance && "isVerified" in value;
  isInstance = isInstance && "isForeverBanned" in value;
  isInstance = isInstance && "dateJoined" in value;
  isInstance = isInstance && "lastInvoice" in value;
  isInstance = isInstance && "lastWithdrawal" in value;
  isInstance = isInstance && "depositAmount" in value;
  isInstance = isInstance && "cooldown" in value;
  isInstance = isInstance && "timeEndBan" in value;
  isInstance = isInstance && "banReason" in value;

  return isInstance;
}

export function UserInfoFromJSON(json: any): UserInfo {
  return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    nickname: json["nickname"],
    avatar: json["avatar"],
    avatarBorder: AvatarBorderFromJSON(json["avatar_border"]),
    banInChat: json["ban_in_chat"],
    isStaff: json["is_staff"],
    selectedNicknameDecoration: NicknameDecorationsEnumFromJSON(
      json["selected_nickname_decoration"],
    ),
    isActive: json["is_active"],
    isVerified: json["is_verified"],
    isForeverBanned: json["is_forever_banned"],
    dateJoined: new Date(json["date_joined"]),
    lastInvoice: InvoiceStatusFromJSON(json["last_invoice"]),
    lastWithdrawal: WithdrawalFromJSON(json["last_withdrawal"]),
    depositAmount: json["deposit_amount"],
    cooldown: json["cooldown"],
    timeEndBan:
      json["time_end_ban"] === null ? null : new Date(json["time_end_ban"]),
    banReason: json["ban_reason"],
  };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_active: value.isActive,
    date_joined: value.dateJoined.toISOString(),
    cooldown: value.cooldown,
  };
}
