// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaymentMethodShort
 */
export interface PaymentMethodShort {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodShort
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodShort
   */
  title: string;
}

/**
 * Check if a given object implements the PaymentMethodShort interface.
 */
export function instanceOfPaymentMethodShort(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "title" in value;

  return isInstance;
}

export function PaymentMethodShortFromJSON(json: any): PaymentMethodShort {
  return PaymentMethodShortFromJSONTyped(json, false);
}

export function PaymentMethodShortFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentMethodShort {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    title: json["title"],
  };
}

export function PaymentMethodShortToJSON(
  value?: PaymentMethodShort | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    title: value.title,
  };
}
