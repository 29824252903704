// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { GenshinServerEnum } from "./GenshinServerEnum";
import {
  GenshinServerEnumFromJSON,
  GenshinServerEnumFromJSONTyped,
  GenshinServerEnumToJSON,
} from "./GenshinServerEnum";

/**
 *
 * @export
 * @interface MakeGenshinWithdrawalRequest
 */
export interface MakeGenshinWithdrawalRequest {
  /**
   *
   * @type {number}
   * @memberof MakeGenshinWithdrawalRequest
   */
  amountBullcoins: number;
  /**
   *
   * @type {string}
   * @memberof MakeGenshinWithdrawalRequest
   */
  genshinUid: string;
  /**
   *
   * @type {GenshinServerEnum}
   * @memberof MakeGenshinWithdrawalRequest
   */
  genshinServer: GenshinServerEnum;
  /**
   *
   * @type {number}
   * @memberof MakeGenshinWithdrawalRequest
   */
  amountMoons?: number;
}

/**
 * Check if a given object implements the MakeGenshinWithdrawalRequest interface.
 */
export function instanceOfMakeGenshinWithdrawalRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountBullcoins" in value;
  isInstance = isInstance && "genshinUid" in value;
  isInstance = isInstance && "genshinServer" in value;

  return isInstance;
}

export function MakeGenshinWithdrawalRequestFromJSON(
  json: any,
): MakeGenshinWithdrawalRequest {
  return MakeGenshinWithdrawalRequestFromJSONTyped(json, false);
}

export function MakeGenshinWithdrawalRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MakeGenshinWithdrawalRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountBullcoins: json["amount_bullcoins"],
    genshinUid: json["genshin_uid"],
    genshinServer: GenshinServerEnumFromJSON(json["genshin_server"]),
    amountMoons: !exists(json, "amount_moons")
      ? undefined
      : json["amount_moons"],
  };
}

export function MakeGenshinWithdrawalRequestToJSON(
  value?: MakeGenshinWithdrawalRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_bullcoins: value.amountBullcoins,
    genshin_uid: value.genshinUid,
    genshin_server: GenshinServerEnumToJSON(value.genshinServer),
    amount_moons: value.amountMoons,
  };
}
