// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `Factory New` - Factory New
 * * `Minimal Wear` - Minimal Wear
 * * `Field-Tested` - Field Tested
 * * `Well-Worn` - Well Worn
 * * `Battle-Scarred` - Battle Scarred
 * @export
 */
export const WearEnum = {
  FactoryNew: "Factory New",
  MinimalWear: "Minimal Wear",
  FieldTested: "Field-Tested",
  WellWorn: "Well-Worn",
  BattleScarred: "Battle-Scarred",
} as const;
export type WearEnum = (typeof WearEnum)[keyof typeof WearEnum];

export function WearEnumFromJSON(json: any): WearEnum {
  return WearEnumFromJSONTyped(json, false);
}

export function WearEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): WearEnum {
  return json as WearEnum;
}

export function WearEnumToJSON(value?: WearEnum | null): any {
  return value as any;
}
