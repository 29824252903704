// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AvatarBorder } from "./AvatarBorder";
import {
  AvatarBorderFromJSON,
  AvatarBorderFromJSONTyped,
  AvatarBorderToJSON,
} from "./AvatarBorder";
import type { NicknameDecorationsEnum } from "./NicknameDecorationsEnum";
import {
  NicknameDecorationsEnumFromJSON,
  NicknameDecorationsEnumFromJSONTyped,
  NicknameDecorationsEnumToJSON,
} from "./NicknameDecorationsEnum";

/**
 *
 * @export
 * @interface ShortUserWithoutChatBan
 */
export interface ShortUserWithoutChatBan {
  /**
   *
   * @type {number}
   * @memberof ShortUserWithoutChatBan
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof ShortUserWithoutChatBan
   */
  readonly nickname: string;
  /**
   *
   * @type {string}
   * @memberof ShortUserWithoutChatBan
   */
  readonly avatar: string;
  /**
   *
   * @type {AvatarBorder}
   * @memberof ShortUserWithoutChatBan
   */
  readonly avatarBorder: AvatarBorder;
  /**
   *
   * @type {NicknameDecorationsEnum}
   * @memberof ShortUserWithoutChatBan
   */
  selectedNicknameDecoration: NicknameDecorationsEnum | null;
}

/**
 * Check if a given object implements the ShortUserWithoutChatBan interface.
 */
export function instanceOfShortUserWithoutChatBan(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "avatar" in value;
  isInstance = isInstance && "avatarBorder" in value;
  isInstance = isInstance && "selectedNicknameDecoration" in value;

  return isInstance;
}

export function ShortUserWithoutChatBanFromJSON(
  json: any,
): ShortUserWithoutChatBan {
  return ShortUserWithoutChatBanFromJSONTyped(json, false);
}

export function ShortUserWithoutChatBanFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ShortUserWithoutChatBan {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    nickname: json["nickname"],
    avatar: json["avatar"],
    avatarBorder: AvatarBorderFromJSON(json["avatar_border"]),
    selectedNicknameDecoration: NicknameDecorationsEnumFromJSON(
      json["selected_nickname_decoration"],
    ),
  };
}

export function ShortUserWithoutChatBanToJSON(
  value?: ShortUserWithoutChatBan | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    selected_nickname_decoration: NicknameDecorationsEnumToJSON(
      value.selectedNicknameDecoration,
    ),
  };
}
