// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  password: string | null;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  nickname: string | null;
}

/**
 * Check if a given object implements the UserCreate interface.
 */
export function instanceOfUserCreate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "password" in value;
  isInstance = isInstance && "nickname" in value;

  return isInstance;
}

export function UserCreateFromJSON(json: any): UserCreate {
  return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    password: json["password"],
    nickname: json["nickname"],
  };
}

export function UserCreateToJSON(value?: UserCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    nickname: value.nickname,
  };
}
