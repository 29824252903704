// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface MakePUBGWithdrawalRequest
 */
export interface MakePUBGWithdrawalRequest {
  /**
   *
   * @type {number}
   * @memberof MakePUBGWithdrawalRequest
   */
  amountBullcoins: number;
  /**
   *
   * @type {string}
   * @memberof MakePUBGWithdrawalRequest
   */
  pubgUid: string;
}

/**
 * Check if a given object implements the MakePUBGWithdrawalRequest interface.
 */
export function instanceOfMakePUBGWithdrawalRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountBullcoins" in value;
  isInstance = isInstance && "pubgUid" in value;

  return isInstance;
}

export function MakePUBGWithdrawalRequestFromJSON(
  json: any,
): MakePUBGWithdrawalRequest {
  return MakePUBGWithdrawalRequestFromJSONTyped(json, false);
}

export function MakePUBGWithdrawalRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MakePUBGWithdrawalRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountBullcoins: json["amount_bullcoins"],
    pubgUid: json["pubg_uid"],
  };
}

export function MakePUBGWithdrawalRequestToJSON(
  value?: MakePUBGWithdrawalRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_bullcoins: value.amountBullcoins,
    pubg_uid: value.pubgUid,
  };
}
