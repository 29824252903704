// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  oldPassword: string;
  /**
   *
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  newPassword: string;
}

/**
 * Check if a given object implements the ChangePasswordRequest interface.
 */
export function instanceOfChangePasswordRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "oldPassword" in value;
  isInstance = isInstance && "newPassword" in value;

  return isInstance;
}

export function ChangePasswordRequestFromJSON(
  json: any,
): ChangePasswordRequest {
  return ChangePasswordRequestFromJSONTyped(json, false);
}

export function ChangePasswordRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChangePasswordRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    oldPassword: json["old_password"],
    newPassword: json["new_password"],
  };
}

export function ChangePasswordRequestToJSON(
  value?: ChangePasswordRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    old_password: value.oldPassword,
    new_password: value.newPassword,
  };
}
