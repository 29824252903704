// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { UpgradeGameStatusEnum } from "./UpgradeGameStatusEnum";
import {
  UpgradeGameStatusEnumFromJSON,
  UpgradeGameStatusEnumFromJSONTyped,
  UpgradeGameStatusEnumToJSON,
} from "./UpgradeGameStatusEnum";

/**
 *
 * @export
 * @interface UpgradeGame
 */
export interface UpgradeGame {
  /**
   *
   * @type {UpgradeGameStatusEnum}
   * @memberof UpgradeGame
   */
  status: UpgradeGameStatusEnum;
}

/**
 * Check if a given object implements the UpgradeGame interface.
 */
export function instanceOfUpgradeGame(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "status" in value;

  return isInstance;
}

export function UpgradeGameFromJSON(json: any): UpgradeGame {
  return UpgradeGameFromJSONTyped(json, false);
}

export function UpgradeGameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpgradeGame {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: UpgradeGameStatusEnumFromJSON(json["status"]),
  };
}

export function UpgradeGameToJSON(value?: UpgradeGame | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: UpgradeGameStatusEnumToJSON(value.status),
  };
}
