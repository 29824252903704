// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AutoCompleteStandoffWithdrawal
 */
export interface AutoCompleteStandoffWithdrawal {
  /**
   *
   * @type {string}
   * @memberof AutoCompleteStandoffWithdrawal
   */
  gameAvatar: string | null;
  /**
   *
   * @type {string}
   * @memberof AutoCompleteStandoffWithdrawal
   */
  gameAccountId: string | null;
}

/**
 * Check if a given object implements the AutoCompleteStandoffWithdrawal interface.
 */
export function instanceOfAutoCompleteStandoffWithdrawal(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "gameAvatar" in value;
  isInstance = isInstance && "gameAccountId" in value;

  return isInstance;
}

export function AutoCompleteStandoffWithdrawalFromJSON(
  json: any,
): AutoCompleteStandoffWithdrawal {
  return AutoCompleteStandoffWithdrawalFromJSONTyped(json, false);
}

export function AutoCompleteStandoffWithdrawalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AutoCompleteStandoffWithdrawal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    gameAvatar: json["game_avatar"],
    gameAccountId: json["game_account_id"],
  };
}

export function AutoCompleteStandoffWithdrawalToJSON(
  value?: AutoCompleteStandoffWithdrawal | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    game_avatar: value.gameAvatar,
    game_account_id: value.gameAccountId,
  };
}
