// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SubjectTag } from "./SubjectTag";
import {
  SubjectTagFromJSON,
  SubjectTagFromJSONTyped,
  SubjectTagToJSON,
} from "./SubjectTag";

/**
 *
 * @export
 * @interface GenshinLootTyped
 */
export interface GenshinLootTyped {
  /**
   *
   * @type {number}
   * @memberof GenshinLootTyped
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof GenshinLootTyped
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof GenshinLootTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GenshinLootTyped
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof GenshinLootTyped
   */
  price: number;
  /**
   *
   * @type {Array<SubjectTag>}
   * @memberof GenshinLootTyped
   */
  readonly tags: Array<SubjectTag>;
  /**
   *
   * @type {number}
   * @memberof GenshinLootTyped
   */
  readonly chance: number;
  /**
   *
   * @type {string}
   * @memberof GenshinLootTyped
   */
  qualityColor: string;
  /**
   *
   * @type {string}
   * @memberof GenshinLootTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the GenshinLootTyped interface.
 */
export function instanceOfGenshinLootTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "tags" in value;
  isInstance = isInstance && "chance" in value;
  isInstance = isInstance && "qualityColor" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function GenshinLootTypedFromJSON(json: any): GenshinLootTyped {
  return GenshinLootTypedFromJSONTyped(json, false);
}

export function GenshinLootTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenshinLootTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    type: json["type"],
    price: json["price"],
    tags: (json["tags"] as Array<any>).map(SubjectTagFromJSON),
    chance: json["chance"],
    qualityColor: json["quality_color"],
    resourcetype: json["resourcetype"],
  };
}

export function GenshinLootTypedToJSON(value?: GenshinLootTyped | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    img: value.img,
    name: value.name,
    type: value.type,
    price: value.price,
    quality_color: value.qualityColor,
    resourcetype: value.resourcetype,
  };
}
