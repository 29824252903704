// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserRewardEvent
 */
export interface UserRewardEvent {
  /**
   * Можно узнать у фронтендеров. Используется в запросах к API в следующем формате: https://bulldrop.net/api/events/{slug}/...
   * @type {string}
   * @memberof UserRewardEvent
   */
  slug: string;
}

/**
 * Check if a given object implements the UserRewardEvent interface.
 */
export function instanceOfUserRewardEvent(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "slug" in value;

  return isInstance;
}

export function UserRewardEventFromJSON(json: any): UserRewardEvent {
  return UserRewardEventFromJSONTyped(json, false);
}

export function UserRewardEventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRewardEvent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    slug: json["slug"],
  };
}

export function UserRewardEventToJSON(value?: UserRewardEvent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    slug: value.slug,
  };
}
