// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface EmailConfirmRequest
 */
export interface EmailConfirmRequest {
  /**
   *
   * @type {string}
   * @memberof EmailConfirmRequest
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof EmailConfirmRequest
   */
  userCode: string;
}

/**
 * Check if a given object implements the EmailConfirmRequest interface.
 */
export function instanceOfEmailConfirmRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userEmail" in value;
  isInstance = isInstance && "userCode" in value;

  return isInstance;
}

export function EmailConfirmRequestFromJSON(json: any): EmailConfirmRequest {
  return EmailConfirmRequestFromJSONTyped(json, false);
}

export function EmailConfirmRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EmailConfirmRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userEmail: json["user_email"],
    userCode: json["user_code"],
  };
}

export function EmailConfirmRequestToJSON(
  value?: EmailConfirmRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_email: value.userEmail,
    user_code: value.userCode,
  };
}
