// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ActivePromoCodeRequest
 */
export interface ActivePromoCodeRequest {
  /**
   *
   * @type {string}
   * @memberof ActivePromoCodeRequest
   */
  percent: string;
  /**
   *
   * @type {string}
   * @memberof ActivePromoCodeRequest
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof ActivePromoCodeRequest
   */
  maxSumForBonus?: number | null;
}

/**
 * Check if a given object implements the ActivePromoCodeRequest interface.
 */
export function instanceOfActivePromoCodeRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "percent" in value;
  isInstance = isInstance && "code" in value;

  return isInstance;
}

export function ActivePromoCodeRequestFromJSON(
  json: any,
): ActivePromoCodeRequest {
  return ActivePromoCodeRequestFromJSONTyped(json, false);
}

export function ActivePromoCodeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivePromoCodeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    percent: json["percent"],
    code: json["code"],
    maxSumForBonus: !exists(json, "max_sum_for_bonus")
      ? undefined
      : json["max_sum_for_bonus"],
  };
}

export function ActivePromoCodeRequestToJSON(
  value?: ActivePromoCodeRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    percent: value.percent,
    code: value.code,
    max_sum_for_bonus: value.maxSumForBonus,
  };
}
