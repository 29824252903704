// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TurnInputRequest
 */
export interface TurnInputRequest {
  /**
   *
   * @type {number}
   * @memberof TurnInputRequest
   */
  choice: number;
}

/**
 * Check if a given object implements the TurnInputRequest interface.
 */
export function instanceOfTurnInputRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "choice" in value;

  return isInstance;
}

export function TurnInputRequestFromJSON(json: any): TurnInputRequest {
  return TurnInputRequestFromJSONTyped(json, false);
}

export function TurnInputRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TurnInputRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    choice: json["choice"],
  };
}

export function TurnInputRequestToJSON(value?: TurnInputRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    choice: value.choice,
  };
}
