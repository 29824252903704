// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `main` - main
 * * `cases_battle` - cases_battle
 * * `coin` - coin
 * * `crash` - crash
 * * `wheel_game` - wheel_game
 * * `roulette` - roulette
 * @export
 */
export const ChatMessageRoomEnum = {
  Main: "main",
  CasesBattle: "cases_battle",
  Coin: "coin",
  Crash: "crash",
  WheelGame: "wheel_game",
  Roulette: "roulette",
} as const;
export type ChatMessageRoomEnum =
  (typeof ChatMessageRoomEnum)[keyof typeof ChatMessageRoomEnum];

export function ChatMessageRoomEnumFromJSON(json: any): ChatMessageRoomEnum {
  return ChatMessageRoomEnumFromJSONTyped(json, false);
}

export function ChatMessageRoomEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatMessageRoomEnum {
  return json as ChatMessageRoomEnum;
}

export function ChatMessageRoomEnumToJSON(
  value?: ChatMessageRoomEnum | null,
): any {
  return value as any;
}
