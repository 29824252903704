// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `main` - Main
 * * `cases_battle` - Cases Battle
 * * `coin` - Coin
 * * `crash` - Crash
 * * `wheel_game` - Wheel Game
 * * `roulette` - Roulette
 * @export
 */
export const ChatMessageFullRoomEnum = {
  Main: "main",
  CasesBattle: "cases_battle",
  Coin: "coin",
  Crash: "crash",
  WheelGame: "wheel_game",
  Roulette: "roulette",
} as const;
export type ChatMessageFullRoomEnum =
  (typeof ChatMessageFullRoomEnum)[keyof typeof ChatMessageFullRoomEnum];

export function ChatMessageFullRoomEnumFromJSON(
  json: any,
): ChatMessageFullRoomEnum {
  return ChatMessageFullRoomEnumFromJSONTyped(json, false);
}

export function ChatMessageFullRoomEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatMessageFullRoomEnum {
  return json as ChatMessageFullRoomEnum;
}

export function ChatMessageFullRoomEnumToJSON(
  value?: ChatMessageFullRoomEnum | null,
): any {
  return value as any;
}
