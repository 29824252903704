// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SubjectTag } from "./SubjectTag";
import {
  SubjectTagFromJSON,
  SubjectTagFromJSONTyped,
  SubjectTagToJSON,
} from "./SubjectTag";

/**
 *
 * @export
 * @interface CS2LootTyped
 */
export interface CS2LootTyped {
  /**
   *
   * @type {number}
   * @memberof CS2LootTyped
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof CS2LootTyped
   */
  price: number;
  /**
   *
   * @type {Array<SubjectTag>}
   * @memberof CS2LootTyped
   */
  readonly tags: Array<SubjectTag>;
  /**
   *
   * @type {number}
   * @memberof CS2LootTyped
   */
  readonly chance: number;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  qualityColor: string;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  wear: string;
  /**
   *
   * @type {boolean}
   * @memberof CS2LootTyped
   */
  stattrack: boolean;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof CS2LootTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the CS2LootTyped interface.
 */
export function instanceOfCS2LootTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "tags" in value;
  isInstance = isInstance && "chance" in value;
  isInstance = isInstance && "qualityColor" in value;
  isInstance = isInstance && "wear" in value;
  isInstance = isInstance && "stattrack" in value;
  isInstance = isInstance && "fullName" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function CS2LootTypedFromJSON(json: any): CS2LootTyped {
  return CS2LootTypedFromJSONTyped(json, false);
}

export function CS2LootTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CS2LootTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    type: json["type"],
    price: json["price"],
    tags: (json["tags"] as Array<any>).map(SubjectTagFromJSON),
    chance: json["chance"],
    qualityColor: json["quality_color"],
    wear: json["wear"],
    stattrack: json["stattrack"],
    fullName: json["full_name"],
    resourcetype: json["resourcetype"],
  };
}

export function CS2LootTypedToJSON(value?: CS2LootTyped | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    img: value.img,
    name: value.name,
    type: value.type,
    price: value.price,
    quality_color: value.qualityColor,
    wear: value.wear,
    stattrack: value.stattrack,
    full_name: value.fullName,
    resourcetype: value.resourcetype,
  };
}
