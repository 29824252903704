// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { StandoffSubjectQualityEnum } from "./StandoffSubjectQualityEnum";
import {
  StandoffSubjectQualityEnumFromJSON,
  StandoffSubjectQualityEnumFromJSONTyped,
  StandoffSubjectQualityEnumToJSON,
} from "./StandoffSubjectQualityEnum";
import type { SubjectTag } from "./SubjectTag";
import {
  SubjectTagFromJSON,
  SubjectTagFromJSONTyped,
  SubjectTagToJSON,
} from "./SubjectTag";

/**
 *
 * @export
 * @interface StandoffSubjectTyped
 */
export interface StandoffSubjectTyped {
  /**
   *
   * @type {number}
   * @memberof StandoffSubjectTyped
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof StandoffSubjectTyped
   */
  readonly img: string;
  /**
   *
   * @type {string}
   * @memberof StandoffSubjectTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof StandoffSubjectTyped
   */
  type: string | null;
  /**
   *
   * @type {number}
   * @memberof StandoffSubjectTyped
   */
  price: number;
  /**
   *
   * @type {Array<SubjectTag>}
   * @memberof StandoffSubjectTyped
   */
  readonly tags: Array<SubjectTag>;
  /**
   *
   * @type {StandoffSubjectQualityEnum}
   * @memberof StandoffSubjectTyped
   */
  qualityColor: StandoffSubjectQualityEnum;
  /**
   *
   * @type {boolean}
   * @memberof StandoffSubjectTyped
   */
  stattrack: boolean;
  /**
   *
   * @type {string}
   * @memberof StandoffSubjectTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the StandoffSubjectTyped interface.
 */
export function instanceOfStandoffSubjectTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "tags" in value;
  isInstance = isInstance && "qualityColor" in value;
  isInstance = isInstance && "stattrack" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function StandoffSubjectTypedFromJSON(json: any): StandoffSubjectTyped {
  return StandoffSubjectTypedFromJSONTyped(json, false);
}

export function StandoffSubjectTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StandoffSubjectTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    type: json["type"],
    price: json["price"],
    tags: (json["tags"] as Array<any>).map(SubjectTagFromJSON),
    qualityColor: StandoffSubjectQualityEnumFromJSON(json["quality_color"]),
    stattrack: json["stattrack"],
    resourcetype: json["resourcetype"],
  };
}

export function StandoffSubjectTypedToJSON(
  value?: StandoffSubjectTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    price: value.price,
    quality_color: StandoffSubjectQualityEnumToJSON(value.qualityColor),
    stattrack: value.stattrack,
    resourcetype: value.resourcetype,
  };
}
