// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
  /**
   *
   * @type {number}
   * @memberof ReportRequest
   */
  messageId: number;
}

/**
 * Check if a given object implements the ReportRequest interface.
 */
export function instanceOfReportRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "messageId" in value;

  return isInstance;
}

export function ReportRequestFromJSON(json: any): ReportRequest {
  return ReportRequestFromJSONTyped(json, false);
}

export function ReportRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReportRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    messageId: json["message_id"],
  };
}

export function ReportRequestToJSON(value?: ReportRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message_id: value.messageId,
  };
}
