// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";

/**
 *
 * @export
 * @interface UpgradeGameBid
 */
export interface UpgradeGameBid {
  /**
   *
   * @type {Subject}
   * @memberof UpgradeGameBid
   */
  subject: Subject;
  /**
   *
   * @type {number}
   * @memberof UpgradeGameBid
   */
  bullcoins: number;
}

/**
 * Check if a given object implements the UpgradeGameBid interface.
 */
export function instanceOfUpgradeGameBid(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "bullcoins" in value;

  return isInstance;
}

export function UpgradeGameBidFromJSON(json: any): UpgradeGameBid {
  return UpgradeGameBidFromJSONTyped(json, false);
}

export function UpgradeGameBidFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpgradeGameBid {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: SubjectFromJSON(json["subject"]),
    bullcoins: json["bullcoins"],
  };
}

export function UpgradeGameBidToJSON(value?: UpgradeGameBid | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subject: SubjectToJSON(value.subject),
    bullcoins: value.bullcoins,
  };
}
