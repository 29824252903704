// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `created` - Создан
 * * `failed` - Ошибка
 * * `success` - Успешно
 * @export
 */
export const TopUpBalanceInvoiceStatusEnum = {
  Created: "created",
  Failed: "failed",
  Success: "success",
} as const;
export type TopUpBalanceInvoiceStatusEnum =
  (typeof TopUpBalanceInvoiceStatusEnum)[keyof typeof TopUpBalanceInvoiceStatusEnum];

export function TopUpBalanceInvoiceStatusEnumFromJSON(
  json: any,
): TopUpBalanceInvoiceStatusEnum {
  return TopUpBalanceInvoiceStatusEnumFromJSONTyped(json, false);
}

export function TopUpBalanceInvoiceStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TopUpBalanceInvoiceStatusEnum {
  return json as TopUpBalanceInvoiceStatusEnum;
}

export function TopUpBalanceInvoiceStatusEnumToJSON(
  value?: TopUpBalanceInvoiceStatusEnum | null,
): any {
  return value as any;
}
