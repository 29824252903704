// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `ru` - ru
 * * `en` - en
 * @export
 */
export const IsoEnum = {
  Ru: "ru",
  En: "en",
} as const;
export type IsoEnum = (typeof IsoEnum)[keyof typeof IsoEnum];

export function IsoEnumFromJSON(json: any): IsoEnum {
  return IsoEnumFromJSONTyped(json, false);
}

export function IsoEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): IsoEnum {
  return json as IsoEnum;
}

export function IsoEnumToJSON(value?: IsoEnum | null): any {
  return value as any;
}
