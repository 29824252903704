// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Rewards } from "./Rewards";
import {
  RewardsFromJSON,
  RewardsFromJSONTyped,
  RewardsToJSON,
} from "./Rewards";

/**
 *
 * @export
 * @interface RewardInfo
 */
export interface RewardInfo {
  /**
   *
   * @type {string}
   * @memberof RewardInfo
   */
  rewardType: string;
  /**
   *
   * @type {Rewards}
   * @memberof RewardInfo
   */
  rewardInfo: Rewards;
}

/**
 * Check if a given object implements the RewardInfo interface.
 */
export function instanceOfRewardInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "rewardType" in value;
  isInstance = isInstance && "rewardInfo" in value;

  return isInstance;
}

export function RewardInfoFromJSON(json: any): RewardInfo {
  return RewardInfoFromJSONTyped(json, false);
}

export function RewardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RewardInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rewardType: json["reward_type"],
    rewardInfo: RewardsFromJSON(json["reward_info"]),
  };
}

export function RewardInfoToJSON(value?: RewardInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reward_type: value.rewardType,
    reward_info: RewardsToJSON(value.rewardInfo),
  };
}
