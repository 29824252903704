// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ActivePromoCode } from "./ActivePromoCode";
import {
  ActivePromoCodeFromJSON,
  ActivePromoCodeFromJSONTyped,
  ActivePromoCodeToJSON,
} from "./ActivePromoCode";
import type { AvatarBorder } from "./AvatarBorder";
import {
  AvatarBorderFromJSON,
  AvatarBorderFromJSONTyped,
  AvatarBorderToJSON,
} from "./AvatarBorder";
import type { LanguageEnum } from "./LanguageEnum";
import {
  LanguageEnumFromJSON,
  LanguageEnumFromJSONTyped,
  LanguageEnumToJSON,
} from "./LanguageEnum";
import type { NicknameDecorationsEnum } from "./NicknameDecorationsEnum";
import {
  NicknameDecorationsEnumFromJSON,
  NicknameDecorationsEnumFromJSONTyped,
  NicknameDecorationsEnumToJSON,
} from "./NicknameDecorationsEnum";
import type { PermissionsEnum } from "./PermissionsEnum";
import {
  PermissionsEnumFromJSON,
  PermissionsEnumFromJSONTyped,
  PermissionsEnumToJSON,
} from "./PermissionsEnum";

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  readonly balance: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  readonly avatar: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  readonly isStaff: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  banInChat: boolean;
  /**
   *
   * @type {ActivePromoCode}
   * @memberof User
   */
  activePromoCode: ActivePromoCode;
  /**
   *
   * @type {AvatarBorder}
   * @memberof User
   */
  readonly avatarBorder: AvatarBorder;
  /**
   *
   * @type {Array<PermissionsEnum>}
   * @memberof User
   */
  permissions: Array<PermissionsEnum> | null;
  /**
   *
   * @type {number}
   * @memberof User
   */
  readonly newYearPercentTopUp: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  readonly topUpMultiplier: number;
  /**
   *
   * @type {Array<NicknameDecorationsEnum>}
   * @memberof User
   */
  readonly availableNicknameDecorations: Array<NicknameDecorationsEnum>;
  /**
   *
   * @type {NicknameDecorationsEnum}
   * @memberof User
   */
  selectedNicknameDecoration: NicknameDecorationsEnum | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone: string | null;
  /**
   *
   * @type {LanguageEnum}
   * @memberof User
   */
  language: LanguageEnum;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  readonly isBonusHunter: boolean;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "balance" in value;
  isInstance = isInstance && "avatar" in value;
  isInstance = isInstance && "isStaff" in value;
  isInstance = isInstance && "banInChat" in value;
  isInstance = isInstance && "activePromoCode" in value;
  isInstance = isInstance && "avatarBorder" in value;
  isInstance = isInstance && "permissions" in value;
  isInstance = isInstance && "newYearPercentTopUp" in value;
  isInstance = isInstance && "topUpMultiplier" in value;
  isInstance = isInstance && "availableNicknameDecorations" in value;
  isInstance = isInstance && "selectedNicknameDecoration" in value;
  isInstance = isInstance && "phone" in value;
  isInstance = isInstance && "language" in value;
  isInstance = isInstance && "isBonusHunter" in value;

  return isInstance;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    nickname: json["nickname"],
    balance: json["balance"],
    avatar: json["avatar"],
    isStaff: json["is_staff"],
    banInChat: json["ban_in_chat"],
    activePromoCode: ActivePromoCodeFromJSON(json["active_promo_code"]),
    avatarBorder: AvatarBorderFromJSON(json["avatar_border"]),
    permissions:
      json["permissions"] === null
        ? null
        : (json["permissions"] as Array<any>).map(PermissionsEnumFromJSON),
    newYearPercentTopUp: json["new_year_percent_top_up"],
    topUpMultiplier: json["top_up_multiplier"],
    availableNicknameDecorations: (
      json["available_nickname_decorations"] as Array<any>
    ).map(NicknameDecorationsEnumFromJSON),
    selectedNicknameDecoration: NicknameDecorationsEnumFromJSON(
      json["selected_nickname_decoration"],
    ),
    phone: json["phone"],
    language: LanguageEnumFromJSON(json["language"]),
    isBonusHunter: json["is_bonus_hunter"],
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    nickname: value.nickname,
    ban_in_chat: value.banInChat,
    active_promo_code: ActivePromoCodeToJSON(value.activePromoCode),
    permissions:
      value.permissions === null
        ? null
        : (value.permissions as Array<any>).map(PermissionsEnumToJSON),
    selected_nickname_decoration: NicknameDecorationsEnumToJSON(
      value.selectedNicknameDecoration,
    ),
    phone: value.phone,
    language: LanguageEnumToJSON(value.language),
  };
}
