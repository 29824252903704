// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `fe3852` - Красный
 * * `d64bf3` - Розовый
 * * `4867ff` - Синий
 * * `9059ff` - Фиолетовый
 * * `71aeec` - Голубой
 * * `aaaaaa` - Серый
 * * `ffc259` - Золотой
 * @export
 */
export const CS2SubjectQualityColorEnum = {
  Fe3852: "fe3852",
  D64bf3: "d64bf3",
  _4867ff: "4867ff",
  _9059ff: "9059ff",
  _71aeec: "71aeec",
  Aaaaaa: "aaaaaa",
  Ffc259: "ffc259",
} as const;
export type CS2SubjectQualityColorEnum =
  (typeof CS2SubjectQualityColorEnum)[keyof typeof CS2SubjectQualityColorEnum];

export function CS2SubjectQualityColorEnumFromJSON(
  json: any,
): CS2SubjectQualityColorEnum {
  return CS2SubjectQualityColorEnumFromJSONTyped(json, false);
}

export function CS2SubjectQualityColorEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CS2SubjectQualityColorEnum {
  return json as CS2SubjectQualityColorEnum;
}

export function CS2SubjectQualityColorEnumToJSON(
  value?: CS2SubjectQualityColorEnum | null,
): any {
  return value as any;
}
