// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Contacts
 */
export interface Contacts {
  /**
   *
   * @type {string}
   * @memberof Contacts
   */
  companyName: string;
  /**
   *
   * @type {string}
   * @memberof Contacts
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof Contacts
   */
  inn: string | null;
  /**
   *
   * @type {string}
   * @memberof Contacts
   */
  companyId: string | null;
  /**
   *
   * @type {string}
   * @memberof Contacts
   */
  extra: string | null;
}

/**
 * Check if a given object implements the Contacts interface.
 */
export function instanceOfContacts(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "companyName" in value;
  isInstance = isInstance && "address" in value;
  isInstance = isInstance && "inn" in value;
  isInstance = isInstance && "companyId" in value;
  isInstance = isInstance && "extra" in value;

  return isInstance;
}

export function ContactsFromJSON(json: any): Contacts {
  return ContactsFromJSONTyped(json, false);
}

export function ContactsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Contacts {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    companyName: json["company_name"],
    address: json["address"],
    inn: json["inn"],
    companyId: json["company_id"],
    extra: json["extra"],
  };
}

export function ContactsToJSON(value?: Contacts | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    company_name: value.companyName,
    address: value.address,
    inn: value.inn,
    company_id: value.companyId,
    extra: value.extra,
  };
}
