<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.15679 7.34321C7.64601 6.85399 8.50214 6.69674 9.16608 7.36068L13.8136 12.0082L18.4613 7.36061C19.1252 6.69667 19.9639 6.83645 20.4705 7.34314C20.9598 7.83236 21.117 8.68848 20.4531 9.35242L15.8054 14L20.453 18.6476C21.117 19.3116 20.9597 20.1677 20.4705 20.6569C19.9638 21.1636 19.1251 21.3034 18.4612 20.6394L13.8136 15.9919L9.16613 20.6394C8.50219 21.3033 7.64606 21.1461 7.15684 20.6568C6.65015 20.1502 6.51038 19.3115 7.17431 18.6476L11.8218 14.0001L7.17426 9.3525C6.51033 8.68856 6.6501 7.8499 7.15679 7.34321Z"
    />
  </svg>
</template>
