// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Socials
 */
export interface Socials {
  /**
   *
   * @type {string}
   * @memberof Socials
   */
  vkId: string | null;
  /**
   *
   * @type {string}
   * @memberof Socials
   */
  telegramId: string | null;
  /**
   *
   * @type {string}
   * @memberof Socials
   */
  steamId: string | null;
  /**
   *
   * @type {string}
   * @memberof Socials
   */
  facebookId: string | null;
  /**
   *
   * @type {string}
   * @memberof Socials
   */
  yandexId: string | null;
}

/**
 * Check if a given object implements the Socials interface.
 */
export function instanceOfSocials(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "vkId" in value;
  isInstance = isInstance && "telegramId" in value;
  isInstance = isInstance && "steamId" in value;
  isInstance = isInstance && "facebookId" in value;
  isInstance = isInstance && "yandexId" in value;

  return isInstance;
}

export function SocialsFromJSON(json: any): Socials {
  return SocialsFromJSONTyped(json, false);
}

export function SocialsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Socials {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vkId: json["vk_id"],
    telegramId: json["telegram_id"],
    steamId: json["steam_id"],
    facebookId: json["facebook_id"],
    yandexId: json["yandex_id"],
  };
}

export function SocialsToJSON(value?: Socials | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vk_id: value.vkId,
    telegram_id: value.telegramId,
    steam_id: value.steamId,
    facebook_id: value.facebookId,
    yandex_id: value.yandexId,
  };
}
