// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AuthUrl
 */
export interface AuthUrl {
  /**
   *
   * @type {string}
   * @memberof AuthUrl
   */
  url: string;
}

/**
 * Check if a given object implements the AuthUrl interface.
 */
export function instanceOfAuthUrl(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "url" in value;

  return isInstance;
}

export function AuthUrlFromJSON(json: any): AuthUrl {
  return AuthUrlFromJSONTyped(json, false);
}

export function AuthUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthUrl {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: json["url"],
  };
}

export function AuthUrlToJSON(value?: AuthUrl | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
  };
}
