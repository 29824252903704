// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { GenshinSubjectQualityColorEnum } from "./GenshinSubjectQualityColorEnum";
import {
  GenshinSubjectQualityColorEnumFromJSON,
  GenshinSubjectQualityColorEnumFromJSONTyped,
  GenshinSubjectQualityColorEnumToJSON,
} from "./GenshinSubjectQualityColorEnum";
import type { SubjectTag } from "./SubjectTag";
import {
  SubjectTagFromJSON,
  SubjectTagFromJSONTyped,
  SubjectTagToJSON,
} from "./SubjectTag";

/**
 *
 * @export
 * @interface GenshinSubjectTyped
 */
export interface GenshinSubjectTyped {
  /**
   *
   * @type {number}
   * @memberof GenshinSubjectTyped
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof GenshinSubjectTyped
   */
  readonly img: string;
  /**
   *
   * @type {string}
   * @memberof GenshinSubjectTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GenshinSubjectTyped
   */
  type: string | null;
  /**
   *
   * @type {number}
   * @memberof GenshinSubjectTyped
   */
  price: number;
  /**
   *
   * @type {Array<SubjectTag>}
   * @memberof GenshinSubjectTyped
   */
  readonly tags: Array<SubjectTag>;
  /**
   *
   * @type {GenshinSubjectQualityColorEnum}
   * @memberof GenshinSubjectTyped
   */
  qualityColor: GenshinSubjectQualityColorEnum;
  /**
   *
   * @type {string}
   * @memberof GenshinSubjectTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the GenshinSubjectTyped interface.
 */
export function instanceOfGenshinSubjectTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "tags" in value;
  isInstance = isInstance && "qualityColor" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function GenshinSubjectTypedFromJSON(json: any): GenshinSubjectTyped {
  return GenshinSubjectTypedFromJSONTyped(json, false);
}

export function GenshinSubjectTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenshinSubjectTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    type: json["type"],
    price: json["price"],
    tags: (json["tags"] as Array<any>).map(SubjectTagFromJSON),
    qualityColor: GenshinSubjectQualityColorEnumFromJSON(json["quality_color"]),
    resourcetype: json["resourcetype"],
  };
}

export function GenshinSubjectTypedToJSON(
  value?: GenshinSubjectTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    price: value.price,
    quality_color: GenshinSubjectQualityColorEnumToJSON(value.qualityColor),
    resourcetype: value.resourcetype,
  };
}
