// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";

/**
 *
 * @export
 * @interface SubjectInventoryTyped
 */
export interface SubjectInventoryTyped {
  /**
   *
   * @type {number}
   * @memberof SubjectInventoryTyped
   */
  readonly id: number;
  /**
   *
   * @type {Subject}
   * @memberof SubjectInventoryTyped
   */
  readonly subject: Subject;
  /**
   *
   * @type {Date}
   * @memberof SubjectInventoryTyped
   */
  dtAdded: Date;
  /**
   *
   * @type {string}
   * @memberof SubjectInventoryTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the SubjectInventoryTyped interface.
 */
export function instanceOfSubjectInventoryTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "dtAdded" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function SubjectInventoryTypedFromJSON(
  json: any,
): SubjectInventoryTyped {
  return SubjectInventoryTypedFromJSONTyped(json, false);
}

export function SubjectInventoryTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubjectInventoryTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    subject: SubjectFromJSON(json["subject"]),
    dtAdded: new Date(json["dt_added"]),
    resourcetype: json["resourcetype"],
  };
}

export function SubjectInventoryTypedToJSON(
  value?: SubjectInventoryTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dt_added: value.dtAdded.toISOString(),
    resourcetype: value.resourcetype,
  };
}
