// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  MarkViewedAllNotifications,
  Notification,
  PaginatedNotificationList,
} from "../models/index";
import {
  MarkViewedAllNotificationsFromJSON,
  MarkViewedAllNotificationsToJSON,
  NotificationFromJSON,
  NotificationToJSON,
  PaginatedNotificationListFromJSON,
  PaginatedNotificationListToJSON,
} from "../models/index";

export interface NotificationsApiAllListRequest {
  noScanned?: string;
  page?: number;
}

/**
 *
 */
export class NotificationsApi extends runtime.BaseAPI {
  /**
   * Список всех уведомлений пользователя. GET-параметр `no_scanned` отфильтрует по непросмотренным
   */
  async allListRaw(
    requestParameters: NotificationsApiAllListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedNotificationList>> {
    const queryParameters: any = {};

    if (requestParameters["noScanned"] != null) {
      queryParameters["no_scanned"] = requestParameters["noScanned"];
    }

    if (requestParameters["page"] != null) {
      queryParameters["page"] = requestParameters["page"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/notifications/all/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedNotificationListFromJSON(jsonValue),
    );
  }

  /**
   * Список всех уведомлений пользователя. GET-параметр `no_scanned` отфильтрует по непросмотренным
   */
  async allList(
    requestParameters: NotificationsApiAllListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedNotificationList> {
    const response = await this.allListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Проверка, просмотрены ли уведомления
   */
  async checkRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Notification>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/notifications/check`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NotificationFromJSON(jsonValue),
    );
  }

  /**
   * Проверка, просмотрены ли уведомления
   */
  async checkRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Notification> {
    const response = await this.checkRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Помечает все уведомления, как просмотренные
   */
  async scanedAllCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MarkViewedAllNotifications>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/notifications/scaned/all/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MarkViewedAllNotificationsFromJSON(jsonValue),
    );
  }

  /**
   * Помечает все уведомления, как просмотренные
   */
  async scanedAllCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MarkViewedAllNotifications> {
    const response = await this.scanedAllCreateRaw(initOverrides);
    return await response.value();
  }

  /**
   * Возвращает количество непрочитанных уведомлений
   */
  async unviewedCountRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Notification>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/notifications/unviewed_count`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NotificationFromJSON(jsonValue),
    );
  }

  /**
   * Возвращает количество непрочитанных уведомлений
   */
  async unviewedCountRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Notification> {
    const response = await this.unviewedCountRetrieveRaw(initOverrides);
    return await response.value();
  }
}
