// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { NicknameDecorationLoot } from "./NicknameDecorationLoot";
import {
  NicknameDecorationLootFromJSON,
  NicknameDecorationLootFromJSONTyped,
  NicknameDecorationLootToJSON,
} from "./NicknameDecorationLoot";

/**
 *
 * @export
 * @interface DecorationRewardInfo
 */
export interface DecorationRewardInfo {
  /**
   *
   * @type {string}
   * @memberof DecorationRewardInfo
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof DecorationRewardInfo
   */
  readonly isAvailable: boolean;
  /**
   *
   * @type {Array<NicknameDecorationLoot>}
   * @memberof DecorationRewardInfo
   */
  readonly subjects: Array<NicknameDecorationLoot>;
  /**
   *
   * @type {string}
   * @memberof DecorationRewardInfo
   */
  icon: string | null;
}

/**
 * Check if a given object implements the DecorationRewardInfo interface.
 */
export function instanceOfDecorationRewardInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "isAvailable" in value;
  isInstance = isInstance && "subjects" in value;
  isInstance = isInstance && "icon" in value;

  return isInstance;
}

export function DecorationRewardInfoFromJSON(json: any): DecorationRewardInfo {
  return DecorationRewardInfoFromJSONTyped(json, false);
}

export function DecorationRewardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DecorationRewardInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    isAvailable: json["is_available"],
    subjects: (json["subjects"] as Array<any>).map(
      NicknameDecorationLootFromJSON,
    ),
    icon: json["icon"],
  };
}

export function DecorationRewardInfoToJSON(
  value?: DecorationRewardInfo | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    icon: value.icon,
  };
}
