// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BloggersBattleCases } from "./BloggersBattleCases";
import {
  BloggersBattleCasesFromJSON,
  BloggersBattleCasesFromJSONTyped,
  BloggersBattleCasesToJSON,
} from "./BloggersBattleCases";

/**
 *
 * @export
 * @interface BloggersBattle
 */
export interface BloggersBattle {
  /**
   *
   * @type {Date}
   * @memberof BloggersBattle
   */
  startDate: Date | null;
  /**
   *
   * @type {Date}
   * @memberof BloggersBattle
   */
  endDate: Date | null;
  /**
   *
   * @type {Array<BloggersBattleCases>}
   * @memberof BloggersBattle
   */
  readonly cases: Array<BloggersBattleCases>;
}

/**
 * Check if a given object implements the BloggersBattle interface.
 */
export function instanceOfBloggersBattle(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "cases" in value;

  return isInstance;
}

export function BloggersBattleFromJSON(json: any): BloggersBattle {
  return BloggersBattleFromJSONTyped(json, false);
}

export function BloggersBattleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BloggersBattle {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startDate:
      json["start_date"] === null ? null : new Date(json["start_date"]),
    endDate: json["end_date"] === null ? null : new Date(json["end_date"]),
    cases: (json["cases"] as Array<any>).map(BloggersBattleCasesFromJSON),
  };
}

export function BloggersBattleToJSON(value?: BloggersBattle | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start_date: value.startDate === null ? null : value.startDate.toISOString(),
    end_date: value.endDate === null ? null : value.endDate.toISOString(),
  };
}
