// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `aaaaaa` - Серый
 * * `56bf8f` - Зелёный
 * * `667fff` - Синий
 * * `8a5ce5` - Фиолетовый
 * * `d766ed` - Розовый
 * * `e52a5b` - Красный
 * * `ffc259` - Золотой
 * @export
 */
export const PUBGSubjectQualityColorEnum = {
  Aaaaaa: "aaaaaa",
  _56bf8f: "56bf8f",
  _667fff: "667fff",
  _8a5ce5: "8a5ce5",
  D766ed: "d766ed",
  E52a5b: "e52a5b",
  Ffc259: "ffc259",
} as const;
export type PUBGSubjectQualityColorEnum =
  (typeof PUBGSubjectQualityColorEnum)[keyof typeof PUBGSubjectQualityColorEnum];

export function PUBGSubjectQualityColorEnumFromJSON(
  json: any,
): PUBGSubjectQualityColorEnum {
  return PUBGSubjectQualityColorEnumFromJSONTyped(json, false);
}

export function PUBGSubjectQualityColorEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PUBGSubjectQualityColorEnum {
  return json as PUBGSubjectQualityColorEnum;
}

export function PUBGSubjectQualityColorEnumToJSON(
  value?: PUBGSubjectQualityColorEnum | null,
): any {
  return value as any;
}
