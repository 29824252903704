// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { TranslationKeyRequest } from "./TranslationKeyRequest";
import {
  TranslationKeyRequestFromJSON,
  TranslationKeyRequestFromJSONTyped,
  TranslationKeyRequestToJSON,
} from "./TranslationKeyRequest";
import type { TranslationLangRequest } from "./TranslationLangRequest";
import {
  TranslationLangRequestFromJSON,
  TranslationLangRequestFromJSONTyped,
  TranslationLangRequestToJSON,
} from "./TranslationLangRequest";

/**
 *
 * @export
 * @interface FullTranslationRequest
 */
export interface FullTranslationRequest {
  /**
   *
   * @type {number}
   * @memberof FullTranslationRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FullTranslationRequest
   */
  value: string;
  /**
   *
   * @type {TranslationLangRequest}
   * @memberof FullTranslationRequest
   */
  language: TranslationLangRequest;
  /**
   *
   * @type {TranslationKeyRequest}
   * @memberof FullTranslationRequest
   */
  key: TranslationKeyRequest;
}

/**
 * Check if a given object implements the FullTranslationRequest interface.
 */
export function instanceOfFullTranslationRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "value" in value;
  isInstance = isInstance && "language" in value;
  isInstance = isInstance && "key" in value;

  return isInstance;
}

export function FullTranslationRequestFromJSON(
  json: any,
): FullTranslationRequest {
  return FullTranslationRequestFromJSONTyped(json, false);
}

export function FullTranslationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FullTranslationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    value: json["value"],
    language: TranslationLangRequestFromJSON(json["language"]),
    key: TranslationKeyRequestFromJSON(json["key"]),
  };
}

export function FullTranslationRequestToJSON(
  value?: FullTranslationRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    value: value.value,
    language: TranslationLangRequestToJSON(value.language),
    key: TranslationKeyRequestToJSON(value.key),
  };
}
