// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SkinsbackWithdrawalOrder } from "./SkinsbackWithdrawalOrder";
import {
  SkinsbackWithdrawalOrderFromJSON,
  SkinsbackWithdrawalOrderFromJSONTyped,
  SkinsbackWithdrawalOrderToJSON,
} from "./SkinsbackWithdrawalOrder";
import type { WithdrawalStatusEnum } from "./WithdrawalStatusEnum";
import {
  WithdrawalStatusEnumFromJSON,
  WithdrawalStatusEnumFromJSONTyped,
  WithdrawalStatusEnumToJSON,
} from "./WithdrawalStatusEnum";

/**
 *
 * @export
 * @interface SkinsbackWithdrawalFullTyped
 */
export interface SkinsbackWithdrawalFullTyped {
  /**
   *
   * @type {number}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  amountBullcoins: number;
  /**
   *
   * @type {string}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  tradeUrl: string;
  /**
   *
   * @type {Array<SkinsbackWithdrawalOrder>}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  orders: Array<SkinsbackWithdrawalOrder>;
  /**
   *
   * @type {WithdrawalStatusEnum}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  status: WithdrawalStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  created: Date;
  /**
   *
   * @type {string}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  cancelComment: string | null;
  /**
   *
   * @type {string}
   * @memberof SkinsbackWithdrawalFullTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the SkinsbackWithdrawalFullTyped interface.
 */
export function instanceOfSkinsbackWithdrawalFullTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "amountBullcoins" in value;
  isInstance = isInstance && "tradeUrl" in value;
  isInstance = isInstance && "orders" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "cancelComment" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function SkinsbackWithdrawalFullTypedFromJSON(
  json: any,
): SkinsbackWithdrawalFullTyped {
  return SkinsbackWithdrawalFullTypedFromJSONTyped(json, false);
}

export function SkinsbackWithdrawalFullTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SkinsbackWithdrawalFullTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    amountBullcoins: json["amount_bullcoins"],
    tradeUrl: json["trade_url"],
    orders: (json["orders"] as Array<any>).map(
      SkinsbackWithdrawalOrderFromJSON,
    ),
    status: WithdrawalStatusEnumFromJSON(json["status"]),
    created: new Date(json["created"]),
    cancelComment: json["cancel_comment"],
    resourcetype: json["resourcetype"],
  };
}

export function SkinsbackWithdrawalFullTypedToJSON(
  value?: SkinsbackWithdrawalFullTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_bullcoins: value.amountBullcoins,
    trade_url: value.tradeUrl,
    orders: (value.orders as Array<any>).map(SkinsbackWithdrawalOrderToJSON),
    status: WithdrawalStatusEnumToJSON(value.status),
    created: value.created.toISOString(),
    cancel_comment: value.cancelComment,
    resourcetype: value.resourcetype,
  };
}
