// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";

/**
 *
 * @export
 * @interface Inventory
 */
export interface Inventory {
  /**
   *
   * @type {number}
   * @memberof Inventory
   */
  readonly id: number;
  /**
   *
   * @type {Subject}
   * @memberof Inventory
   */
  readonly subject: Subject;
  /**
   *
   * @type {Date}
   * @memberof Inventory
   */
  dtAdded: Date;
}

/**
 * Check if a given object implements the Inventory interface.
 */
export function instanceOfInventory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "dtAdded" in value;

  return isInstance;
}

export function InventoryFromJSON(json: any): Inventory {
  return InventoryFromJSONTyped(json, false);
}

export function InventoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Inventory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    subject: SubjectFromJSON(json["subject"]),
    dtAdded: new Date(json["dt_added"]),
  };
}

export function InventoryToJSON(value?: Inventory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    dt_added: value.dtAdded.toISOString(),
  };
}
