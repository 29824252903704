// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DeviceCreateRequest,
  SendPushNotificationRequest,
} from "../models/index";
import {
  DeviceCreateRequestFromJSON,
  DeviceCreateRequestToJSON,
  SendPushNotificationRequestFromJSON,
  SendPushNotificationRequestToJSON,
} from "../models/index";

export interface PushApiCreateTokenRequest {
  deviceCreateRequest: DeviceCreateRequest;
}

export interface PushApiSendToRequest {
  sendPushNotificationRequest: SendPushNotificationRequest;
}

/**
 *
 */
export class PushApi extends runtime.BaseAPI {
  /**
   */
  async createTokenRaw(
    requestParameters: PushApiCreateTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["deviceCreateRequest"] == null) {
      throw new runtime.RequiredError(
        "deviceCreateRequest",
        'Required parameter "deviceCreateRequest" was null or undefined when calling createToken().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/push/create-token/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DeviceCreateRequestToJSON(
          requestParameters["deviceCreateRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async createToken(
    requestParameters: PushApiCreateTokenRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createTokenRaw(requestParameters, initOverrides);
  }

  /**
   */
  async sendToRaw(
    requestParameters: PushApiSendToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["sendPushNotificationRequest"] == null) {
      throw new runtime.RequiredError(
        "sendPushNotificationRequest",
        'Required parameter "sendPushNotificationRequest" was null or undefined when calling sendTo().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/push/send-to/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SendPushNotificationRequestToJSON(
          requestParameters["sendPushNotificationRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async sendTo(
    requestParameters: PushApiSendToRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendToRaw(requestParameters, initOverrides);
  }
}
