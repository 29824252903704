// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";

/**
 *
 * @export
 * @interface UpgradeRecommendedSubject
 */
export interface UpgradeRecommendedSubject {
  /**
   *
   * @type {number}
   * @memberof UpgradeRecommendedSubject
   */
  readonly id: number;
  /**
   *
   * @type {Subject}
   * @memberof UpgradeRecommendedSubject
   */
  readonly subject: Subject;
}

/**
 * Check if a given object implements the UpgradeRecommendedSubject interface.
 */
export function instanceOfUpgradeRecommendedSubject(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "subject" in value;

  return isInstance;
}

export function UpgradeRecommendedSubjectFromJSON(
  json: any,
): UpgradeRecommendedSubject {
  return UpgradeRecommendedSubjectFromJSONTyped(json, false);
}

export function UpgradeRecommendedSubjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpgradeRecommendedSubject {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    subject: SubjectFromJSON(json["subject"]),
  };
}

export function UpgradeRecommendedSubjectToJSON(
  value?: UpgradeRecommendedSubject | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
