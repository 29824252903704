// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RobloxWithdrawalCalculateResponse
 */
export interface RobloxWithdrawalCalculateResponse {
  /**
   *
   * @type {number}
   * @memberof RobloxWithdrawalCalculateResponse
   */
  amountRobux: number;
  /**
   *
   * @type {number}
   * @memberof RobloxWithdrawalCalculateResponse
   */
  bullcoinsToReturn: number;
}

/**
 * Check if a given object implements the RobloxWithdrawalCalculateResponse interface.
 */
export function instanceOfRobloxWithdrawalCalculateResponse(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountRobux" in value;
  isInstance = isInstance && "bullcoinsToReturn" in value;

  return isInstance;
}

export function RobloxWithdrawalCalculateResponseFromJSON(
  json: any,
): RobloxWithdrawalCalculateResponse {
  return RobloxWithdrawalCalculateResponseFromJSONTyped(json, false);
}

export function RobloxWithdrawalCalculateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RobloxWithdrawalCalculateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountRobux: json["amount_robux"],
    bullcoinsToReturn: json["bullcoins_to_return"],
  };
}

export function RobloxWithdrawalCalculateResponseToJSON(
  value?: RobloxWithdrawalCalculateResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_robux: value.amountRobux,
    bullcoins_to_return: value.bullcoinsToReturn,
  };
}
