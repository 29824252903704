// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Article
 */
export interface Article {
  /**
   *
   * @type {string}
   * @memberof Article
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  header: string;
  /**
   *
   * @type {string}
   * @memberof Article
   */
  readonly text: string;
}

/**
 * Check if a given object implements the Article interface.
 */
export function instanceOfArticle(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "header" in value;
  isInstance = isInstance && "text" in value;

  return isInstance;
}

export function ArticleFromJSON(json: any): Article {
  return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Article {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    slug: json["slug"],
    header: json["header"],
    text: json["text"],
  };
}

export function ArticleToJSON(value?: Article | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    slug: value.slug,
    header: value.header,
  };
}
