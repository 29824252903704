// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ModeEnum } from "./ModeEnum";
import {
  ModeEnumFromJSON,
  ModeEnumFromJSONTyped,
  ModeEnumToJSON,
} from "./ModeEnum";

/**
 *
 * @export
 * @interface StartGameInputRequest
 */
export interface StartGameInputRequest {
  /**
   *
   * @type {ModeEnum}
   * @memberof StartGameInputRequest
   */
  mode: ModeEnum;
  /**
   *
   * @type {number}
   * @memberof StartGameInputRequest
   */
  rate: number;
}

/**
 * Check if a given object implements the StartGameInputRequest interface.
 */
export function instanceOfStartGameInputRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "mode" in value;
  isInstance = isInstance && "rate" in value;

  return isInstance;
}

export function StartGameInputRequestFromJSON(
  json: any,
): StartGameInputRequest {
  return StartGameInputRequestFromJSONTyped(json, false);
}

export function StartGameInputRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StartGameInputRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    mode: ModeEnumFromJSON(json["mode"]),
    rate: json["rate"],
  };
}

export function StartGameInputRequestToJSON(
  value?: StartGameInputRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    mode: ModeEnumToJSON(value.mode),
    rate: value.rate,
  };
}
