// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";

/**
 *
 * @export
 * @interface SubjectRewardTyped
 */
export interface SubjectRewardTyped {
  /**
   *
   * @type {Subject}
   * @memberof SubjectRewardTyped
   */
  readonly subject: Subject;
  /**
   *
   * @type {string}
   * @memberof SubjectRewardTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the SubjectRewardTyped interface.
 */
export function instanceOfSubjectRewardTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function SubjectRewardTypedFromJSON(json: any): SubjectRewardTyped {
  return SubjectRewardTypedFromJSONTyped(json, false);
}

export function SubjectRewardTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubjectRewardTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subject: SubjectFromJSON(json["subject"]),
    resourcetype: json["resourcetype"],
  };
}

export function SubjectRewardTypedToJSON(
  value?: SubjectRewardTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    resourcetype: value.resourcetype,
  };
}
