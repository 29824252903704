// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `started` - Started
 * * `processing` - Processing
 * * `won` - Won
 * * `lost` - Lost
 * @export
 */
export const LadderGameStatusEnum = {
  Started: "started",
  Processing: "processing",
  Won: "won",
  Lost: "lost",
} as const;
export type LadderGameStatusEnum =
  (typeof LadderGameStatusEnum)[keyof typeof LadderGameStatusEnum];

export function LadderGameStatusEnumFromJSON(json: any): LadderGameStatusEnum {
  return LadderGameStatusEnumFromJSONTyped(json, false);
}

export function LadderGameStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LadderGameStatusEnum {
  return json as LadderGameStatusEnum;
}

export function LadderGameStatusEnumToJSON(
  value?: LadderGameStatusEnum | null,
): any {
  return value as any;
}
