// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NicknameDecorationRewardTyped
 */
export interface NicknameDecorationRewardTyped {
  /**
   *
   * @type {string}
   * @memberof NicknameDecorationRewardTyped
   */
  decoration: string;
  /**
   *
   * @type {string}
   * @memberof NicknameDecorationRewardTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof NicknameDecorationRewardTyped
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof NicknameDecorationRewardTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the NicknameDecorationRewardTyped interface.
 */
export function instanceOfNicknameDecorationRewardTyped(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "decoration" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function NicknameDecorationRewardTypedFromJSON(
  json: any,
): NicknameDecorationRewardTyped {
  return NicknameDecorationRewardTypedFromJSONTyped(json, false);
}

export function NicknameDecorationRewardTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NicknameDecorationRewardTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    decoration: json["decoration"],
    name: json["name"],
    img: json["img"],
    resourcetype: json["resourcetype"],
  };
}

export function NicknameDecorationRewardTypedToJSON(
  value?: NicknameDecorationRewardTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    decoration: value.decoration,
    name: value.name,
    img: value.img,
    resourcetype: value.resourcetype,
  };
}
