// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SkinsbackInfo
 */
export interface SkinsbackInfo {
  /**
   *
   * @type {string}
   * @memberof SkinsbackInfo
   */
  readonly tradeUrl: string | null;
}

/**
 * Check if a given object implements the SkinsbackInfo interface.
 */
export function instanceOfSkinsbackInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "tradeUrl" in value;

  return isInstance;
}

export function SkinsbackInfoFromJSON(json: any): SkinsbackInfo {
  return SkinsbackInfoFromJSONTyped(json, false);
}

export function SkinsbackInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SkinsbackInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tradeUrl: json["trade_url"],
  };
}

export function SkinsbackInfoToJSON(value?: SkinsbackInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {};
}
