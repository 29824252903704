// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ReferralAccount } from "./ReferralAccount";
import {
  ReferralAccountFromJSON,
  ReferralAccountFromJSONTyped,
  ReferralAccountToJSON,
} from "./ReferralAccount";
import type { ReferrerAccount } from "./ReferrerAccount";
import {
  ReferrerAccountFromJSON,
  ReferrerAccountFromJSONTyped,
  ReferrerAccountToJSON,
} from "./ReferrerAccount";

/**
 *
 * @export
 * @interface ReferralSystemAccount
 */
export interface ReferralSystemAccount {
  /**
   *
   * @type {ReferralAccount}
   * @memberof ReferralSystemAccount
   */
  referralAccount: ReferralAccount | null;
  /**
   *
   * @type {ReferrerAccount}
   * @memberof ReferralSystemAccount
   */
  referrerAccount: ReferrerAccount;
}

/**
 * Check if a given object implements the ReferralSystemAccount interface.
 */
export function instanceOfReferralSystemAccount(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "referralAccount" in value;
  isInstance = isInstance && "referrerAccount" in value;

  return isInstance;
}

export function ReferralSystemAccountFromJSON(
  json: any,
): ReferralSystemAccount {
  return ReferralSystemAccountFromJSONTyped(json, false);
}

export function ReferralSystemAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReferralSystemAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    referralAccount: ReferralAccountFromJSON(json["referral_account"]),
    referrerAccount: ReferrerAccountFromJSON(json["referrer_account"]),
  };
}

export function ReferralSystemAccountToJSON(
  value?: ReferralSystemAccount | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    referral_account: ReferralAccountToJSON(value.referralAccount),
    referrer_account: ReferrerAccountToJSON(value.referrerAccount),
  };
}
