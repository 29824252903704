// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {number}
   * @memberof Country
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  flag: string;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  name: string;
}

/**
 * Check if a given object implements the Country interface.
 */
export function instanceOfCountry(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "flag" in value;
  isInstance = isInstance && "name" in value;

  return isInstance;
}

export function CountryFromJSON(json: any): Country {
  return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Country {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    flag: json["flag"],
    name: json["name"],
  };
}

export function CountryToJSON(value?: Country | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    flag: value.flag,
    name: value.name,
  };
}
