<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7933 0.287712C15.4872 0.783344 15.6479 1.74764 15.1523 2.44152L7.43238 13.2494C7.15015 13.6445 6.6985 13.8838 6.21307 13.8955C5.72765 13.9072 5.26502 13.6898 4.96415 13.3087L0.3322 7.44156C-0.196181 6.77228 -0.0819578 5.80138 0.587326 5.273C1.25661 4.74462 2.22751 4.85884 2.75589 5.52812L6.11425 9.78204L12.6395 0.64668C13.1351 -0.0472043 14.0994 -0.20792 14.7933 0.287712Z"
      fill="#E3E3E5"
    />
  </svg>
</template>
