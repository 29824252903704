// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SimpleCase
 */
export interface SimpleCase {
  /**
   *
   * @type {number}
   * @memberof SimpleCase
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof SimpleCase
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof SimpleCase
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SimpleCase
   */
  price: number;
}

/**
 * Check if a given object implements the SimpleCase interface.
 */
export function instanceOfSimpleCase(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "price" in value;

  return isInstance;
}

export function SimpleCaseFromJSON(json: any): SimpleCase {
  return SimpleCaseFromJSONTyped(json, false);
}

export function SimpleCaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SimpleCase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    price: json["price"],
  };
}

export function SimpleCaseToJSON(value?: SimpleCase | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    img: value.img,
    name: value.name,
    price: value.price,
  };
}
