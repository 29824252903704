// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `98171b` - Красный
 * * `9d177a` - Розовый
 * * `0f3580` - Синий
 * * `67099f` - Фиолетовый
 * * `126695` - Голубой
 * * `5c676b` - Серый
 * * `d09232` - Золотой
 * @export
 */
export const StandoffSubjectQualityEnum = {
  _98171b: "98171b",
  _9d177a: "9d177a",
  _0f3580: "0f3580",
  _67099f: "67099f",
  _126695: "126695",
  _5c676b: "5c676b",
  D09232: "d09232",
} as const;
export type StandoffSubjectQualityEnum =
  (typeof StandoffSubjectQualityEnum)[keyof typeof StandoffSubjectQualityEnum];

export function StandoffSubjectQualityEnumFromJSON(
  json: any,
): StandoffSubjectQualityEnum {
  return StandoffSubjectQualityEnumFromJSONTyped(json, false);
}

export function StandoffSubjectQualityEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StandoffSubjectQualityEnum {
  return json as StandoffSubjectQualityEnum;
}

export function StandoffSubjectQualityEnumToJSON(
  value?: StandoffSubjectQualityEnum | null,
): any {
  return value as any;
}
