// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Reward } from "./Reward";
import { RewardFromJSON, RewardFromJSONTyped, RewardToJSON } from "./Reward";
import type { UserRewardEvent } from "./UserRewardEvent";
import {
  UserRewardEventFromJSON,
  UserRewardEventFromJSONTyped,
  UserRewardEventToJSON,
} from "./UserRewardEvent";

/**
 *
 * @export
 * @interface UserReward
 */
export interface UserReward {
  /**
   *
   * @type {number}
   * @memberof UserReward
   */
  readonly id: number;
  /**
   *
   * @type {Array<Reward>}
   * @memberof UserReward
   */
  rewards: Array<Reward>;
  /**
   *
   * @type {string}
   * @memberof UserReward
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof UserReward
   */
  title: string;
  /**
   *
   * @type {UserRewardEvent}
   * @memberof UserReward
   */
  readonly event: UserRewardEvent | null;
}

/**
 * Check if a given object implements the UserReward interface.
 */
export function instanceOfUserReward(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "rewards" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "event" in value;

  return isInstance;
}

export function UserRewardFromJSON(json: any): UserReward {
  return UserRewardFromJSONTyped(json, false);
}

export function UserRewardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserReward {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    rewards: (json["rewards"] as Array<any>).map(RewardFromJSON),
    description: json["description"],
    title: json["title"],
    event: UserRewardEventFromJSON(json["event"]),
  };
}

export function UserRewardToJSON(value?: UserReward | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rewards: (value.rewards as Array<any>).map(RewardToJSON),
    description: value.description,
    title: value.title,
  };
}
