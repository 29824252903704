// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";
import type { UpgradeGameBid } from "./UpgradeGameBid";
import {
  UpgradeGameBidFromJSON,
  UpgradeGameBidFromJSONTyped,
  UpgradeGameBidToJSON,
} from "./UpgradeGameBid";
import type { UpgradeGamer } from "./UpgradeGamer";
import {
  UpgradeGamerFromJSON,
  UpgradeGamerFromJSONTyped,
  UpgradeGamerToJSON,
} from "./UpgradeGamer";

/**
 *
 * @export
 * @interface UpgradeGameTop
 */
export interface UpgradeGameTop {
  /**
   *
   * @type {UpgradeGameBid}
   * @memberof UpgradeGameTop
   */
  readonly bid: UpgradeGameBid;
  /**
   *
   * @type {Subject}
   * @memberof UpgradeGameTop
   */
  readonly upgrade: Subject;
  /**
   *
   * @type {UpgradeGamer}
   * @memberof UpgradeGameTop
   */
  gamer: UpgradeGamer;
  /**
   *
   * @type {number}
   * @memberof UpgradeGameTop
   */
  chance: number;
}

/**
 * Check if a given object implements the UpgradeGameTop interface.
 */
export function instanceOfUpgradeGameTop(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "bid" in value;
  isInstance = isInstance && "upgrade" in value;
  isInstance = isInstance && "gamer" in value;
  isInstance = isInstance && "chance" in value;

  return isInstance;
}

export function UpgradeGameTopFromJSON(json: any): UpgradeGameTop {
  return UpgradeGameTopFromJSONTyped(json, false);
}

export function UpgradeGameTopFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpgradeGameTop {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bid: UpgradeGameBidFromJSON(json["bid"]),
    upgrade: SubjectFromJSON(json["upgrade"]),
    gamer: UpgradeGamerFromJSON(json["gamer"]),
    chance: json["chance"],
  };
}

export function UpgradeGameTopToJSON(value?: UpgradeGameTop | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    gamer: UpgradeGamerToJSON(value.gamer),
    chance: value.chance,
  };
}
