// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ReferrerStatistics
 */
export interface ReferrerStatistics {
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  revenue: number;
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  referralsCount: number;
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  revenueChange: number;
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  referralsCountChange: number;
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  readonly revenueChangePercentage: number | null;
  /**
   *
   * @type {number}
   * @memberof ReferrerStatistics
   */
  readonly referralsCountChangePercentage: number | null;
}

/**
 * Check if a given object implements the ReferrerStatistics interface.
 */
export function instanceOfReferrerStatistics(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "revenue" in value;
  isInstance = isInstance && "referralsCount" in value;
  isInstance = isInstance && "revenueChange" in value;
  isInstance = isInstance && "referralsCountChange" in value;
  isInstance = isInstance && "revenueChangePercentage" in value;
  isInstance = isInstance && "referralsCountChangePercentage" in value;

  return isInstance;
}

export function ReferrerStatisticsFromJSON(json: any): ReferrerStatistics {
  return ReferrerStatisticsFromJSONTyped(json, false);
}

export function ReferrerStatisticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ReferrerStatistics {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    revenue: json["revenue"],
    referralsCount: json["referrals_count"],
    revenueChange: json["revenue_change"],
    referralsCountChange: json["referrals_count_change"],
    revenueChangePercentage: json["revenue_change_percentage"],
    referralsCountChangePercentage: json["referrals_count_change_percentage"],
  };
}

export function ReferrerStatisticsToJSON(
  value?: ReferrerStatistics | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    revenue: value.revenue,
    referrals_count: value.referralsCount,
    revenue_change: value.revenueChange,
    referrals_count_change: value.referralsCountChange,
  };
}
