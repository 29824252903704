// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PinMessageRequest
 */
export interface PinMessageRequest {
  /**
   *
   * @type {number}
   * @memberof PinMessageRequest
   */
  messageId: number;
}

/**
 * Check if a given object implements the PinMessageRequest interface.
 */
export function instanceOfPinMessageRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "messageId" in value;

  return isInstance;
}

export function PinMessageRequestFromJSON(json: any): PinMessageRequest {
  return PinMessageRequestFromJSONTyped(json, false);
}

export function PinMessageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PinMessageRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    messageId: json["message_id"],
  };
}

export function PinMessageRequestToJSON(value?: PinMessageRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message_id: value.messageId,
  };
}
