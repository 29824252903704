// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ContractSettings
 */
export interface ContractSettings {
  /**
   *
   * @type {number}
   * @memberof ContractSettings
   */
  minCoef: number;
  /**
   *
   * @type {number}
   * @memberof ContractSettings
   */
  maxCoef: number;
  /**
   *
   * @type {number}
   * @memberof ContractSettings
   */
  readonly maxSubjectPrice: number;
  /**
   *
   * @type {number}
   * @memberof ContractSettings
   */
  readonly minSubjectPrice: number;
}

/**
 * Check if a given object implements the ContractSettings interface.
 */
export function instanceOfContractSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "minCoef" in value;
  isInstance = isInstance && "maxCoef" in value;
  isInstance = isInstance && "maxSubjectPrice" in value;
  isInstance = isInstance && "minSubjectPrice" in value;

  return isInstance;
}

export function ContractSettingsFromJSON(json: any): ContractSettings {
  return ContractSettingsFromJSONTyped(json, false);
}

export function ContractSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContractSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    minCoef: json["min_coef"],
    maxCoef: json["max_coef"],
    maxSubjectPrice: json["max_subject_price"],
    minSubjectPrice: json["min_subject_price"],
  };
}

export function ContractSettingsToJSON(value?: ContractSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    min_coef: value.minCoef,
    max_coef: value.maxCoef,
  };
}
