// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CalcChance
 */
export interface CalcChance {
  /**
   *
   * @type {number}
   * @memberof CalcChance
   */
  chance: number;
}

/**
 * Check if a given object implements the CalcChance interface.
 */
export function instanceOfCalcChance(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "chance" in value;

  return isInstance;
}

export function CalcChanceFromJSON(json: any): CalcChance {
  return CalcChanceFromJSONTyped(json, false);
}

export function CalcChanceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CalcChance {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    chance: json["chance"],
  };
}

export function CalcChanceToJSON(value?: CalcChance | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    chance: value.chance,
  };
}
