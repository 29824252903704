// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SendPushNotificationRequest
 */
export interface SendPushNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof SendPushNotificationRequest
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof SendPushNotificationRequest
   */
  title: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SendPushNotificationRequest
   */
  userIds?: Array<string>;
}

/**
 * Check if a given object implements the SendPushNotificationRequest interface.
 */
export function instanceOfSendPushNotificationRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "body" in value;
  isInstance = isInstance && "title" in value;

  return isInstance;
}

export function SendPushNotificationRequestFromJSON(
  json: any,
): SendPushNotificationRequest {
  return SendPushNotificationRequestFromJSONTyped(json, false);
}

export function SendPushNotificationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendPushNotificationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    body: json["body"],
    title: json["title"],
    userIds: !exists(json, "user_ids") ? undefined : json["user_ids"],
  };
}

export function SendPushNotificationRequestToJSON(
  value?: SendPushNotificationRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    body: value.body,
    title: value.title,
    user_ids: value.userIds,
  };
}
