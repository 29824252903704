// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  TranslationsUpdate,
  TranslationsUpdateRequest,
} from "../models/index";
import {
  TranslationsUpdateFromJSON,
  TranslationsUpdateToJSON,
  TranslationsUpdateRequestFromJSON,
  TranslationsUpdateRequestToJSON,
} from "../models/index";

export interface TranslationsApiCreateRequest {
  translationsUpdateRequest: TranslationsUpdateRequest;
}

/**
 *
 */
export class TranslationsApi extends runtime.BaseAPI {
  /**
   */
  async createRaw(
    requestParameters: TranslationsApiCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TranslationsUpdate>> {
    if (requestParameters["translationsUpdateRequest"] == null) {
      throw new runtime.RequiredError(
        "translationsUpdateRequest",
        'Required parameter "translationsUpdateRequest" was null or undefined when calling create().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/translations/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TranslationsUpdateRequestToJSON(
          requestParameters["translationsUpdateRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TranslationsUpdateFromJSON(jsonValue),
    );
  }

  /**
   */
  async create(
    requestParameters: TranslationsApiCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TranslationsUpdate> {
    const response = await this.createRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
