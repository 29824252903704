// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TranslationKeyRequest
 */
export interface TranslationKeyRequest {
  /**
   *
   * @type {number}
   * @memberof TranslationKeyRequest
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TranslationKeyRequest
   */
  name: string;
}

/**
 * Check if a given object implements the TranslationKeyRequest interface.
 */
export function instanceOfTranslationKeyRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;

  return isInstance;
}

export function TranslationKeyRequestFromJSON(
  json: any,
): TranslationKeyRequest {
  return TranslationKeyRequestFromJSONTyped(json, false);
}

export function TranslationKeyRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TranslationKeyRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
  };
}

export function TranslationKeyRequestToJSON(
  value?: TranslationKeyRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
