// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { UserLevel } from "./UserLevel";
import {
  UserLevelFromJSON,
  UserLevelFromJSONTyped,
  UserLevelToJSON,
} from "./UserLevel";

/**
 *
 * @export
 * @interface BattlePassLevel
 */
export interface BattlePassLevel {
  /**
   *
   * @type {number}
   * @memberof BattlePassLevel
   */
  number: number | null;
  /**
   *
   * @type {string}
   * @memberof BattlePassLevel
   */
  rewardIcon: string;
  /**
   *
   * @type {number}
   * @memberof BattlePassLevel
   */
  cost: number;
  /**
   *
   * @type {string}
   * @memberof BattlePassLevel
   */
  rewardName: string;
  /**
   *
   * @type {number}
   * @memberof BattlePassLevel
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof BattlePassLevel
   */
  readonly rewardType: string;
  /**
   *
   * @type {string}
   * @memberof BattlePassLevel
   */
  rewardDescription: string;
  /**
   *
   * @type {string}
   * @memberof BattlePassLevel
   */
  rewardQuality: string;
  /**
   *
   * @type {UserLevel}
   * @memberof BattlePassLevel
   */
  userProgress: UserLevel | null;
}

/**
 * Check if a given object implements the BattlePassLevel interface.
 */
export function instanceOfBattlePassLevel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "number" in value;
  isInstance = isInstance && "rewardIcon" in value;
  isInstance = isInstance && "cost" in value;
  isInstance = isInstance && "rewardName" in value;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "rewardType" in value;
  isInstance = isInstance && "rewardDescription" in value;
  isInstance = isInstance && "rewardQuality" in value;
  isInstance = isInstance && "userProgress" in value;

  return isInstance;
}

export function BattlePassLevelFromJSON(json: any): BattlePassLevel {
  return BattlePassLevelFromJSONTyped(json, false);
}

export function BattlePassLevelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BattlePassLevel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    number: json["number"],
    rewardIcon: json["reward_icon"],
    cost: json["cost"],
    rewardName: json["reward_name"],
    id: json["id"],
    rewardType: json["reward_type"],
    rewardDescription: json["reward_description"],
    rewardQuality: json["reward_quality"],
    userProgress: UserLevelFromJSON(json["user_progress"]),
  };
}

export function BattlePassLevelToJSON(value?: BattlePassLevel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    number: value.number,
    reward_icon: value.rewardIcon,
    cost: value.cost,
    reward_name: value.rewardName,
    reward_description: value.rewardDescription,
    reward_quality: value.rewardQuality,
    user_progress: UserLevelToJSON(value.userProgress),
  };
}
