// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpgradeGamer
 */
export interface UpgradeGamer {
  /**
   *
   * @type {string}
   * @memberof UpgradeGamer
   */
  nickname: string;
  /**
   *
   * @type {string}
   * @memberof UpgradeGamer
   */
  avatarImg: string | null;
}

/**
 * Check if a given object implements the UpgradeGamer interface.
 */
export function instanceOfUpgradeGamer(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "avatarImg" in value;

  return isInstance;
}

export function UpgradeGamerFromJSON(json: any): UpgradeGamer {
  return UpgradeGamerFromJSONTyped(json, false);
}

export function UpgradeGamerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpgradeGamer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    nickname: json["nickname"],
    avatarImg: json["avatar_img"],
  };
}

export function UpgradeGamerToJSON(value?: UpgradeGamer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    nickname: value.nickname,
    avatar_img: value.avatarImg,
  };
}
