// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CurrentLevel
 */
export interface CurrentLevel {
  /**
   *
   * @type {number}
   * @memberof CurrentLevel
   */
  number: number;
  /**
   *
   * @type {number}
   * @memberof CurrentLevel
   */
  cost: number;
  /**
   *
   * @type {number}
   * @memberof CurrentLevel
   */
  doubloons: number;
}

/**
 * Check if a given object implements the CurrentLevel interface.
 */
export function instanceOfCurrentLevel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "number" in value;
  isInstance = isInstance && "cost" in value;
  isInstance = isInstance && "doubloons" in value;

  return isInstance;
}

export function CurrentLevelFromJSON(json: any): CurrentLevel {
  return CurrentLevelFromJSONTyped(json, false);
}

export function CurrentLevelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CurrentLevel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    number: json["number"],
    cost: json["cost"],
    doubloons: json["doubloons"],
  };
}

export function CurrentLevelToJSON(value?: CurrentLevel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    number: value.number,
    cost: value.cost,
    doubloons: value.doubloons,
  };
}
