// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { LadderGameStatusEnum } from "./LadderGameStatusEnum";
import {
  LadderGameStatusEnumFromJSON,
  LadderGameStatusEnumFromJSONTyped,
  LadderGameStatusEnumToJSON,
} from "./LadderGameStatusEnum";

/**
 *
 * @export
 * @interface MinerGame
 */
export interface MinerGame {
  /**
   *
   * @type {string}
   * @memberof MinerGame
   */
  id: string;
  /**
   *
   * @type {LadderGameStatusEnum}
   * @memberof MinerGame
   */
  status: LadderGameStatusEnum;
  /**
   *
   * @type {number}
   * @memberof MinerGame
   */
  startRate: number;
  /**
   *
   * @type {number}
   * @memberof MinerGame
   */
  numberMines: number;
  /**
   *
   * @type {Date}
   * @memberof MinerGame
   */
  started: Date;
  /**
   *
   * @type {number}
   * @memberof MinerGame
   */
  currentStep: number;
  /**
   *
   * @type {number}
   * @memberof MinerGame
   */
  currentCoefficient: number;
  /**
   *
   * @type {number}
   * @memberof MinerGame
   */
  currentRate: number;
  /**
   *
   * @type {Array<number>}
   * @memberof MinerGame
   */
  minesLocations: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof MinerGame
   */
  openedLocations: Array<number>;
  /**
   *
   * @type {string}
   * @memberof MinerGame
   */
  nickname: string;
  /**
   *
   * @type {string}
   * @memberof MinerGame
   */
  selectedNicknameDecoration: string;
}

/**
 * Check if a given object implements the MinerGame interface.
 */
export function instanceOfMinerGame(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "startRate" in value;
  isInstance = isInstance && "numberMines" in value;
  isInstance = isInstance && "started" in value;
  isInstance = isInstance && "currentStep" in value;
  isInstance = isInstance && "currentCoefficient" in value;
  isInstance = isInstance && "currentRate" in value;
  isInstance = isInstance && "minesLocations" in value;
  isInstance = isInstance && "openedLocations" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "selectedNicknameDecoration" in value;

  return isInstance;
}

export function MinerGameFromJSON(json: any): MinerGame {
  return MinerGameFromJSONTyped(json, false);
}

export function MinerGameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MinerGame {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    status: LadderGameStatusEnumFromJSON(json["status"]),
    startRate: json["start_rate"],
    numberMines: json["number_mines"],
    started: new Date(json["started"]),
    currentStep: json["current_step"],
    currentCoefficient: json["current_coefficient"],
    currentRate: json["current_rate"],
    minesLocations: json["mines_locations"],
    openedLocations: json["opened_locations"],
    nickname: json["nickname"],
    selectedNicknameDecoration: json["selected_nickname_decoration"],
  };
}

export function MinerGameToJSON(value?: MinerGame | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: LadderGameStatusEnumToJSON(value.status),
    start_rate: value.startRate,
    number_mines: value.numberMines,
    started: value.started.toISOString(),
    current_step: value.currentStep,
    current_coefficient: value.currentCoefficient,
    current_rate: value.currentRate,
    mines_locations: value.minesLocations,
    opened_locations: value.openedLocations,
    nickname: value.nickname,
    selected_nickname_decoration: value.selectedNicknameDecoration,
  };
}
