// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PaymentMethodShort } from "./PaymentMethodShort";
import {
  PaymentMethodShortFromJSON,
  PaymentMethodShortFromJSONTyped,
  PaymentMethodShortToJSON,
} from "./PaymentMethodShort";
import type { TopUpBalanceInvoiceStatusEnum } from "./TopUpBalanceInvoiceStatusEnum";
import {
  TopUpBalanceInvoiceStatusEnumFromJSON,
  TopUpBalanceInvoiceStatusEnumFromJSONTyped,
  TopUpBalanceInvoiceStatusEnumToJSON,
} from "./TopUpBalanceInvoiceStatusEnum";

/**
 *
 * @export
 * @interface InvoiceStatus
 */
export interface InvoiceStatus {
  /**
   *
   * @type {string}
   * @memberof InvoiceStatus
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceStatus
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceStatus
   */
  amountBullcoins: number | null;
  /**
   *
   * @type {number}
   * @memberof InvoiceStatus
   */
  amountGold: number;
  /**
   *
   * @type {TopUpBalanceInvoiceStatusEnum}
   * @memberof InvoiceStatus
   */
  status: TopUpBalanceInvoiceStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof InvoiceStatus
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof InvoiceStatus
   */
  created: Date;
  /**
   *
   * @type {PaymentMethodShort}
   * @memberof InvoiceStatus
   */
  paymentMethod: PaymentMethodShort;
}

/**
 * Check if a given object implements the InvoiceStatus interface.
 */
export function instanceOfInvoiceStatus(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "amountBullcoins" in value;
  isInstance = isInstance && "amountGold" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "paymentMethod" in value;

  return isInstance;
}

export function InvoiceStatusFromJSON(json: any): InvoiceStatus {
  return InvoiceStatusFromJSONTyped(json, false);
}

export function InvoiceStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InvoiceStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    amount: json["amount"],
    amountBullcoins: json["amount_bullcoins"],
    amountGold: json["amount_gold"],
    status: TopUpBalanceInvoiceStatusEnumFromJSON(json["status"]),
    createdAt: new Date(json["created_at"]),
    created: new Date(json["created"]),
    paymentMethod: PaymentMethodShortFromJSON(json["payment_method"]),
  };
}

export function InvoiceStatusToJSON(value?: InvoiceStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    amount: value.amount,
    amount_bullcoins: value.amountBullcoins,
    amount_gold: value.amountGold,
    status: TopUpBalanceInvoiceStatusEnumToJSON(value.status),
    created_at: value.createdAt.toISOString(),
    created: value.created.toISOString(),
    payment_method: PaymentMethodShortToJSON(value.paymentMethod),
  };
}
