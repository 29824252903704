// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { LanguageEnum } from "./LanguageEnum";
import {
  LanguageEnumFromJSON,
  LanguageEnumFromJSONTyped,
  LanguageEnumToJSON,
} from "./LanguageEnum";

/**
 *
 * @export
 * @interface LanguageRequest
 */
export interface LanguageRequest {
  /**
   *
   * @type {LanguageEnum}
   * @memberof LanguageRequest
   */
  language: LanguageEnum;
}

/**
 * Check if a given object implements the LanguageRequest interface.
 */
export function instanceOfLanguageRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "language" in value;

  return isInstance;
}

export function LanguageRequestFromJSON(json: any): LanguageRequest {
  return LanguageRequestFromJSONTyped(json, false);
}

export function LanguageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LanguageRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    language: LanguageEnumFromJSON(json["language"]),
  };
}

export function LanguageRequestToJSON(value?: LanguageRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    language: LanguageEnumToJSON(value.language),
  };
}
