// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
  /**
   *
   * @type {string}
   * @memberof UserCreateRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserCreateRequest
   */
  password?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserCreateRequest
   */
  nickname?: string | null;
}

/**
 * Check if a given object implements the UserCreateRequest interface.
 */
export function instanceOfUserCreateRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
  return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    password: !exists(json, "password") ? undefined : json["password"],
    nickname: !exists(json, "nickname") ? undefined : json["nickname"],
  };
}

export function UserCreateRequestToJSON(value?: UserCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    password: value.password,
    nickname: value.nickname,
  };
}
