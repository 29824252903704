// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { FullTranslationRequest } from "./FullTranslationRequest";
import {
  FullTranslationRequestFromJSON,
  FullTranslationRequestFromJSONTyped,
  FullTranslationRequestToJSON,
} from "./FullTranslationRequest";
import type { TranslationProjectRequest } from "./TranslationProjectRequest";
import {
  TranslationProjectRequestFromJSON,
  TranslationProjectRequestFromJSONTyped,
  TranslationProjectRequestToJSON,
} from "./TranslationProjectRequest";

/**
 *
 * @export
 * @interface TranslationsUpdateRequest
 */
export interface TranslationsUpdateRequest {
  /**
   *
   * @type {Array<FullTranslationRequest>}
   * @memberof TranslationsUpdateRequest
   */
  translations: Array<FullTranslationRequest>;
  /**
   *
   * @type {TranslationProjectRequest}
   * @memberof TranslationsUpdateRequest
   */
  project: TranslationProjectRequest;
}

/**
 * Check if a given object implements the TranslationsUpdateRequest interface.
 */
export function instanceOfTranslationsUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "translations" in value;
  isInstance = isInstance && "project" in value;

  return isInstance;
}

export function TranslationsUpdateRequestFromJSON(
  json: any,
): TranslationsUpdateRequest {
  return TranslationsUpdateRequestFromJSONTyped(json, false);
}

export function TranslationsUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TranslationsUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    translations: (json["translations"] as Array<any>).map(
      FullTranslationRequestFromJSON,
    ),
    project: TranslationProjectRequestFromJSON(json["project"]),
  };
}

export function TranslationsUpdateRequestToJSON(
  value?: TranslationsUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    translations: (value.translations as Array<any>).map(
      FullTranslationRequestToJSON,
    ),
    project: TranslationProjectRequestToJSON(value.project),
  };
}
