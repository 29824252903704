// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SubjectWithChance } from "./SubjectWithChance";
import {
  SubjectWithChanceFromJSON,
  SubjectWithChanceFromJSONTyped,
  SubjectWithChanceToJSON,
} from "./SubjectWithChance";

/**
 *
 * @export
 * @interface SubjectWithChanceRewardInfo
 */
export interface SubjectWithChanceRewardInfo {
  /**
   *
   * @type {string}
   * @memberof SubjectWithChanceRewardInfo
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof SubjectWithChanceRewardInfo
   */
  readonly isAvailable: boolean;
  /**
   *
   * @type {Array<SubjectWithChance>}
   * @memberof SubjectWithChanceRewardInfo
   */
  readonly subjects: Array<SubjectWithChance>;
}

/**
 * Check if a given object implements the SubjectWithChanceRewardInfo interface.
 */
export function instanceOfSubjectWithChanceRewardInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "isAvailable" in value;
  isInstance = isInstance && "subjects" in value;

  return isInstance;
}

export function SubjectWithChanceRewardInfoFromJSON(
  json: any,
): SubjectWithChanceRewardInfo {
  return SubjectWithChanceRewardInfoFromJSONTyped(json, false);
}

export function SubjectWithChanceRewardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SubjectWithChanceRewardInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    isAvailable: json["is_available"],
    subjects: (json["subjects"] as Array<any>).map(SubjectWithChanceFromJSON),
  };
}

export function SubjectWithChanceRewardInfoToJSON(
  value?: SubjectWithChanceRewardInfo | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
