// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BossBattleTopUsersResponse } from "./BossBattleTopUsersResponse";
import {
  BossBattleTopUsersResponseFromJSON,
  BossBattleTopUsersResponseFromJSONTyped,
  BossBattleTopUsersResponseToJSON,
} from "./BossBattleTopUsersResponse";
import type { TopUser } from "./TopUser";
import {
  TopUserFromJSON,
  TopUserFromJSONTyped,
  TopUserToJSON,
} from "./TopUser";

/**
 *
 * @export
 * @interface BossBattleTopResponse
 */
export interface BossBattleTopResponse {
  /**
   *
   * @type {TopUser}
   * @memberof BossBattleTopResponse
   */
  currentUser: TopUser | null;
  /**
   *
   * @type {BossBattleTopUsersResponse}
   * @memberof BossBattleTopResponse
   */
  topUsers: BossBattleTopUsersResponse;
}

/**
 * Check if a given object implements the BossBattleTopResponse interface.
 */
export function instanceOfBossBattleTopResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "currentUser" in value;
  isInstance = isInstance && "topUsers" in value;

  return isInstance;
}

export function BossBattleTopResponseFromJSON(
  json: any,
): BossBattleTopResponse {
  return BossBattleTopResponseFromJSONTyped(json, false);
}

export function BossBattleTopResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BossBattleTopResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentUser: TopUserFromJSON(json["current_user"]),
    topUsers: BossBattleTopUsersResponseFromJSON(json["top_users"]),
  };
}

export function BossBattleTopResponseToJSON(
  value?: BossBattleTopResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_user: TopUserToJSON(value.currentUser),
    top_users: BossBattleTopUsersResponseToJSON(value.topUsers),
  };
}
