// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  Case,
  CaseList,
  CaseListsByCollections,
  Inventory,
  OpenCaseBatchRequest,
  OpenCaseBatchResponse,
  SellInventoryBatchRequest,
  SellInventoryResponse,
  TelegramCase,
  TelegramCypherTapCase,
  VkCase,
} from "../models/index";
import {
  CaseFromJSON,
  CaseToJSON,
  CaseListFromJSON,
  CaseListToJSON,
  CaseListsByCollectionsFromJSON,
  CaseListsByCollectionsToJSON,
  InventoryFromJSON,
  InventoryToJSON,
  OpenCaseBatchRequestFromJSON,
  OpenCaseBatchRequestToJSON,
  OpenCaseBatchResponseFromJSON,
  OpenCaseBatchResponseToJSON,
  SellInventoryBatchRequestFromJSON,
  SellInventoryBatchRequestToJSON,
  SellInventoryResponseFromJSON,
  SellInventoryResponseToJSON,
  TelegramCaseFromJSON,
  TelegramCaseToJSON,
  TelegramCypherTapCaseFromJSON,
  TelegramCypherTapCaseToJSON,
  VkCaseFromJSON,
  VkCaseToJSON,
} from "../models/index";

export interface CasesApiInventorySellBatchCreateRequest {
  sellInventoryBatchRequest: SellInventoryBatchRequest;
}

export interface CasesApiInventorySellCreateRequest {
  id: number;
}

export interface CasesApiOpenBatchRequest {
  openCaseBatchRequest: OpenCaseBatchRequest;
}

export interface CasesApiRecommendedCasesListRequest {
  limit?: number;
}

export interface CasesApiRetrieveRequest {
  id: number;
}

export interface CasesApiTelegramCypherTapRetrieveRequest {
  isDetail?: boolean;
}

export interface CasesApiTelegramRetrieveRequest {
  isDetail?: boolean;
}

export interface CasesApiVkRetrieveRequest {
  isDetail?: boolean;
}

/**
 *
 */
export class CasesApi extends runtime.BaseAPI {
  /**
   */
  async byCollectionsListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<CaseListsByCollections>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/by-collections/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CaseListsByCollectionsFromJSON),
    );
  }

  /**
   */
  async byCollectionsList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<CaseListsByCollections>> {
    const response = await this.byCollectionsListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async inventoryListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Inventory>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/inventory/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(InventoryFromJSON),
    );
  }

  /**
   */
  async inventoryList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Inventory>> {
    const response = await this.inventoryListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async inventorySellAllCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/inventory/sell-all/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async inventorySellAllCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.inventorySellAllCreateRaw(initOverrides);
  }

  /**
   */
  async inventorySellBatchCreateRaw(
    requestParameters: CasesApiInventorySellBatchCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["sellInventoryBatchRequest"] == null) {
      throw new runtime.RequiredError(
        "sellInventoryBatchRequest",
        'Required parameter "sellInventoryBatchRequest" was null or undefined when calling inventorySellBatchCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/inventory/sell-batch/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SellInventoryBatchRequestToJSON(
          requestParameters["sellInventoryBatchRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async inventorySellBatchCreate(
    requestParameters: CasesApiInventorySellBatchCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.inventorySellBatchCreateRaw(requestParameters, initOverrides);
  }

  /**
   */
  async inventorySellCreateRaw(
    requestParameters: CasesApiInventorySellCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SellInventoryResponse>> {
    if (requestParameters["id"] == null) {
      throw new runtime.RequiredError(
        "id",
        'Required parameter "id" was null or undefined when calling inventorySellCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/inventory/{id}/sell/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters["id"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SellInventoryResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async inventorySellCreate(
    requestParameters: CasesApiInventorySellCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SellInventoryResponse> {
    const response = await this.inventorySellCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async openBatchRaw(
    requestParameters: CasesApiOpenBatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OpenCaseBatchResponse>> {
    if (requestParameters["openCaseBatchRequest"] == null) {
      throw new runtime.RequiredError(
        "openCaseBatchRequest",
        'Required parameter "openCaseBatchRequest" was null or undefined when calling openBatch().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/open/batch/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: OpenCaseBatchRequestToJSON(
          requestParameters["openCaseBatchRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpenCaseBatchResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async openBatch(
    requestParameters: CasesApiOpenBatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OpenCaseBatchResponse> {
    const response = await this.openBatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async recommendedCasesListRaw(
    requestParameters: CasesApiRecommendedCasesListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<CaseList>>> {
    const queryParameters: any = {};

    if (requestParameters["limit"] != null) {
      queryParameters["limit"] = requestParameters["limit"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/recommended_cases/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CaseListFromJSON),
    );
  }

  /**
   */
  async recommendedCasesList(
    requestParameters: CasesApiRecommendedCasesListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<CaseList>> {
    const response = await this.recommendedCasesListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async retrieveRaw(
    requestParameters: CasesApiRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Case>> {
    if (requestParameters["id"] == null) {
      throw new runtime.RequiredError(
        "id",
        'Required parameter "id" was null or undefined when calling retrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/{id}/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters["id"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CaseFromJSON(jsonValue),
    );
  }

  /**
   */
  async retrieve(
    requestParameters: CasesApiRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Case> {
    const response = await this.retrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async telegramCypherTapOpenRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Inventory>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/telegram_cypher_tap/open/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InventoryFromJSON(jsonValue),
    );
  }

  /**
   */
  async telegramCypherTapOpen(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Inventory> {
    const response = await this.telegramCypherTapOpenRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async telegramCypherTapRetrieveRaw(
    requestParameters: CasesApiTelegramCypherTapRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TelegramCypherTapCase>> {
    const queryParameters: any = {};

    if (requestParameters["isDetail"] != null) {
      queryParameters["is_detail"] = requestParameters["isDetail"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/telegram_cypher_tap/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TelegramCypherTapCaseFromJSON(jsonValue),
    );
  }

  /**
   */
  async telegramCypherTapRetrieve(
    requestParameters: CasesApiTelegramCypherTapRetrieveRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TelegramCypherTapCase> {
    const response = await this.telegramCypherTapRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async telegramOpenRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Inventory>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/telegram/open/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InventoryFromJSON(jsonValue),
    );
  }

  /**
   */
  async telegramOpen(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Inventory> {
    const response = await this.telegramOpenRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async telegramRetrieveRaw(
    requestParameters: CasesApiTelegramRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TelegramCase>> {
    const queryParameters: any = {};

    if (requestParameters["isDetail"] != null) {
      queryParameters["is_detail"] = requestParameters["isDetail"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/telegram/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TelegramCaseFromJSON(jsonValue),
    );
  }

  /**
   */
  async telegramRetrieve(
    requestParameters: CasesApiTelegramRetrieveRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TelegramCase> {
    const response = await this.telegramRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async vkOpenRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Inventory>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/vk/open/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InventoryFromJSON(jsonValue),
    );
  }

  /**
   */
  async vkOpen(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Inventory> {
    const response = await this.vkOpenRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async vkRetrieveRaw(
    requestParameters: CasesApiVkRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<VkCase>> {
    const queryParameters: any = {};

    if (requestParameters["isDetail"] != null) {
      queryParameters["is_detail"] = requestParameters["isDetail"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/cases/vk/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      VkCaseFromJSON(jsonValue),
    );
  }

  /**
   */
  async vkRetrieve(
    requestParameters: CasesApiVkRetrieveRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<VkCase> {
    const response = await this.vkRetrieveRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
