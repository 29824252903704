// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CalculatePUBGPacksRequest
 */
export interface CalculatePUBGPacksRequest {
  /**
   *
   * @type {number}
   * @memberof CalculatePUBGPacksRequest
   */
  amountBullcoins: number;
}

/**
 * Check if a given object implements the CalculatePUBGPacksRequest interface.
 */
export function instanceOfCalculatePUBGPacksRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountBullcoins" in value;

  return isInstance;
}

export function CalculatePUBGPacksRequestFromJSON(
  json: any,
): CalculatePUBGPacksRequest {
  return CalculatePUBGPacksRequestFromJSONTyped(json, false);
}

export function CalculatePUBGPacksRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CalculatePUBGPacksRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountBullcoins: json["amount_bullcoins"],
  };
}

export function CalculatePUBGPacksRequestToJSON(
  value?: CalculatePUBGPacksRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_bullcoins: value.amountBullcoins,
  };
}
