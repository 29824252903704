// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { AvatarBorder, DefaultAvatar } from "../models/index";
import {
  AvatarBorderFromJSON,
  AvatarBorderToJSON,
  DefaultAvatarFromJSON,
  DefaultAvatarToJSON,
} from "../models/index";

export interface AvatarsApiBordersListRequest {
  page?: number;
}

/**
 *
 */
export class AvatarsApi extends runtime.BaseAPI {
  /**
   */
  async bordersListRaw(
    requestParameters: AvatarsApiBordersListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AvatarBorder>>> {
    const queryParameters: any = {};

    if (requestParameters["page"] != null) {
      queryParameters["page"] = requestParameters["page"];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/avatars/borders/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(AvatarBorderFromJSON),
    );
  }

  /**
   */
  async bordersList(
    requestParameters: AvatarsApiBordersListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AvatarBorder>> {
    const response = await this.bordersListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async listRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<DefaultAvatar>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/avatars/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(DefaultAvatarFromJSON),
    );
  }

  /**
   */
  async list(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<DefaultAvatar>> {
    const response = await this.listRaw(initOverrides);
    return await response.value();
  }
}
