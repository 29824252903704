// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `amount` - Amount
 * * `email` - Email
 * * `phone` - Phone
 * * `national_phone` - National Phone
 * * `code` - Code
 * @export
 */
export const RequiredFieldsEnum = {
  Amount: "amount",
  Email: "email",
  Phone: "phone",
  NationalPhone: "national_phone",
  Code: "code",
} as const;
export type RequiredFieldsEnum =
  (typeof RequiredFieldsEnum)[keyof typeof RequiredFieldsEnum];

export function RequiredFieldsEnumFromJSON(json: any): RequiredFieldsEnum {
  return RequiredFieldsEnumFromJSONTyped(json, false);
}

export function RequiredFieldsEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RequiredFieldsEnum {
  return json as RequiredFieldsEnum;
}

export function RequiredFieldsEnumToJSON(
  value?: RequiredFieldsEnum | null,
): any {
  return value as any;
}
