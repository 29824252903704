// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PaymentMethodRate
 */
export interface PaymentMethodRate {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodRate
   */
  currency: string;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodRate
   */
  methodRate: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethodRate
   */
  currencyRate: number;
}

/**
 * Check if a given object implements the PaymentMethodRate interface.
 */
export function instanceOfPaymentMethodRate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "methodRate" in value;
  isInstance = isInstance && "currencyRate" in value;

  return isInstance;
}

export function PaymentMethodRateFromJSON(json: any): PaymentMethodRate {
  return PaymentMethodRateFromJSONTyped(json, false);
}

export function PaymentMethodRateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentMethodRate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currency: json["currency"],
    methodRate: json["method_rate"],
    currencyRate: json["currency_rate"],
  };
}

export function PaymentMethodRateToJSON(value?: PaymentMethodRate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    currency: value.currency,
    method_rate: value.methodRate,
    currency_rate: value.currencyRate,
  };
}
