// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `won` - won
 * * `lost` - lost
 * @export
 */
export const NextFloorResponseStatusEnum = {
  Won: "won",
  Lost: "lost",
} as const;
export type NextFloorResponseStatusEnum =
  (typeof NextFloorResponseStatusEnum)[keyof typeof NextFloorResponseStatusEnum];

export function NextFloorResponseStatusEnumFromJSON(
  json: any,
): NextFloorResponseStatusEnum {
  return NextFloorResponseStatusEnumFromJSONTyped(json, false);
}

export function NextFloorResponseStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NextFloorResponseStatusEnum {
  return json as NextFloorResponseStatusEnum;
}

export function NextFloorResponseStatusEnumToJSON(
  value?: NextFloorResponseStatusEnum | null,
): any {
  return value as any;
}
