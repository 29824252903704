// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `84868c` - Серый
 * * `56bf8f` - Зелёный
 * * `46ace3` - Синий
 * * `c67ae5` - Фиолетовый
 * * `e9ae3c` - Золотой
 * @export
 */
export const GenshinSubjectQualityColorEnum = {
  _84868c: "84868c",
  _56bf8f: "56bf8f",
  _46ace3: "46ace3",
  C67ae5: "c67ae5",
  E9ae3c: "e9ae3c",
} as const;
export type GenshinSubjectQualityColorEnum =
  (typeof GenshinSubjectQualityColorEnum)[keyof typeof GenshinSubjectQualityColorEnum];

export function GenshinSubjectQualityColorEnumFromJSON(
  json: any,
): GenshinSubjectQualityColorEnum {
  return GenshinSubjectQualityColorEnumFromJSONTyped(json, false);
}

export function GenshinSubjectQualityColorEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenshinSubjectQualityColorEnum {
  return json as GenshinSubjectQualityColorEnum;
}

export function GenshinSubjectQualityColorEnumToJSON(
  value?: GenshinSubjectQualityColorEnum | null,
): any {
  return value as any;
}
