// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PromoCodeTypeEnum } from "./PromoCodeTypeEnum";
import {
  PromoCodeTypeEnumFromJSON,
  PromoCodeTypeEnumFromJSONTyped,
  PromoCodeTypeEnumToJSON,
} from "./PromoCodeTypeEnum";

/**
 *
 * @export
 * @interface PromoCode
 */
export interface PromoCode {
  /**
   *
   * @type {string}
   * @memberof PromoCode
   */
  code: string;
  /**
   *
   * @type {PromoCodeTypeEnum}
   * @memberof PromoCode
   */
  type: PromoCodeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PromoCode
   */
  percent: string;
  /**
   *
   * @type {number}
   * @memberof PromoCode
   */
  bonusGold: number;
  /**
   *
   * @type {number}
   * @memberof PromoCode
   */
  subject: number | null;
  /**
   *
   * @type {number}
   * @memberof PromoCode
   */
  _case: number | null;
  /**
   *
   * @type {number}
   * @memberof PromoCode
   */
  tablets: number | null;
  /**
   *
   * @type {number}
   * @memberof PromoCode
   */
  attackPoints: number | null;
}

/**
 * Check if a given object implements the PromoCode interface.
 */
export function instanceOfPromoCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "code" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "percent" in value;
  isInstance = isInstance && "bonusGold" in value;
  isInstance = isInstance && "subject" in value;
  isInstance = isInstance && "_case" in value;
  isInstance = isInstance && "tablets" in value;
  isInstance = isInstance && "attackPoints" in value;

  return isInstance;
}

export function PromoCodeFromJSON(json: any): PromoCode {
  return PromoCodeFromJSONTyped(json, false);
}

export function PromoCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PromoCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    type: PromoCodeTypeEnumFromJSON(json["type"]),
    percent: json["percent"],
    bonusGold: json["bonus_gold"],
    subject: json["subject"],
    _case: json["case"],
    tablets: json["tablets"],
    attackPoints: json["attack_points"],
  };
}

export function PromoCodeToJSON(value?: PromoCode | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    type: PromoCodeTypeEnumToJSON(value.type),
    percent: value.percent,
    bonus_gold: value.bonusGold,
    subject: value.subject,
    case: value._case,
    tablets: value.tablets,
    attack_points: value.attackPoints,
  };
}
