// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArtifactInventory,
  BattlePassInfo,
  DecorationRewardInfo,
  MomentRewardInfo,
  RewardInfo,
  SubjectWithChanceRewardInfo,
  TakeRewardRequest,
} from "../models/index";
import {
  ArtifactInventoryFromJSON,
  ArtifactInventoryToJSON,
  BattlePassInfoFromJSON,
  BattlePassInfoToJSON,
  DecorationRewardInfoFromJSON,
  DecorationRewardInfoToJSON,
  MomentRewardInfoFromJSON,
  MomentRewardInfoToJSON,
  RewardInfoFromJSON,
  RewardInfoToJSON,
  SubjectWithChanceRewardInfoFromJSON,
  SubjectWithChanceRewardInfoToJSON,
  TakeRewardRequestFromJSON,
  TakeRewardRequestToJSON,
} from "../models/index";

export interface BattlePassApiPassDecorationsRewardInfoRetrieveRequest {
  levelId: number;
}

export interface BattlePassApiPassMomentRewardInfoRetrieveRequest {
  levelId: number;
}

export interface BattlePassApiPassSubjectChancesRewardInfoRetrieveRequest {
  levelId: number;
}

export interface BattlePassApiPassTakeRewardCreateRequest {
  takeRewardRequest: TakeRewardRequest;
}

export interface BattlePassApiPassUserRewardInfoRetrieveRequest {
  levelId: number;
}

/**
 *
 */
export class BattlePassApi extends runtime.BaseAPI {
  /**
   */
  async passDecorationsRewardInfoRetrieveRaw(
    requestParameters: BattlePassApiPassDecorationsRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DecorationRewardInfo>> {
    if (requestParameters["levelId"] == null) {
      throw new runtime.RequiredError(
        "levelId",
        'Required parameter "levelId" was null or undefined when calling passDecorationsRewardInfoRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/decorations-reward-info/{level_id}/`.replace(
          `{${"level_id"}}`,
          encodeURIComponent(String(requestParameters["levelId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DecorationRewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passDecorationsRewardInfoRetrieve(
    requestParameters: BattlePassApiPassDecorationsRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DecorationRewardInfo> {
    const response = await this.passDecorationsRewardInfoRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async passMomentRewardInfoRetrieveRaw(
    requestParameters: BattlePassApiPassMomentRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MomentRewardInfo>> {
    if (requestParameters["levelId"] == null) {
      throw new runtime.RequiredError(
        "levelId",
        'Required parameter "levelId" was null or undefined when calling passMomentRewardInfoRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/moment-reward-info/{level_id}/`.replace(
          `{${"level_id"}}`,
          encodeURIComponent(String(requestParameters["levelId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MomentRewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passMomentRewardInfoRetrieve(
    requestParameters: BattlePassApiPassMomentRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MomentRewardInfo> {
    const response = await this.passMomentRewardInfoRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async passProgressRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BattlePassInfo>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/progress/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BattlePassInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passProgressRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<BattlePassInfo> {
    const response = await this.passProgressRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * @deprecated
   */
  async passSecretSubjectsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ArtifactInventory>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/secret-subjects/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ArtifactInventoryFromJSON(jsonValue),
    );
  }

  /**
   * @deprecated
   */
  async passSecretSubjectsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ArtifactInventory> {
    const response = await this.passSecretSubjectsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async passSubjectChancesRewardInfoRetrieveRaw(
    requestParameters: BattlePassApiPassSubjectChancesRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SubjectWithChanceRewardInfo>> {
    if (requestParameters["levelId"] == null) {
      throw new runtime.RequiredError(
        "levelId",
        'Required parameter "levelId" was null or undefined when calling passSubjectChancesRewardInfoRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/subject-chances-reward-info/{level_id}/`.replace(
          `{${"level_id"}}`,
          encodeURIComponent(String(requestParameters["levelId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SubjectWithChanceRewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passSubjectChancesRewardInfoRetrieve(
    requestParameters: BattlePassApiPassSubjectChancesRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SubjectWithChanceRewardInfo> {
    const response = await this.passSubjectChancesRewardInfoRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async passTakeRewardCreateRaw(
    requestParameters: BattlePassApiPassTakeRewardCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RewardInfo>> {
    if (requestParameters["takeRewardRequest"] == null) {
      throw new runtime.RequiredError(
        "takeRewardRequest",
        'Required parameter "takeRewardRequest" was null or undefined when calling passTakeRewardCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/take-reward/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TakeRewardRequestToJSON(requestParameters["takeRewardRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passTakeRewardCreate(
    requestParameters: BattlePassApiPassTakeRewardCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RewardInfo> {
    const response = await this.passTakeRewardCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async passUserRewardInfoRetrieveRaw(
    requestParameters: BattlePassApiPassUserRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RewardInfo>> {
    if (requestParameters["levelId"] == null) {
      throw new runtime.RequiredError(
        "levelId",
        'Required parameter "levelId" was null or undefined when calling passUserRewardInfoRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/battle-pass/user-reward-info/{level_id}/`.replace(
          `{${"level_id"}}`,
          encodeURIComponent(String(requestParameters["levelId"])),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RewardInfoFromJSON(jsonValue),
    );
  }

  /**
   */
  async passUserRewardInfoRetrieve(
    requestParameters: BattlePassApiPassUserRewardInfoRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RewardInfo> {
    const response = await this.passUserRewardInfoRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
