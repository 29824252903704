// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  text: string;
  /**
   *
   * @type {Date}
   * @memberof Notification
   */
  sentAt: Date;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  isViewed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  readonly read: boolean;
}

/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "text" in value;
  isInstance = isInstance && "sentAt" in value;
  isInstance = isInstance && "isViewed" in value;
  isInstance = isInstance && "read" in value;

  return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
  return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Notification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: json["title"],
    text: json["text"],
    sentAt: new Date(json["sent_at"]),
    isViewed: json["is_viewed"],
    read: json["read"],
  };
}

export function NotificationToJSON(value?: Notification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    text: value.text,
    sent_at: value.sentAt.toISOString(),
    is_viewed: value.isViewed,
  };
}
