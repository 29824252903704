// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TowerSettings
 */
export interface TowerSettings {
  /**
   *
   * @type {number}
   * @memberof TowerSettings
   */
  maxBet: number | null;
}

/**
 * Check if a given object implements the TowerSettings interface.
 */
export function instanceOfTowerSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "maxBet" in value;

  return isInstance;
}

export function TowerSettingsFromJSON(json: any): TowerSettings {
  return TowerSettingsFromJSONTyped(json, false);
}

export function TowerSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TowerSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    maxBet: json["max_bet"],
  };
}

export function TowerSettingsToJSON(value?: TowerSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    max_bet: value.maxBet,
  };
}
