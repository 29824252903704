// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SockWithSubjectReward } from "./SockWithSubjectReward";
import {
  SockWithSubjectRewardFromJSON,
  SockWithSubjectRewardFromJSONTyped,
  SockWithSubjectRewardToJSON,
} from "./SockWithSubjectReward";
import type { UserShort } from "./UserShort";
import {
  UserShortFromJSON,
  UserShortFromJSONTyped,
  UserShortToJSON,
} from "./UserShort";

/**
 *
 * @export
 * @interface TopUser
 */
export interface TopUser {
  /**
   *
   * @type {UserShort}
   * @memberof TopUser
   */
  user: UserShort;
  /**
   *
   * @type {number}
   * @memberof TopUser
   */
  number: number | null;
  /**
   *
   * @type {number}
   * @memberof TopUser
   */
  damage: number;
  /**
   *
   * @type {number}
   * @memberof TopUser
   */
  percentage: number;
  /**
   *
   * @type {SockWithSubjectReward}
   * @memberof TopUser
   */
  reward: SockWithSubjectReward;
  /**
   *
   * @type {number}
   * @memberof TopUser
   */
  bullcoinsPrize: number;
}

/**
 * Check if a given object implements the TopUser interface.
 */
export function instanceOfTopUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "user" in value;
  isInstance = isInstance && "number" in value;
  isInstance = isInstance && "damage" in value;
  isInstance = isInstance && "percentage" in value;
  isInstance = isInstance && "reward" in value;
  isInstance = isInstance && "bullcoinsPrize" in value;

  return isInstance;
}

export function TopUserFromJSON(json: any): TopUser {
  return TopUserFromJSONTyped(json, false);
}

export function TopUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TopUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    user: UserShortFromJSON(json["user"]),
    number: json["number"],
    damage: json["damage"],
    percentage: json["percentage"],
    reward: SockWithSubjectRewardFromJSON(json["reward"]),
    bullcoinsPrize: json["bullcoins_prize"],
  };
}

export function TopUserToJSON(value?: TopUser | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user: UserShortToJSON(value.user),
    number: value.number,
    damage: value.damage,
    percentage: value.percentage,
    reward: SockWithSubjectRewardToJSON(value.reward),
    bullcoins_prize: value.bullcoinsPrize,
  };
}
