// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface BossBattleAttackAction
 */
export interface BossBattleAttackAction {
  /**
   *
   * @type {number}
   * @memberof BossBattleAttackAction
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof BossBattleAttackAction
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BossBattleAttackAction
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof BossBattleAttackAction
   */
  img: string;
  /**
   *
   * @type {number}
   * @memberof BossBattleAttackAction
   */
  price: number;
}

/**
 * Check if a given object implements the BossBattleAttackAction interface.
 */
export function instanceOfBossBattleAttackAction(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "price" in value;

  return isInstance;
}

export function BossBattleAttackActionFromJSON(
  json: any,
): BossBattleAttackAction {
  return BossBattleAttackActionFromJSONTyped(json, false);
}

export function BossBattleAttackActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BossBattleAttackAction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    description: json["description"],
    img: json["img"],
    price: json["price"],
  };
}

export function BossBattleAttackActionToJSON(
  value?: BossBattleAttackAction | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    img: value.img,
    price: value.price,
  };
}
