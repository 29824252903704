// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Subject } from "./Subject";
import {
  SubjectFromJSON,
  SubjectFromJSONTyped,
  SubjectToJSON,
} from "./Subject";
import type { VkCaseConditions } from "./VkCaseConditions";
import {
  VkCaseConditionsFromJSON,
  VkCaseConditionsFromJSONTyped,
  VkCaseConditionsToJSON,
} from "./VkCaseConditions";

/**
 *
 * @export
 * @interface VkCase
 */
export interface VkCase {
  /**
   *
   * @type {string}
   * @memberof VkCase
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof VkCase
   */
  readonly name: string;
  /**
   *
   * @type {string}
   * @memberof VkCase
   */
  location: string;
  /**
   *
   * @type {Array<Subject>}
   * @memberof VkCase
   */
  readonly subjects: Array<Subject> | null;
  /**
   *
   * @type {string}
   * @memberof VkCase
   */
  readonly canOpenAfter: string | null;
  /**
   *
   * @type {VkCaseConditions}
   * @memberof VkCase
   */
  readonly conditions: VkCaseConditions | null;
}

/**
 * Check if a given object implements the VkCase interface.
 */
export function instanceOfVkCase(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "location" in value;
  isInstance = isInstance && "subjects" in value;
  isInstance = isInstance && "canOpenAfter" in value;
  isInstance = isInstance && "conditions" in value;

  return isInstance;
}

export function VkCaseFromJSON(json: any): VkCase {
  return VkCaseFromJSONTyped(json, false);
}

export function VkCaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): VkCase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    img: json["img"],
    name: json["name"],
    location: json["location"],
    subjects:
      json["subjects"] === null
        ? null
        : (json["subjects"] as Array<any>).map(SubjectFromJSON),
    canOpenAfter: json["can_open_after"],
    conditions: VkCaseConditionsFromJSON(json["conditions"]),
  };
}

export function VkCaseToJSON(value?: VkCase | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    img: value.img,
    location: value.location,
  };
}
