// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { WithdrawalStatusEnum } from "./WithdrawalStatusEnum";
import {
  WithdrawalStatusEnumFromJSON,
  WithdrawalStatusEnumFromJSONTyped,
  WithdrawalStatusEnumToJSON,
} from "./WithdrawalStatusEnum";

/**
 *
 * @export
 * @interface SkinsbackWithdrawal
 */
export interface SkinsbackWithdrawal {
  /**
   *
   * @type {number}
   * @memberof SkinsbackWithdrawal
   */
  readonly id: number;
  /**
   *
   * @type {number}
   * @memberof SkinsbackWithdrawal
   */
  amountBullcoins: number;
  /**
   *
   * @type {WithdrawalStatusEnum}
   * @memberof SkinsbackWithdrawal
   */
  status: WithdrawalStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof SkinsbackWithdrawal
   */
  created: Date;
  /**
   *
   * @type {string}
   * @memberof SkinsbackWithdrawal
   */
  cancelComment: string | null;
}

/**
 * Check if a given object implements the SkinsbackWithdrawal interface.
 */
export function instanceOfSkinsbackWithdrawal(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "amountBullcoins" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "cancelComment" in value;

  return isInstance;
}

export function SkinsbackWithdrawalFromJSON(json: any): SkinsbackWithdrawal {
  return SkinsbackWithdrawalFromJSONTyped(json, false);
}

export function SkinsbackWithdrawalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SkinsbackWithdrawal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    amountBullcoins: json["amount_bullcoins"],
    status: WithdrawalStatusEnumFromJSON(json["status"]),
    created: new Date(json["created"]),
    cancelComment: json["cancel_comment"],
  };
}

export function SkinsbackWithdrawalToJSON(
  value?: SkinsbackWithdrawal | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_bullcoins: value.amountBullcoins,
    status: WithdrawalStatusEnumToJSON(value.status),
    created: value.created.toISOString(),
    cancel_comment: value.cancelComment,
  };
}
