// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateAvatar
 */
export interface UpdateAvatar {
  /**
   *
   * @type {string}
   * @memberof UpdateAvatar
   */
  avatarImg: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateAvatar
   */
  defaultAvatarImg: number;
  /**
   *
   * @type {number}
   * @memberof UpdateAvatar
   */
  avatarBorder: number | null;
}

/**
 * Check if a given object implements the UpdateAvatar interface.
 */
export function instanceOfUpdateAvatar(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "avatarImg" in value;
  isInstance = isInstance && "defaultAvatarImg" in value;
  isInstance = isInstance && "avatarBorder" in value;

  return isInstance;
}

export function UpdateAvatarFromJSON(json: any): UpdateAvatar {
  return UpdateAvatarFromJSONTyped(json, false);
}

export function UpdateAvatarFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateAvatar {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    avatarImg: json["avatar_img"],
    defaultAvatarImg: json["default_avatar_img"],
    avatarBorder: json["avatar_border"],
  };
}

export function UpdateAvatarToJSON(value?: UpdateAvatar | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    avatar_img: value.avatarImg,
    default_avatar_img: value.defaultAvatarImg,
    avatar_border: value.avatarBorder,
  };
}
