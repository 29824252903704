// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { BanTypeEnum } from "./BanTypeEnum";
import {
  BanTypeEnumFromJSON,
  BanTypeEnumFromJSONTyped,
  BanTypeEnumToJSON,
} from "./BanTypeEnum";

/**
 *
 * @export
 * @interface ChatBanRequest
 */
export interface ChatBanRequest {
  /**
   *
   * @type {number}
   * @memberof ChatBanRequest
   */
  userId: number;
  /**
   *
   * @type {BanTypeEnum}
   * @memberof ChatBanRequest
   */
  banType?: BanTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ChatBanRequest
   */
  reason?: string;
  /**
   *
   * @type {number}
   * @memberof ChatBanRequest
   */
  messageId?: number | null;
}

/**
 * Check if a given object implements the ChatBanRequest interface.
 */
export function instanceOfChatBanRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "userId" in value;

  return isInstance;
}

export function ChatBanRequestFromJSON(json: any): ChatBanRequest {
  return ChatBanRequestFromJSONTyped(json, false);
}

export function ChatBanRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatBanRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
    banType: !exists(json, "ban_type")
      ? undefined
      : BanTypeEnumFromJSON(json["ban_type"]),
    reason: !exists(json, "reason") ? undefined : json["reason"],
    messageId: !exists(json, "message_id") ? undefined : json["message_id"],
  };
}

export function ChatBanRequestToJSON(value?: ChatBanRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
    ban_type: BanTypeEnumToJSON(value.banType),
    reason: value.reason,
    message_id: value.messageId,
  };
}
