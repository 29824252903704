// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { FullTranslation } from "./FullTranslation";
import {
  FullTranslationFromJSON,
  FullTranslationFromJSONTyped,
  FullTranslationToJSON,
} from "./FullTranslation";
import type { TranslationProject } from "./TranslationProject";
import {
  TranslationProjectFromJSON,
  TranslationProjectFromJSONTyped,
  TranslationProjectToJSON,
} from "./TranslationProject";

/**
 *
 * @export
 * @interface TranslationsUpdate
 */
export interface TranslationsUpdate {
  /**
   *
   * @type {Array<FullTranslation>}
   * @memberof TranslationsUpdate
   */
  translations: Array<FullTranslation>;
  /**
   *
   * @type {TranslationProject}
   * @memberof TranslationsUpdate
   */
  project: TranslationProject;
}

/**
 * Check if a given object implements the TranslationsUpdate interface.
 */
export function instanceOfTranslationsUpdate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "translations" in value;
  isInstance = isInstance && "project" in value;

  return isInstance;
}

export function TranslationsUpdateFromJSON(json: any): TranslationsUpdate {
  return TranslationsUpdateFromJSONTyped(json, false);
}

export function TranslationsUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TranslationsUpdate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    translations: (json["translations"] as Array<any>).map(
      FullTranslationFromJSON,
    ),
    project: TranslationProjectFromJSON(json["project"]),
  };
}

export function TranslationsUpdateToJSON(
  value?: TranslationsUpdate | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    translations: (value.translations as Array<any>).map(FullTranslationToJSON),
    project: TranslationProjectToJSON(value.project),
  };
}
