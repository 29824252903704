// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { SkinsbackWithdrawalFull } from "./SkinsbackWithdrawalFull";
import {
  SkinsbackWithdrawalFullFromJSON,
  SkinsbackWithdrawalFullFromJSONTyped,
  SkinsbackWithdrawalFullToJSON,
} from "./SkinsbackWithdrawalFull";

/**
 *
 * @export
 * @interface PaginatedSkinsbackWithdrawalFullList
 */
export interface PaginatedSkinsbackWithdrawalFullList {
  /**
   *
   * @type {string}
   * @memberof PaginatedSkinsbackWithdrawalFullList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedSkinsbackWithdrawalFullList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<SkinsbackWithdrawalFull>}
   * @memberof PaginatedSkinsbackWithdrawalFullList
   */
  results: Array<SkinsbackWithdrawalFull>;
}

/**
 * Check if a given object implements the PaginatedSkinsbackWithdrawalFullList interface.
 */
export function instanceOfPaginatedSkinsbackWithdrawalFullList(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function PaginatedSkinsbackWithdrawalFullListFromJSON(
  json: any,
): PaginatedSkinsbackWithdrawalFullList {
  return PaginatedSkinsbackWithdrawalFullListFromJSONTyped(json, false);
}

export function PaginatedSkinsbackWithdrawalFullListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaginatedSkinsbackWithdrawalFullList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    next: !exists(json, "next") ? undefined : json["next"],
    previous: !exists(json, "previous") ? undefined : json["previous"],
    results: (json["results"] as Array<any>).map(
      SkinsbackWithdrawalFullFromJSON,
    ),
  };
}

export function PaginatedSkinsbackWithdrawalFullListToJSON(
  value?: PaginatedSkinsbackWithdrawalFullList | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    next: value.next,
    previous: value.previous,
    results: (value.results as Array<any>).map(SkinsbackWithdrawalFullToJSON),
  };
}
