// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `America` - America
 * * `Europe` - Europe
 * * `Asia` - Asia
 * * `TW, HK, MO` - Tw Hk Mo
 * @export
 */
export const GenshinServerEnum = {
  America: "America",
  Europe: "Europe",
  Asia: "Asia",
  TwHkMo: "TW, HK, MO",
} as const;
export type GenshinServerEnum =
  (typeof GenshinServerEnum)[keyof typeof GenshinServerEnum];

export function GenshinServerEnumFromJSON(json: any): GenshinServerEnum {
  return GenshinServerEnumFromJSONTyped(json, false);
}

export function GenshinServerEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenshinServerEnum {
  return json as GenshinServerEnum;
}

export function GenshinServerEnumToJSON(value?: GenshinServerEnum | null): any {
  return value as any;
}
