// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Boss } from "./Boss";
import { BossFromJSON, BossFromJSONTyped, BossToJSON } from "./Boss";

/**
 *
 * @export
 * @interface BossBattleEvent
 */
export interface BossBattleEvent {
  /**
   *
   * @type {Boss}
   * @memberof BossBattleEvent
   */
  currentBoss: Boss | null;
  /**
   *
   * @type {Boss}
   * @memberof BossBattleEvent
   */
  nextBoss: Boss | null;
}

/**
 * Check if a given object implements the BossBattleEvent interface.
 */
export function instanceOfBossBattleEvent(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "currentBoss" in value;
  isInstance = isInstance && "nextBoss" in value;

  return isInstance;
}

export function BossBattleEventFromJSON(json: any): BossBattleEvent {
  return BossBattleEventFromJSONTyped(json, false);
}

export function BossBattleEventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BossBattleEvent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currentBoss: BossFromJSON(json["current_boss"]),
    nextBoss: BossFromJSON(json["next_boss"]),
  };
}

export function BossBattleEventToJSON(value?: BossBattleEvent | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_boss: BossToJSON(value.currentBoss),
    next_boss: BossToJSON(value.nextBoss),
  };
}
