// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PreparedWithdrawalPack } from "./PreparedWithdrawalPack";
import {
  PreparedWithdrawalPackFromJSON,
  PreparedWithdrawalPackFromJSONTyped,
  PreparedWithdrawalPackToJSON,
} from "./PreparedWithdrawalPack";

/**
 *
 * @export
 * @interface PreparedGenshinWithdrawal
 */
export interface PreparedGenshinWithdrawal {
  /**
   *
   * @type {number}
   * @memberof PreparedGenshinWithdrawal
   */
  amountGems: number;
  /**
   *
   * @type {number}
   * @memberof PreparedGenshinWithdrawal
   */
  amountBullcoinsToReturn: number;
  /**
   *
   * @type {Array<PreparedWithdrawalPack>}
   * @memberof PreparedGenshinWithdrawal
   */
  packs: Array<PreparedWithdrawalPack>;
  /**
   *
   * @type {number}
   * @memberof PreparedGenshinWithdrawal
   */
  amountMoons: number;
}

/**
 * Check if a given object implements the PreparedGenshinWithdrawal interface.
 */
export function instanceOfPreparedGenshinWithdrawal(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountGems" in value;
  isInstance = isInstance && "amountBullcoinsToReturn" in value;
  isInstance = isInstance && "packs" in value;
  isInstance = isInstance && "amountMoons" in value;

  return isInstance;
}

export function PreparedGenshinWithdrawalFromJSON(
  json: any,
): PreparedGenshinWithdrawal {
  return PreparedGenshinWithdrawalFromJSONTyped(json, false);
}

export function PreparedGenshinWithdrawalFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PreparedGenshinWithdrawal {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountGems: json["amount_gems"],
    amountBullcoinsToReturn: json["amount_bullcoins_to_return"],
    packs: (json["packs"] as Array<any>).map(PreparedWithdrawalPackFromJSON),
    amountMoons: json["amount_moons"],
  };
}

export function PreparedGenshinWithdrawalToJSON(
  value?: PreparedGenshinWithdrawal | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_gems: value.amountGems,
    amount_bullcoins_to_return: value.amountBullcoinsToReturn,
    packs: (value.packs as Array<any>).map(PreparedWithdrawalPackToJSON),
    amount_moons: value.amountMoons,
  };
}
