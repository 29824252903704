// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RewardRangeRewardBase
 */
export interface RewardRangeRewardBase {
  /**
   *
   * @type {string}
   * @memberof RewardRangeRewardBase
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RewardRangeRewardBase
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof RewardRangeRewardBase
   */
  icon: string | null;
}

/**
 * Check if a given object implements the RewardRangeRewardBase interface.
 */
export function instanceOfRewardRangeRewardBase(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "icon" in value;

  return isInstance;
}

export function RewardRangeRewardBaseFromJSON(
  json: any,
): RewardRangeRewardBase {
  return RewardRangeRewardBaseFromJSONTyped(json, false);
}

export function RewardRangeRewardBaseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RewardRangeRewardBase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    description: json["description"],
    icon: json["icon"],
  };
}

export function RewardRangeRewardBaseToJSON(
  value?: RewardRangeRewardBase | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    description: value.description,
    icon: value.icon,
  };
}
