// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { TaskProgressTypeEnum } from "./TaskProgressTypeEnum";
import {
  TaskProgressTypeEnumFromJSON,
  TaskProgressTypeEnumFromJSONTyped,
  TaskProgressTypeEnumToJSON,
} from "./TaskProgressTypeEnum";

/**
 *
 * @export
 * @interface TaskProgress
 */
export interface TaskProgress {
  /**
   *
   * @type {string}
   * @memberof TaskProgress
   */
  name: string;
  /**
   *
   * @type {TaskProgressTypeEnum}
   * @memberof TaskProgress
   */
  type: TaskProgressTypeEnum;
  /**
   *
   * @type {number}
   * @memberof TaskProgress
   */
  readonly targetValue: number;
  /**
   *
   * @type {number}
   * @memberof TaskProgress
   */
  readonly progress: number;
  /**
   *
   * @type {boolean}
   * @memberof TaskProgress
   */
  readonly completed: boolean;
  /**
   *
   * @type {number}
   * @memberof TaskProgress
   */
  reward: number;
}

/**
 * Check if a given object implements the TaskProgress interface.
 */
export function instanceOfTaskProgress(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "targetValue" in value;
  isInstance = isInstance && "progress" in value;
  isInstance = isInstance && "completed" in value;
  isInstance = isInstance && "reward" in value;

  return isInstance;
}

export function TaskProgressFromJSON(json: any): TaskProgress {
  return TaskProgressFromJSONTyped(json, false);
}

export function TaskProgressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TaskProgress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    type: TaskProgressTypeEnumFromJSON(json["type"]),
    targetValue: json["target_value"],
    progress: json["progress"],
    completed: json["completed"],
    reward: json["reward"],
  };
}

export function TaskProgressToJSON(value?: TaskProgress | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: TaskProgressTypeEnumToJSON(value.type),
    reward: value.reward,
  };
}
