// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ActivePromoCodeRequest } from "./ActivePromoCodeRequest";
import {
  ActivePromoCodeRequestFromJSON,
  ActivePromoCodeRequestFromJSONTyped,
  ActivePromoCodeRequestToJSON,
} from "./ActivePromoCodeRequest";
import type { LanguageEnum } from "./LanguageEnum";
import {
  LanguageEnumFromJSON,
  LanguageEnumFromJSONTyped,
  LanguageEnumToJSON,
} from "./LanguageEnum";
import type { NicknameDecorationsEnum } from "./NicknameDecorationsEnum";
import {
  NicknameDecorationsEnumFromJSON,
  NicknameDecorationsEnumFromJSONTyped,
  NicknameDecorationsEnumToJSON,
} from "./NicknameDecorationsEnum";
import type { PermissionsEnum } from "./PermissionsEnum";
import {
  PermissionsEnumFromJSON,
  PermissionsEnumFromJSONTyped,
  PermissionsEnumToJSON,
} from "./PermissionsEnum";

/**
 *
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  nickname: string;
  /**
   *
   * @type {boolean}
   * @memberof UserRequest
   */
  banInChat: boolean;
  /**
   *
   * @type {ActivePromoCodeRequest}
   * @memberof UserRequest
   */
  activePromoCode?: ActivePromoCodeRequest;
  /**
   *
   * @type {Array<PermissionsEnum>}
   * @memberof UserRequest
   */
  permissions: Array<PermissionsEnum> | null;
  /**
   *
   * @type {NicknameDecorationsEnum}
   * @memberof UserRequest
   */
  selectedNicknameDecoration?: NicknameDecorationsEnum | null;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  phone?: string | null;
  /**
   *
   * @type {LanguageEnum}
   * @memberof UserRequest
   */
  language?: LanguageEnum;
}

/**
 * Check if a given object implements the UserRequest interface.
 */
export function instanceOfUserRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "banInChat" in value;
  isInstance = isInstance && "permissions" in value;

  return isInstance;
}

export function UserRequestFromJSON(json: any): UserRequest {
  return UserRequestFromJSONTyped(json, false);
}

export function UserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    nickname: json["nickname"],
    banInChat: json["ban_in_chat"],
    activePromoCode: !exists(json, "active_promo_code")
      ? undefined
      : ActivePromoCodeRequestFromJSON(json["active_promo_code"]),
    permissions:
      json["permissions"] === null
        ? null
        : (json["permissions"] as Array<any>).map(PermissionsEnumFromJSON),
    selectedNicknameDecoration: !exists(json, "selected_nickname_decoration")
      ? undefined
      : NicknameDecorationsEnumFromJSON(json["selected_nickname_decoration"]),
    phone: !exists(json, "phone") ? undefined : json["phone"],
    language: !exists(json, "language")
      ? undefined
      : LanguageEnumFromJSON(json["language"]),
  };
}

export function UserRequestToJSON(value?: UserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    nickname: value.nickname,
    ban_in_chat: value.banInChat,
    active_promo_code: ActivePromoCodeRequestToJSON(value.activePromoCode),
    permissions:
      value.permissions === null
        ? null
        : (value.permissions as Array<any>).map(PermissionsEnumToJSON),
    selected_nickname_decoration: NicknameDecorationsEnumToJSON(
      value.selectedNicknameDecoration,
    ),
    phone: value.phone,
    language: LanguageEnumToJSON(value.language),
  };
}
