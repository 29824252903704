// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { WithdrawalStatusEnum } from "./WithdrawalStatusEnum";
import {
  WithdrawalStatusEnumFromJSON,
  WithdrawalStatusEnumFromJSONTyped,
  WithdrawalStatusEnumToJSON,
} from "./WithdrawalStatusEnum";

/**
 *
 * @export
 * @interface StandoffWithdrawalRead
 */
export interface StandoffWithdrawalRead {
  /**
   *
   * @type {number}
   * @memberof StandoffWithdrawalRead
   */
  amountGoldToWithdraw: number;
  /**
   *
   * @type {string}
   * @memberof StandoffWithdrawalRead
   */
  gameAccountId: string | null;
  /**
   *
   * @type {WithdrawalStatusEnum}
   * @memberof StandoffWithdrawalRead
   */
  status: WithdrawalStatusEnum;
  /**
   *
   * @type {string}
   * @memberof StandoffWithdrawalRead
   */
  gameAvatar: string | null;
  /**
   *
   * @type {Date}
   * @memberof StandoffWithdrawalRead
   */
  readonly created: Date;
  /**
   *
   * @type {string}
   * @memberof StandoffWithdrawalRead
   */
  readonly cancelComment: string | null;
  /**
   *
   * @type {string}
   * @memberof StandoffWithdrawalRead
   */
  skinPicture: string;
}

/**
 * Check if a given object implements the StandoffWithdrawalRead interface.
 */
export function instanceOfStandoffWithdrawalRead(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "amountGoldToWithdraw" in value;
  isInstance = isInstance && "gameAccountId" in value;
  isInstance = isInstance && "status" in value;
  isInstance = isInstance && "gameAvatar" in value;
  isInstance = isInstance && "created" in value;
  isInstance = isInstance && "cancelComment" in value;
  isInstance = isInstance && "skinPicture" in value;

  return isInstance;
}

export function StandoffWithdrawalReadFromJSON(
  json: any,
): StandoffWithdrawalRead {
  return StandoffWithdrawalReadFromJSONTyped(json, false);
}

export function StandoffWithdrawalReadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StandoffWithdrawalRead {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    amountGoldToWithdraw: json["amount_gold_to_withdraw"],
    gameAccountId: json["game_account_id"],
    status: WithdrawalStatusEnumFromJSON(json["status"]),
    gameAvatar: json["game_avatar"],
    created: new Date(json["created"]),
    cancelComment: json["cancel_comment"],
    skinPicture: json["skin_picture"],
  };
}

export function StandoffWithdrawalReadToJSON(
  value?: StandoffWithdrawalRead | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    amount_gold_to_withdraw: value.amountGoldToWithdraw,
    game_account_id: value.gameAccountId,
    status: WithdrawalStatusEnumToJSON(value.status),
    game_avatar: value.gameAvatar,
    skin_picture: value.skinPicture,
  };
}
