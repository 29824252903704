// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ActivePromoCode
 */
export interface ActivePromoCode {
  /**
   *
   * @type {string}
   * @memberof ActivePromoCode
   */
  percent: string;
  /**
   *
   * @type {string}
   * @memberof ActivePromoCode
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof ActivePromoCode
   */
  maxSumForBonus: number | null;
}

/**
 * Check if a given object implements the ActivePromoCode interface.
 */
export function instanceOfActivePromoCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "percent" in value;
  isInstance = isInstance && "code" in value;
  isInstance = isInstance && "maxSumForBonus" in value;

  return isInstance;
}

export function ActivePromoCodeFromJSON(json: any): ActivePromoCode {
  return ActivePromoCodeFromJSONTyped(json, false);
}

export function ActivePromoCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivePromoCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    percent: json["percent"],
    code: json["code"],
    maxSumForBonus: json["max_sum_for_bonus"],
  };
}

export function ActivePromoCodeToJSON(value?: ActivePromoCode | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    percent: value.percent,
    code: value.code,
    max_sum_for_bonus: value.maxSumForBonus,
  };
}
