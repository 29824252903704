// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CurrencyEnum } from "./CurrencyEnum";
import {
  CurrencyEnumFromJSON,
  CurrencyEnumFromJSONTyped,
  CurrencyEnumToJSON,
} from "./CurrencyEnum";
import type { PaymentGroup } from "./PaymentGroup";
import {
  PaymentGroupFromJSON,
  PaymentGroupFromJSONTyped,
  PaymentGroupToJSON,
} from "./PaymentGroup";

/**
 *
 * @export
 * @interface CountryDetail
 */
export interface CountryDetail {
  /**
   *
   * @type {number}
   * @memberof CountryDetail
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof CountryDetail
   */
  flag: string;
  /**
   *
   * @type {string}
   * @memberof CountryDetail
   */
  name: string;
  /**
   *
   * @type {CurrencyEnum}
   * @memberof CountryDetail
   */
  currency: CurrencyEnum;
  /**
   *
   * @type {Array<PaymentGroup>}
   * @memberof CountryDetail
   */
  readonly methodGroups: Array<PaymentGroup>;
  /**
   *
   * @type {string}
   * @memberof CountryDetail
   */
  readonly rate: string;
}

/**
 * Check if a given object implements the CountryDetail interface.
 */
export function instanceOfCountryDetail(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "flag" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "methodGroups" in value;
  isInstance = isInstance && "rate" in value;

  return isInstance;
}

export function CountryDetailFromJSON(json: any): CountryDetail {
  return CountryDetailFromJSONTyped(json, false);
}

export function CountryDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CountryDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    flag: json["flag"],
    name: json["name"],
    currency: CurrencyEnumFromJSON(json["currency"]),
    methodGroups: (json["method_groups"] as Array<any>).map(
      PaymentGroupFromJSON,
    ),
    rate: json["rate"],
  };
}

export function CountryDetailToJSON(value?: CountryDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    flag: value.flag,
    name: value.name,
    currency: CurrencyEnumToJSON(value.currency),
  };
}
