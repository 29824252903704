// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CaseList } from "./CaseList";
import {
  CaseListFromJSON,
  CaseListFromJSONTyped,
  CaseListToJSON,
} from "./CaseList";

/**
 *
 * @export
 * @interface CaseListsByCollections
 */
export interface CaseListsByCollections {
  /**
   *
   * @type {string}
   * @memberof CaseListsByCollections
   */
  name: string;
  /**
   *
   * @type {Array<CaseList>}
   * @memberof CaseListsByCollections
   */
  readonly cases: Array<CaseList>;
}

/**
 * Check if a given object implements the CaseListsByCollections interface.
 */
export function instanceOfCaseListsByCollections(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "cases" in value;

  return isInstance;
}

export function CaseListsByCollectionsFromJSON(
  json: any,
): CaseListsByCollections {
  return CaseListsByCollectionsFromJSONTyped(json, false);
}

export function CaseListsByCollectionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CaseListsByCollections {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    cases: (json["cases"] as Array<any>).map(CaseListFromJSON),
  };
}

export function CaseListsByCollectionsToJSON(
  value?: CaseListsByCollections | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
