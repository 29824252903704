// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { RobloxSubjectQualityEnum } from "./RobloxSubjectQualityEnum";
import {
  RobloxSubjectQualityEnumFromJSON,
  RobloxSubjectQualityEnumFromJSONTyped,
  RobloxSubjectQualityEnumToJSON,
} from "./RobloxSubjectQualityEnum";
import type { SubjectTag } from "./SubjectTag";
import {
  SubjectTagFromJSON,
  SubjectTagFromJSONTyped,
  SubjectTagToJSON,
} from "./SubjectTag";

/**
 *
 * @export
 * @interface RobloxSubjectTyped
 */
export interface RobloxSubjectTyped {
  /**
   *
   * @type {number}
   * @memberof RobloxSubjectTyped
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof RobloxSubjectTyped
   */
  readonly img: string;
  /**
   *
   * @type {string}
   * @memberof RobloxSubjectTyped
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RobloxSubjectTyped
   */
  type: string | null;
  /**
   *
   * @type {number}
   * @memberof RobloxSubjectTyped
   */
  price: number;
  /**
   *
   * @type {Array<SubjectTag>}
   * @memberof RobloxSubjectTyped
   */
  readonly tags: Array<SubjectTag>;
  /**
   *
   * @type {RobloxSubjectQualityEnum}
   * @memberof RobloxSubjectTyped
   */
  qualityColor: RobloxSubjectQualityEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxSubjectTyped
   */
  resourcetype: string;
}

/**
 * Check if a given object implements the RobloxSubjectTyped interface.
 */
export function instanceOfRobloxSubjectTyped(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "tags" in value;
  isInstance = isInstance && "qualityColor" in value;
  isInstance = isInstance && "resourcetype" in value;

  return isInstance;
}

export function RobloxSubjectTypedFromJSON(json: any): RobloxSubjectTyped {
  return RobloxSubjectTypedFromJSONTyped(json, false);
}

export function RobloxSubjectTypedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RobloxSubjectTyped {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    type: json["type"],
    price: json["price"],
    tags: (json["tags"] as Array<any>).map(SubjectTagFromJSON),
    qualityColor: RobloxSubjectQualityEnumFromJSON(json["quality_color"]),
    resourcetype: json["resourcetype"],
  };
}

export function RobloxSubjectTypedToJSON(
  value?: RobloxSubjectTyped | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: value.type,
    price: value.price,
    quality_color: RobloxSubjectQualityEnumToJSON(value.qualityColor),
    resourcetype: value.resourcetype,
  };
}
