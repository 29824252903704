// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CaseList
 */
export interface CaseList {
  /**
   *
   * @type {number}
   * @memberof CaseList
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof CaseList
   */
  img: string;
  /**
   *
   * @type {string}
   * @memberof CaseList
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CaseList
   */
  description: string | null;
  /**
   *
   * @type {number}
   * @memberof CaseList
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof CaseList
   */
  readonly oldPrice: number;
  /**
   *
   * @type {number}
   * @memberof CaseList
   */
  discountPrice: number | null;
  /**
   *
   * @type {number}
   * @memberof CaseList
   */
  readonly collectionId: number | null;
}

/**
 * Check if a given object implements the CaseList interface.
 */
export function instanceOfCaseList(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "oldPrice" in value;
  isInstance = isInstance && "discountPrice" in value;
  isInstance = isInstance && "collectionId" in value;

  return isInstance;
}

export function CaseListFromJSON(json: any): CaseList {
  return CaseListFromJSONTyped(json, false);
}

export function CaseListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CaseList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    img: json["img"],
    name: json["name"],
    description: json["description"],
    price: json["price"],
    oldPrice: json["old_price"],
    discountPrice: json["discount_price"],
    collectionId: json["collection_id"],
  };
}

export function CaseListToJSON(value?: CaseList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    img: value.img,
    name: value.name,
    description: value.description,
    price: value.price,
    discount_price: value.discountPrice,
  };
}
