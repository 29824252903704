// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TelegramCaseConditions
 */
export interface TelegramCaseConditions {
  /**
   *
   * @type {boolean}
   * @memberof TelegramCaseConditions
   */
  isTelegramAccountPresent: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TelegramCaseConditions
   */
  isTelegramGroupMember: boolean;
}

/**
 * Check if a given object implements the TelegramCaseConditions interface.
 */
export function instanceOfTelegramCaseConditions(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "isTelegramAccountPresent" in value;
  isInstance = isInstance && "isTelegramGroupMember" in value;

  return isInstance;
}

export function TelegramCaseConditionsFromJSON(
  json: any,
): TelegramCaseConditions {
  return TelegramCaseConditionsFromJSONTyped(json, false);
}

export function TelegramCaseConditionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TelegramCaseConditions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    isTelegramAccountPresent: json["is_telegram_account_present"],
    isTelegramGroupMember: json["is_telegram_group_member"],
  };
}

export function TelegramCaseConditionsToJSON(
  value?: TelegramCaseConditions | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    is_telegram_account_present: value.isTelegramAccountPresent,
    is_telegram_group_member: value.isTelegramGroupMember,
  };
}
