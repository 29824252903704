// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ExchangerProfile
 */
export interface ExchangerProfile {
  /**
   *
   * @type {number}
   * @memberof ExchangerProfile
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof ExchangerProfile
   */
  spent: number;
  /**
   *
   * @type {Date}
   * @memberof ExchangerProfile
   */
  lastSpent: Date | null;
}

/**
 * Check if a given object implements the ExchangerProfile interface.
 */
export function instanceOfExchangerProfile(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "balance" in value;
  isInstance = isInstance && "spent" in value;
  isInstance = isInstance && "lastSpent" in value;

  return isInstance;
}

export function ExchangerProfileFromJSON(json: any): ExchangerProfile {
  return ExchangerProfileFromJSONTyped(json, false);
}

export function ExchangerProfileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExchangerProfile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    balance: json["balance"],
    spent: json["spent"],
    lastSpent:
      json["last_spent"] === null ? null : new Date(json["last_spent"]),
  };
}

export function ExchangerProfileToJSON(value?: ExchangerProfile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    balance: value.balance,
    spent: value.spent,
    last_spent: value.lastSpent === null ? null : value.lastSpent.toISOString(),
  };
}
