// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenshinGemPack
 */
export interface GenshinGemPack {
  /**
   *
   * @type {string}
   * @memberof GenshinGemPack
   */
  img: string;
  /**
   *
   * @type {number}
   * @memberof GenshinGemPack
   */
  amountGems: number;
  /**
   *
   * @type {number}
   * @memberof GenshinGemPack
   */
  priceBullcoins: number;
}

/**
 * Check if a given object implements the GenshinGemPack interface.
 */
export function instanceOfGenshinGemPack(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "img" in value;
  isInstance = isInstance && "amountGems" in value;
  isInstance = isInstance && "priceBullcoins" in value;

  return isInstance;
}

export function GenshinGemPackFromJSON(json: any): GenshinGemPack {
  return GenshinGemPackFromJSONTyped(json, false);
}

export function GenshinGemPackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GenshinGemPack {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    img: json["img"],
    amountGems: json["amount_gems"],
    priceBullcoins: json["price_bullcoins"],
  };
}

export function GenshinGemPackToJSON(value?: GenshinGemPack | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    img: value.img,
    amount_gems: value.amountGems,
    price_bullcoins: value.priceBullcoins,
  };
}
