// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `won` - Win
 * * `lost` - Lost
 * @export
 */
export const NextStepResponseStatusEnum = {
  Won: "won",
  Lost: "lost",
} as const;
export type NextStepResponseStatusEnum =
  (typeof NextStepResponseStatusEnum)[keyof typeof NextStepResponseStatusEnum];

export function NextStepResponseStatusEnumFromJSON(
  json: any,
): NextStepResponseStatusEnum {
  return NextStepResponseStatusEnumFromJSONTyped(json, false);
}

export function NextStepResponseStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NextStepResponseStatusEnum {
  return json as NextStepResponseStatusEnum;
}

export function NextStepResponseStatusEnumToJSON(
  value?: NextStepResponseStatusEnum | null,
): any {
  return value as any;
}
