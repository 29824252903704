// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PatchedChatSettingsUpdateRequest
 */
export interface PatchedChatSettingsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof PatchedChatSettingsUpdateRequest
   */
  cooldown?: number;
}

/**
 * Check if a given object implements the PatchedChatSettingsUpdateRequest interface.
 */
export function instanceOfPatchedChatSettingsUpdateRequest(
  value: object,
): boolean {
  let isInstance = true;

  return isInstance;
}

export function PatchedChatSettingsUpdateRequestFromJSON(
  json: any,
): PatchedChatSettingsUpdateRequest {
  return PatchedChatSettingsUpdateRequestFromJSONTyped(json, false);
}

export function PatchedChatSettingsUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchedChatSettingsUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cooldown: !exists(json, "cooldown") ? undefined : json["cooldown"],
  };
}

export function PatchedChatSettingsUpdateRequestToJSON(
  value?: PatchedChatSettingsUpdateRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cooldown: value.cooldown,
  };
}
