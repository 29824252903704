// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ChatMessageFullRoomEnum } from "./ChatMessageFullRoomEnum";
import {
  ChatMessageFullRoomEnumFromJSON,
  ChatMessageFullRoomEnumFromJSONTyped,
  ChatMessageFullRoomEnumToJSON,
} from "./ChatMessageFullRoomEnum";
import type { MessageSender } from "./MessageSender";
import {
  MessageSenderFromJSON,
  MessageSenderFromJSONTyped,
  MessageSenderToJSON,
} from "./MessageSender";
import type { PlatformEnum } from "./PlatformEnum";
import {
  PlatformEnumFromJSON,
  PlatformEnumFromJSONTyped,
  PlatformEnumToJSON,
} from "./PlatformEnum";
import type { ReactionsCount } from "./ReactionsCount";
import {
  ReactionsCountFromJSON,
  ReactionsCountFromJSONTyped,
  ReactionsCountToJSON,
} from "./ReactionsCount";

/**
 *
 * @export
 * @interface ChatMessageFull
 */
export interface ChatMessageFull {
  /**
   *
   * @type {number}
   * @memberof ChatMessageFull
   */
  readonly id: number;
  /**
   *
   * @type {MessageSender}
   * @memberof ChatMessageFull
   */
  messageSender: MessageSender;
  /**
   *
   * @type {string}
   * @memberof ChatMessageFull
   */
  messageText: string;
  /**
   *
   * @type {Date}
   * @memberof ChatMessageFull
   */
  createdAt: Date;
  /**
   *
   * @type {ChatMessageFullRoomEnum}
   * @memberof ChatMessageFull
   */
  room: ChatMessageFullRoomEnum;
  /**
   *
   * @type {string}
   * @memberof ChatMessageFull
   */
  roomId: string | null;
  /**
   *
   * @type {PlatformEnum}
   * @memberof ChatMessageFull
   */
  game: PlatformEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessageFull
   */
  readonly canBeDeleted: boolean;
  /**
   *
   * @type {Array<ReactionsCount>}
   * @memberof ChatMessageFull
   */
  readonly reactions: Array<ReactionsCount>;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessageFull
   */
  readonly deleted: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ChatMessageFull
   */
  isReported: boolean;
}

/**
 * Check if a given object implements the ChatMessageFull interface.
 */
export function instanceOfChatMessageFull(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "messageSender" in value;
  isInstance = isInstance && "messageText" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "room" in value;
  isInstance = isInstance && "roomId" in value;
  isInstance = isInstance && "game" in value;
  isInstance = isInstance && "canBeDeleted" in value;
  isInstance = isInstance && "reactions" in value;
  isInstance = isInstance && "deleted" in value;
  isInstance = isInstance && "isReported" in value;

  return isInstance;
}

export function ChatMessageFullFromJSON(json: any): ChatMessageFull {
  return ChatMessageFullFromJSONTyped(json, false);
}

export function ChatMessageFullFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ChatMessageFull {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    messageSender: MessageSenderFromJSON(json["message_sender"]),
    messageText: json["message_text"],
    createdAt: new Date(json["created_at"]),
    room: ChatMessageFullRoomEnumFromJSON(json["room"]),
    roomId: json["room_id"],
    game: PlatformEnumFromJSON(json["game"]),
    canBeDeleted: json["can_be_deleted"],
    reactions: (json["reactions"] as Array<any>).map(ReactionsCountFromJSON),
    deleted: json["deleted"],
    isReported: json["is_reported"],
  };
}

export function ChatMessageFullToJSON(value?: ChatMessageFull | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message_sender: MessageSenderToJSON(value.messageSender),
    message_text: value.messageText,
    created_at: value.createdAt.toISOString(),
    room: ChatMessageFullRoomEnumToJSON(value.room),
    room_id: value.roomId,
    game: PlatformEnumToJSON(value.game),
    is_reported: value.isReported,
  };
}
