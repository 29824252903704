// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CS2SubjectTyped,
  instanceOfCS2SubjectTyped,
  CS2SubjectTypedFromJSON,
  CS2SubjectTypedFromJSONTyped,
  CS2SubjectTypedToJSON,
} from "./CS2SubjectTyped";
import {
  GenshinSubjectTyped,
  instanceOfGenshinSubjectTyped,
  GenshinSubjectTypedFromJSON,
  GenshinSubjectTypedFromJSONTyped,
  GenshinSubjectTypedToJSON,
} from "./GenshinSubjectTyped";
import {
  PUBGSubjectTyped,
  instanceOfPUBGSubjectTyped,
  PUBGSubjectTypedFromJSON,
  PUBGSubjectTypedFromJSONTyped,
  PUBGSubjectTypedToJSON,
} from "./PUBGSubjectTyped";
import {
  RobloxSubjectTyped,
  instanceOfRobloxSubjectTyped,
  RobloxSubjectTypedFromJSON,
  RobloxSubjectTypedFromJSONTyped,
  RobloxSubjectTypedToJSON,
} from "./RobloxSubjectTyped";
import {
  StandoffSubjectTyped,
  instanceOfStandoffSubjectTyped,
  StandoffSubjectTypedFromJSON,
  StandoffSubjectTypedFromJSONTyped,
  StandoffSubjectTypedToJSON,
} from "./StandoffSubjectTyped";

/**
 * @type Subject
 *
 * @export
 */
export type Subject =
  | ({ resourcetype: "CS2Subject" } & CS2SubjectTyped)
  | ({ resourcetype: "GenshinSubject" } & GenshinSubjectTyped)
  | ({ resourcetype: "PUBGSubject" } & PUBGSubjectTyped)
  | ({ resourcetype: "RobloxSubject" } & RobloxSubjectTyped)
  | ({ resourcetype: "StandoffSubject" } & StandoffSubjectTyped);

export function SubjectFromJSON(json: any): Subject {
  return SubjectFromJSONTyped(json, false);
}

export function SubjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Subject {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json["resourcetype"]) {
    case "CS2Subject":
      return {
        ...CS2SubjectTypedFromJSONTyped(json, true),
        resourcetype: "CS2Subject",
      };
    case "GenshinSubject":
      return {
        ...GenshinSubjectTypedFromJSONTyped(json, true),
        resourcetype: "GenshinSubject",
      };
    case "PUBGSubject":
      return {
        ...PUBGSubjectTypedFromJSONTyped(json, true),
        resourcetype: "PUBGSubject",
      };
    case "RobloxSubject":
      return {
        ...RobloxSubjectTypedFromJSONTyped(json, true),
        resourcetype: "RobloxSubject",
      };
    case "StandoffSubject":
      return {
        ...StandoffSubjectTypedFromJSONTyped(json, true),
        resourcetype: "StandoffSubject",
      };
    default:
      throw new Error(
        `No variant of Subject exists with 'resourcetype=${json["resourcetype"]}'`,
      );
  }
}

export function SubjectToJSON(value?: Subject | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value["resourcetype"]) {
    case "CS2Subject":
      return CS2SubjectTypedToJSON(value);
    case "GenshinSubject":
      return GenshinSubjectTypedToJSON(value);
    case "PUBGSubject":
      return PUBGSubjectTypedToJSON(value);
    case "RobloxSubject":
      return RobloxSubjectTypedToJSON(value);
    case "StandoffSubject":
      return StandoffSubjectTypedToJSON(value);
    default:
      throw new Error(
        `No variant of Subject exists with 'resourcetype=${value["resourcetype"]}'`,
      );
  }
}
