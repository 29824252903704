// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { ReactionEnum } from "./ReactionEnum";
import {
  ReactionEnumFromJSON,
  ReactionEnumFromJSONTyped,
  ReactionEnumToJSON,
} from "./ReactionEnum";

/**
 *
 * @export
 * @interface SetRemoveReactionRequest
 */
export interface SetRemoveReactionRequest {
  /**
   *
   * @type {ReactionEnum}
   * @memberof SetRemoveReactionRequest
   */
  reaction: ReactionEnum;
}

/**
 * Check if a given object implements the SetRemoveReactionRequest interface.
 */
export function instanceOfSetRemoveReactionRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "reaction" in value;

  return isInstance;
}

export function SetRemoveReactionRequestFromJSON(
  json: any,
): SetRemoveReactionRequest {
  return SetRemoveReactionRequestFromJSONTyped(json, false);
}

export function SetRemoveReactionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SetRemoveReactionRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    reaction: ReactionEnumFromJSON(json["reaction"]),
  };
}

export function SetRemoveReactionRequestToJSON(
  value?: SetRemoveReactionRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    reaction: ReactionEnumToJSON(value.reaction),
  };
}
