// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Boss
 */
export interface Boss {
  /**
   *
   * @type {number}
   * @memberof Boss
   */
  readonly id: number;
  /**
   *
   * @type {Date}
   * @memberof Boss
   */
  startDate: Date;
  /**
   *
   * @type {Date}
   * @memberof Boss
   */
  endDate: Date;
  /**
   *
   * @type {number}
   * @memberof Boss
   */
  healthPoints: number;
  /**
   *
   * @type {number}
   * @memberof Boss
   */
  readonly damage: number;
  /**
   *
   * @type {number}
   * @memberof Boss
   */
  healthPercentage: number;
  /**
   *
   * @type {number}
   * @memberof Boss
   */
  prize: number;
}

/**
 * Check if a given object implements the Boss interface.
 */
export function instanceOfBoss(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "startDate" in value;
  isInstance = isInstance && "endDate" in value;
  isInstance = isInstance && "healthPoints" in value;
  isInstance = isInstance && "damage" in value;
  isInstance = isInstance && "healthPercentage" in value;
  isInstance = isInstance && "prize" in value;

  return isInstance;
}

export function BossFromJSON(json: any): Boss {
  return BossFromJSONTyped(json, false);
}

export function BossFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Boss {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    startDate: new Date(json["start_date"]),
    endDate: new Date(json["end_date"]),
    healthPoints: json["health_points"],
    damage: json["damage"],
    healthPercentage: json["health_percentage"],
    prize: json["prize"],
  };
}

export function BossToJSON(value?: Boss | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start_date: value.startDate.toISOString(),
    end_date: value.endDate.toISOString(),
    health_points: value.healthPoints,
    health_percentage: value.healthPercentage,
    prize: value.prize,
  };
}
