export const RETRY_PARAM_NAME = "app-refresh-retry";
export const MAX_RETRIES = 2;

export function getCurrentRetry(route: any) {
  if (
    !route ||
    typeof route !== "object" ||
    !route.query ||
    !route.query[RETRY_PARAM_NAME]
  )
    return 0;
  const retryParamValue = route.query[RETRY_PARAM_NAME];
  const isArray = Array.isArray(retryParamValue);
  if (
    isArray &&
    typeof retryParamValue[0] !== "string" &&
    typeof retryParamValue !== "string"
  )
    return 0;
  const currentRetry = isArray
    ? Number(retryParamValue[0])
    : Number(retryParamValue);
  if (isNaN(currentRetry)) return 0;
  return currentRetry;
}

export function getDelimeter(path: string) {
  if (path.includes("?")) {
    if (path.endsWith("?")) {
      return "";
    } else {
      return "&";
    }
  }
  return "?";
}

type NewPathArgs = {
  fullPath: string;
  delimeter: "" | "?" | "&";
  nextRetry: string;
};

export function getNewPath({ fullPath, delimeter, nextRetry }: NewPathArgs) {
  if (!fullPath.includes(RETRY_PARAM_NAME))
    return `${fullPath}${delimeter}${RETRY_PARAM_NAME}=${nextRetry}`;
  const clearedPath = fullPath.replaceAll(
    new RegExp(`&?${RETRY_PARAM_NAME}=\\d+`, "g"),
    "",
  );
  return `${clearedPath}${delimeter}${RETRY_PARAM_NAME}=${nextRetry}`;
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:chunkError", () => {
    const route = useRoute();
    const currentRetry = getCurrentRetry(route);
    if (currentRetry < MAX_RETRIES) {
      const nextRetry = (currentRetry + 1).toString();
      const { fullPath } = route;
      const delimeter = getDelimeter(fullPath);
      const newPath = getNewPath({ fullPath, delimeter, nextRetry });
      reloadNuxtApp({
        force: true,
        path: newPath,
      });
    }
  });
});
